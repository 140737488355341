/**
  Checkbox Panel 样式

  由于ant-dropmenu 是独立于当前页面node的，
  所以无法添加当前页面的className
*/
.ant-dropdown.ant-dropdown-placement-bottomRight {
  background-color: unset !important;
  padding-top: 0 !important;
}

@media only screen and (max-width: 767px) {

  .sc-EHOje .ant-btn:focus>span,
  .sc-EHOje .ant-btn:active>span {
    position: relative;
  }
}

.filter_name {
  font-family: MaisonNeue-Book;
  font-size: 16px;
  line-height: 1.5;
}
