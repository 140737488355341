:root {
  --xs-width: 300px;
  --s-width: 400px;
  --m-width: 500px;
  --l-width: 600px;
  --xl-width: 700px;
}

.work_title {
  font-family: MaisonNeue-Demi;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: var(--dark);
  text-align: left;
  margin-bottom: 24px;
}

.ant-btn:active > span {
  position: absolute;
}

.grid:after {
  content: "";
  display: block;
  clear: both;
}

.grid-item,
.grid-sizer {
  width: 1%;
}

.grid-item {
  float: left;
  overflow: hidden;
  margin-right: 24px;
  margin-bottom: 24px;
  overflow: hidden;
  position: relative;
}

.featurenewwork_container img {
  margin: 20px 0;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-in-out;
}

.featurenewwork_container img:hover {
  -ms-transform: scale(1.1);
  /* IE 9 */
  -webkit-transform: scale(1.1);
  /* Safari 3-8 */
  transform: scale(1.1);
}

.featurenewwork_container .ant-col {
  overflow: hidden;
  padding: 20px;
}

.grid-item-button {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: left;
  margin: 50px 0 30px 0;
}

.workdetail .bdgBJg.bdgBJg {
  width: 280px;
}
