.ImgSwiper {
  text-align: left;
}

.ImgSwiper .mb12 {
  margin-bottom: 12px;
}

.ImgSwiper .mb20 {
  margin-bottom: 20px;
}

.ImgSwiper .swiper_info {
  position: relative;
  margin-bottom: 80px;
}

.ImgSwiper .swiper_info::before {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: -40px;
  height: 1px;
  background: #efefef;
}

.ImgSwiper .subtitle {
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
}

.ImgSwiper .title {
  font-family: "MaisonNeue-Demi";
  font-size: 40px;
  font-weight: 600;
}

.ImgSwiper .time {
  font-size: 18px;
}

.ImgSwiper .time .icon {
  margin-right: 10px;
  vertical-align: middle;
}

.ImgSwiper .address {
  font-size: 18px;
}

.ImgSwiper .address .icon {
  margin-right: 10px;
  vertical-align: middle;
}

.ImgSwiper .desc {
  font-family: "MaisonNeue-Medium";
  font-size: 16px;
  color: #979797;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ImgSwiper .more {
  font-size: 16px;
}

.ImgSwiper .more .icon {
  margin-left: 8px;
}

.ImgSwiper a:hover,
.ImgSwiper a:active {
  color: var(--dark);
}

.swiper_slide_info img {
  height: 234px;
}

.swiper_slide_info .slick-prev,
.swiper_slide_info .slick-next {
  width: 45px;
  height: 65px;
  background: rgba(255, 255, 255, 0.64);
  z-index: 5;
}

.swiper_slide_info .slick-prev {
  left: 0px;
}

.swiper_slide_info .slick-next {
  right: 0px;
}

.swiper_slide_info .slick-dots {
  bottom: -25px;
}

@media only screen and (max-width: 743px) {
  .ImgSwiper {
    margin: 24px 24px 0 24px !important;
  }
  .ImgSwiper .swiper_info:last-child {
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 744px) {
  .swiper_slide_info img {
    height: 426px;
  }
  .ImgSwiper {
    margin: 24px 40px 0 40px !important;
  }
  .ImgSwiper .swiper_info:last-child {
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 1024px) {
  .swiper_slide_info img {
    height: 426px;
  }
  .ImgSwiper {
    margin: 24px 60px 40px 60px !important;
  }
  .ImgSwiper .swiper_info:last-child {
    margin-bottom: 80px;
  }
}

@media only screen and (min-width: 1280px) {
  .swiper_slide_info img {
    height: 590px;
  }
  .ImgSwiper {
    margin: 24px 140px 40px 140px !important;
  }
  .ImgSwiper .swiper_info:last-child {
    margin-bottom: 80px;
  }
}
