.list_video {
  overflow-y: hidden;
  overflow-x: auto;
}

.list_video .video {
  width: 100%;
  height: 100%;
}

.list_video .video iframe {
  height: 100% !important;
}

.list_video.more {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}

.list_video.more .video {
  width: 80%;
  min-width: 80%;
  margin-right: 4%;
}
