/* 
    CSS Nav
1. General Classes
2. Hero image
3. Featured Collection
4. Featured New Work
5. Artists 
6. Instagram
7. Media Query

*/

/*************************************** 1. General Classes *********************************************************************/
/****************************************************************************************************************************/
body {
  font-family: MaisonNeue-Book;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: var(--dark);
}

.ant-layout-content {
  background-color: var(--white);
}

/******************************************* 2. Hero Image *******************************************************************/
/**********************************************************************************************************************************/
.ant_carousel {
  position: relative;
  /* margin-top: 88px; */
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.ant_carousel .placeholder {
  width: 100%;
  height: 100%;
  background-color: #efeff1;
}

.ant_carousel img {
  min-width: 100%;
  min-height: 100vh;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
}

.ant_carousel .board {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-color: var(--white);
  z-index: 10;
  position: absolute;
  left: 40px;
  bottom: 0px;
  color: var(--dark);
}

.board_name {
  width: 80%;
  font-size: 12px;
  font-family: MaisonNeue-Bold;
  font-size: 36px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  text-align: center;
  color: var(--dark);
  margin-top: 8px;
}

.board_category {
  width: 74px;
  /* height: 14px; */
  font-family: MaisonNeue-Book;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1.5px;
  color: var(--steel-grey);
  margin-top: 40px;
}

.board_description {
  width: 88%;
  /* height: 48px; */
  font-family: MaisonNeue-Book;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  margin-top: 16px;
}

.board_more {
  width: 88%;
  margin-top: 16px;
  margin-bottom: 32px;
  font-family: MaisonNeue-Demi;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: 2px;
  text-align: center;
  color: var(--dark);
}

.slick-dots li {
  margin: 0px;
}

/******************************************* Featured New Work *******************************************************************/
/**********************************************************************************************************************************/
.featurenewwork_container {
  display: flex;
  flex-direction: column;
  margin: 96px 2.78%;
}

/******************************************* Media Query **************************************************************************/
/**********************************************************************************************************************************/
@media only screen and (max-width: 767px) {
  .ant_carousel {
    display: flex;
    /* margin-top: 56px; */
    flex-direction: column;
    height: 700px;
  }

  .ant_carousel img {
    min-height: 408px;
    top: 0;
  }

  .ant_carousel .board {
    left: 0px;
    top: 408px;
    width: 100%;
  }

  .board_description {
    font-size: 14px;
    margin-top: 8px;
    line-height: 1.43;
    display: none;
  }

  .featurenewwork_container {
    margin: 28px 16px 6px 16px;
  }

  .first_row .ant-col {
    padding: 16px 16px 16px;
  }

  .FeaturedCollection_Container {
    margin-top: 18px !important;
  }

  .slick-dots {
    bottom: 275px;
  }

  .homeArtists {
    margin: 0px 16px;
  }

  .homeArtists .FeaturedCollection_Container {
    margin: 0 auto;
  }
}

@media only screen and (min-width: 768px) {
  .ant_carousel .board {
    width: 61.5%;
    /* height: 346px; */
  }

  .board .board_more {
    font-size: 18px;
    line-height: 1.33;
  }

  .homeArtists .FeaturedCollection_Container .slick-slider {
    height: 320px;
  }

  .homeArtists .FeaturedCollection_Container .slick-list {
    height: 320px;
  }

  .homeArtists .FeaturedCollection_Container {
    height: 320px;
  }

  .homeArtists .FeaturedCollection_Image_Container {
    width: 48.9% !important;
    height: 320px;
    /*必须是数值*/
  }
}

@media only screen and (min-width: 1024px) {
  /************* 2. Hero Image ***************/
  .ant_carousel .board {
    width: 46%;
    /* height: 407px; */
  }
}

@media only screen and (min-width: 1440px) {
  .Section_Outer_Container {
    margin: 96px 159px 0px 159px;
  }

  .featurenewwork_container {
    margin: 96px 41px;
  }

  /************* 2. Hero Image *************/
  .ant_carousel .board {
    width: 40%;
    /* height: 358px; */
  }

  .homeArtists .FeaturedCollection_Container .slick-slider {
    margin-left: 2.85%;
    width: 94.3% !important;
    height: 420px;
  }

  .homeArtists .FeaturedCollection_Container .slick-list {
    width: 100% !important;
    height: 420px;
    /*必须写数值*/
    padding: 0px !important;
    margin: 0px !important;
  }

  .homeArtists .FeaturedCollection_Container {
    height: 420px;
  }

  .homeArtists .FeaturedCollection_Image_Container {
    width: 60% !important;
    height: 420px;
    /*必须是数值*/
  }
}

@media only screen and (min-width: 1920px) {
  .Section_Outer_Container {
    margin: 96px 159px 0px 159px;
  }

  /************* 2. Hero Image *******************/
  .ant_carousel .board {
    width: 30%;
    /* height: 358px; */
  }

  /************* 4. Featured New Work *******************/
  .featurenewwork_container {
    margin: 96px 159px;
  }

  .homeArtists .FeaturedCollection_Container .slick-slider {
    margin-left: 8.5%;
    width: 83% !important;
    height: 500px;
  }

  .homeArtists .FeaturedCollection_Container .slick-list {
    width: 100% !important;
    height: 500px;
    padding: 0px !important;
    margin: 0px !important;
  }

  .homeArtists .FeaturedCollection_Container {
    height: 500px;
  }

  .homeArtists .FeaturedCollection_Image_Container {
    width: 60% !important;
    height: 500px;
  }
}
