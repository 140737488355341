.heroCarousel2 .carouselImg {
  padding: 0 12px;
}
.heroCarousel2 button {
  border-radius: 50%;
  height: 48px;
  width: 48px;
  border: solid 1px var(--pale-blue);
  background-color: var(--white);
}

.heroCarousel2 button.slick-arrow.slick-next {
  background-color: white;
}
.heroCarousel2 button.slick-arrow.slick-prev {
  background-color: white;
}

.heroCarousel2 .slick-prev {
  left: 40px;
  top: 320px;
  z-index: 1;
}
.heroCarousel2 .slick-next {
  right: 40px;
  top: 320px;
}

.heroCarousel2 .slick-prev:before {
  content: url("/images/miscellaneous/icon-chevron-left.svg") !important;
}

.heroCarousel2 .slick-next:before {
  content: url("/images/miscellaneous/icon-chevron-right.svg") !important;
}
.heroCarousel2 .slick-next:hover:focus:active {
  background-color: white;
}
.heroCarousel2 .slick-prev:hover:focus:active {
  background-color: white;
}
.carousel.carousel-slider .control-arrow {
  background-color: white;
  position: absolute;
  top: 300px;
  right: 35px;
}

/**************************************************** media query ******************************************************/
@media only screen and (max-width: 767px) {
  .carouselImg img {
    min-height: 249px;
  }
  /* .exhibitionDetailWrap {
    margin: 60px auto;
    width: 92%;
  } */
  .exhibitionDetailInfo_title {
    font-size: 24px;
    line-height: 1.17;
  }
  /* .exhibitionDetailInfo_content {
    margin-top: 24px;
  } */
  .exhibitionDetailInfo_content .content {
    font-size: 14px;
    line-height: 1.43;
  }
  .exhibitionBtn .bdgBJg.bdgBJg {
    width: 320px;
    height: 56px;
  }
  .exhibitionBtn {
    margin-bottom: 64px;
  }
  .exhibitionBtn .gipcPA.gipcPA {
    font-size: 18px;
    line-height: 1.33;
    top: 18px;
    left: 45px;
  }
  .heroCarousel2 .slick-arrow {
    display: none !important;
  }
  .heroCarousel2 .slick-dots {
    bottom: -20px;
  }
  .heroCarousel2 .carouselImg {
    padding: 0px;
  }
  .heroCarousel2 .ewOoqd {
    margin-top: 102px;
  }
  .exhibition_artist {
    margin: 68px 16px 0px 16px;
  }
}
@media only screen and (min-width: 768px) {
  .carouselImg img {
    height: 360px;
  }
}
@media only screen and (min-width: 1024px) {
  .carouselImg img {
    height: 450px;
  }
  .heroCarousel2 .slick-prev {
    top: 250px;
  }
  .heroCarousel2 .slick-next {
    top: 250px;
  }
}
@media only screen and (min-width: 1440px) {
  .carouselImg img {
    height: 500px;
  }
}
@media only screen and (min-width: 1920px) {
  .carouselImg img {
    height: 640px;
  }
  .heroCarousel2 .slick-prev {
    top: 300px;
  }
  .heroCarousel2 .slick-next {
    top: 300px;
  }
}

/* Version2 */
.carouselImg2{
  padding: 0;
  /* padding-right: 20px; */
}
.carouselImg2 img{
  width: 100%;
  object-fit: scale-down;
}
@media only screen and (min-width: 1024px) {
  .carouselImg2 img {
    height: 306px;
  }
}
@media only screen and (min-width: 1280px) {
  .carouselImg2 img {
    height: 372px;
  }
}
