.smallSliderHeader {
  display: flex;
  justify-content: space-between;
}

.smallSliderHeader .work_title {
  margin-top: 3px;
  width: 60%;
}

.smallSliderButton .bdgBJg.bdgBJg {
  width: 100px;
  height: 32px;
  border-radius: 16px;
  background-color: var(--dark);
}

.smallSliderButton .JhMeT {
  display: none;
}

.smallSlider_name {
  font-family: MaisonNeue-Bold;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: var(--dark);
  text-align: left;
  margin: 4px 2px;
}

.smallSlider_name_artist {
  font-family: MaisonNeue-Bold;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: var(--dark);
  text-align: left;
  margin: 4px 2px;
}

.FeaturedCollection_Container .smallSliderHeader {
  width: 100%;
  margin: 0 auto;
}

.FeaturedCollection_Container .smallSliderWrap {
  padding: 0 16px;
}

.smallSliderWrap .slick-slider button {
  display: none !important;
}

.smallSlider_image {
  height: 200px;
  margin: 0 6px;
  text-align: left;
  overflow: hidden;
}

.smallSlider_image img {
  height: 100%;
  width: auto;
}

/* new */
.horizontalSlide_Wrap {
  height: 350px;
  width: 100%;
  overflow: hidden;
}

.horizontalSlide {
  width: 100%;
  height: 310px;
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  margin-left: 0px;
  -webkit-overflow-scrolling: touch;
}

.horizontalSlide .smallSlider_image {
  height: 200px;
  text-align: left;
  overflow: hidden;
  margin: 0;
}

.smallSlidercard {
  margin-right: 8px;
}

.horizontal_Slide {
  width: 100%;
  height: 310px;
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  margin-left: 0px;
  -webkit-overflow-scrolling: touch;
}

.horizontal_Slide .column_item {
  position: relative;
  margin-right: 24px;
  padding: 0;
  min-width: 200px;
  max-height: 100%;
}

.horizontal_Slide .lazyimg_image {
  height: 310px;
  text-align: left;
  overflow: hidden;
  margin: 0;
}

.horizontal_Slide .lazyimg_image img {
  height: 100%;
}

.exhibition_slide {
  height: 295px;
}

.exhibition_slide .lazyimg_image {
  height: 295px;
  width: 280px;
}

.presses_slide {
  height: auto;
}

.presses_slide .column_item {
  min-width: 395px !important;
}

.presses_slide .lazyimg_image {
  height: auto;
}

.presses_slide .lazyimg_image img {
  width: 100%;
  max-width: 100%;
}

.presses_slide .c_text_div {
  margin-top: 16px;
  margin-bottom: 24px;
}

.horizontal_Wrap {
  height: 350px;
  width: 100%;
  overflow: hidden;
}

.horizontal_header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.horizontal_header .title {
  font-family: "MaisonNeue-Demi";
  font-size: 24px;
  line-height: 1.2;
  text-align: left;
  color: var(--dark);
}

.horizontal_slide {
  width: 100%;
  height: 310px;
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  margin-left: 0px;
  -webkit-overflow-scrolling: touch;
}

.small_card {
  height: 280px;
  margin-right: 8px;
}

.small_image {
  height: 100%;
  margin-right: 8px;
  text-align: left;
  overflow: hidden;
}

.small_image img {
  height: 100%;
  width: auto;
  max-width: 100%;
}

.small_art_name {
  font-family: "MaisonNeue-Medium";
  font-size: 20px;
  margin-top: 16px;
  color: var(--dark);
  text-align: left;
}

@media only screen and (max-width: 744px) {
  .presses_slide .column_item {
    min-width: 295px !important;
  }
}
