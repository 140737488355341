.cc_header {
  padding-bottom: 24px;
  border-bottom: 1px solid #efefef;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.cc_header .title {
  font-family: "MaisonNeue-Demi";
  font-size: 24px;
  line-height: 1;
}

.cc_header .title_link {
  font-family: "MaisonNeue-Medium";
  font-size: 16px;
  line-height: 1.5;
}

.cc_header .title_link a {
  display: inline-block;
}

.cc_header .title_link a:hover, .cc_header .title_link a:active, .cc_header .title_link a:focus {
  color: var(--dark);
}

.cc_header .title_link .anticon {
  position: relative;
  top: -3px;
}

.cc_header .title_link span {
  font-family: "MaisonNeue-Medium";
  margin-right: 8px;
}

@media only screen and (max-width: 744px) {
  .cc_header {
    border: 0;
    padding: 0;
    display: block;
    text-align: left;
  }
  .mini_header .title {
    margin-bottom: 24px;
  }
  .mini_header .title_link {
    margin-bottom: 32px;
  }
}
