.Large_Screen_Frame {
  text-align: left;
}

.Small_Screen_Frame {
  visibility: visible;
}

.Small_Screen_Frame .small_button {
  margin-top: 10px;
  border: 1px solid var(--dark) !important;
  font-family: MaisonNeue-Demi;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: 2px;
  color: var(--dark);
  height: 40px;
  width: 107px;
}

.Small_Screen_Frame .small_button:hover, .Small_Screen_Frame .small_button:focus {
  color: var(--dark);
  border: 1px solid #000000;
  color: unset !important;
}

.Small_Screen_Frame .small_button:active {
  color: var(--dark);
  border: 1px solid #000000;
  color: unset !important;
}

.Small_Screen_Frame .small_button:active span {
  position: relative;
}

.Styled_Dropdown_Button .ant-btn {
  border-color: transparent !important;
  box-shadow: none;
}

.Styled_Dropdown_Button .ant-btn:hover,
.Styled_Dropdown_Button .ant-btn:focus {
  border-color: transparent !important;
  color: unset !important;
}

.Styled_Dropdown_Button .ant-select-selection {
  border: 0px;
}

.Styled_Dropdown_Button .ant-select-selection {
  font-size: 16px;
  color: var(--dark);
}

.s_textFilter_list {
  width: 360px;
}

.s_textFilter {
  display: inline-block;
  position: relative;
  height: 24px;
  margin-right: 20px;
  font-family: MaisonNeue-Book;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--dark);
  cursor: pointer;
}

.s_textFilter.active::after {
  content: " ";
  position: absolute;
  height: 2px;
  width: 100%;
  margin: auto;
  bottom: -9px;
  left: 0;
  right: 0;
  display: block;
  background: #000;
}

.s_textFilter .filter_select {
  float: right;
  border: 1px solid #000;
}

.Styled_Menu {
  padding: 24px;
  width: 100%;
  overflow: hidden;
  background-color: white;
}

.S_overlay {
  width: 780px;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}

.S_overlay.ant-dropdown {
  width: 780px;
}

@media only screen and (min-width: 1px) {
  .Small_Screen_Frame {
    display: none;
  }
}

@media only screen and (max-width: 743px) {
  .Large_Screen_Frame {
    display: none !important;
  }
  .Small_Screen_Frame {
    display: block;
  }
  .S_overlay {
    width: 100%;
  }
}

@media screen and (min-width: 744px) {
  .S_overlay {
    width: 288px;
  }
}

@media only screen and (min-width: 768px) {
  .Small_Screen_Frame {
    display: none;
  }
}

@media only screen and (min-width: 1024px) {
  .S_overlay {
    width: 528px;
  }
}
