/* ************* Renderworkdetail ********************* */
.wk_slick-slider-customize {
  width: 600px;
  display: flex;
  flex-direction: column;
  margin-right: 40px;
}

.workdetail_edition:hover {
  text-decoration: underline;
}

.data th,
td {
  padding: 1px 30px 1px 0px;
  text-align: left;
}

.downloadIconWrap {
  padding-top: 24px;
  margin-left: 13px;
}

.downloadIconWrap .c__download {
  flex-direction: row;
}

.downloadIconWrap .c__download .pc__download {
  margin-right: 32px;
}

.downloadIcon {
  font-family: MaisonNeue-Book;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--dark);
  margin-left: 34px;
  cursor: pointer;
}

.downloadIcon-disable {
  cursor: default;
}

.socialIcon .anticon {
  margin-right: 28px;
}

html {
  scroll-behavior: smooth;
}

.wk_warp_pc {
  display: flex;
  flex-direction: row;
  padding-top: 122px;
}

.wk_warp_pc .slick-dots {
  display: flex;
  flex-direction: column;
  width: 15%;
  margin-right: 26px;
  display: flex;
  bottom: 0;
  position: static;
}

.wk_warp_pc .slick-track {
  height: auto !important;
}

.wk_warp_pc .slick-dots li {
  width: 100%;
  height: auto;
  margin-bottom: 19px;
  padding: 0;
  cursor: pointer;
}

.wk_warp_pc .slick-list {
  display: flex;
  width: 85%;
}

.wk_warp_pc .slick-dots li img {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.wk_warp_pc .slick-slider {
  margin: 24px 30px;
  width: 61%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-direction: row-reverse;
}

.wk_warp_pc .slider-img-div {
  height: 100%;
  text-align: left;
}

.wk_warp_pc .slider-img-div img {
  max-height: 100px;
}

.wk_warp_pc .ant-col-sm-24 {
  display: flex;
}

.m__show {
  display: none;
}

/******************************************* Media Query **************************************************************************/
/**********************************************************************************************************************************/
@media only screen and (max-width: 576px) {
  .wk_warp_pc .slick-dots {
    display: none;
  }
  .wk_warp_pc .slick-list {
    width: 100%;
  }
  .wk_warp_pc .downloadIconWrap .c__download {
    flex-direction: column;
  }
  .wk_warp_pc .ant-col-sm-24 {
    display: none;
  }
  .wk_warp_pc .slick-slider {
    width: 100%;
    margin: 0px;
  }
  .wk_slick-slider-customize {
    margin: 24px 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
  .wk_warp_pc {
    position: relative;
    margin: 80px 40px;
    margin-bottom: 0;
    padding-bottom: 20px;
    flex-direction: column;
  }
  .wk_warp_pc::after {
    content: "";
    position: absolute;
    left: -100px;
    right: 0;
    width: 200%;
    height: 1px;
    background-color: #e8e8e8;
    bottom: 0;
  }
  .wk_warp_pc .text_top_info {
    flex-direction: column;
  }
  .wk_warp_pc .ant-row {
    width: 100%;
  }
  .wk_warp_pc .slick-slider {
    margin: 24px 0;
    width: 58%;
  }
  .wk_warp_pc .slick-list {
    width: 90%;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
  .wk_warp_pc .slick-list {
    width: 100%;
  }
  .downloadIcon {
    margin-left: 0px;
    margin-right: 34px;
  }
}

.HeroCarouselMobile {
  height: 280px;
}

@media only screen and (max-width: 1023px) {
  .wm_Artist .ant-divider {
    display: none;
  }
}

@media only screen and (max-width: 1023px) {
  .HeroCarouselMobile {
    height: inherit;
  }
  .HeroCarouselMobile .slick-dots {
    position: relative;
    bottom: 0;
  }
  .WorkDetailVer2 .slick-dots li {
    width: 4px;
    height: 4px;
    margin-right: 10px;
  }
  .WorkDetailVer2 .slick-dots li button {
    border-radius: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    padding: 0;
  }
  .WorkDetailVer2 .slick-dots li button::before {
    display: none;
  }
  .WorkDetailVer2 .slick-dots li.slick-active {
    background: #000;
  }
  .WorkDetailVer2 .c__download {
    flex-direction: row;
  }
  .WorkDetailVer2 .c__download .pc__download:first-child {
    margin-right: 24px !important;
  }
  .WorkDetailVer2 .carouselImg_works {
    padding: 0;
  }
  .w_Materials,
  .w_m_downloadWrap {
    padding-right: 40px;
    padding-left: 40px;
  }
  .wm_Artist {
    padding-right: 40px;
    padding-left: 40px;
  }
  .wm_Artist .cc_header {
    border-bottom: 0;
    padding-bottom: 0;
  }
  .wm_Artist .info_left .ant-divider {
    margin: 24px 0;
  }
}

@media only screen and (max-width: 743px) {
  .wk_warp_mobile .slick-dots {
    bottom: -20px;
  }
  .w_Materials,
  .w_m_downloadWrap {
    padding-right: 24px;
    padding-left: 24px;
  }
  .wm_Artist {
    padding-left: 24px;
    padding-right: 24px;
  }
  .wm_Artist .title_link {
    display: none;
  }
  .HeroCarouselMobile .slick-dots {
    position: relative;
    bottom: 0;
  }
  .WorkDetailVer2 .c__download {
    flex-direction: column !important;
  }
}
