.ImgBanner {
  margin-top: 24px;
  margin-bottom: 40px;
}

.ImgBanner .banner_info {
  position: relative;
  margin-bottom: 40px;
}

.ImgBanner .thumb_box {
  height: 590px;
}

.ImgBanner .thumb_box img {
  width: 100%;
  height: 100%;
}

.ImgBanner .text_modal {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 15px;
  text-align: left;
  color: #fff;
  opacity: 1;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  background: linear-gradient(180deg, transparent 0%, rgba(60, 60, 60, 0.8) 100%);
}

.ImgBanner .subtitle {
  font-family: 'MaisonNeue-Medium';
  font-size: 12px;
  margin-bottom: 8px;
}

.ImgBanner .title {
  font-family: 'MaisonNeue-Demi';
  font-size: 20px;
  color: #fff;
  margin-bottom: 15px;
}

.ImgBanner .desc {
  font-family: 'MaisonNeue-Book';
  margin-bottom: 15px;
  font-size: 16px;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.mobile_article_arrow {
  font-family: 'MaisonNeue-Medium';
  display: none;
}

.mobile_article_arrow .anticon {
  vertical-align: text-top;
}

@media only screen and (max-width: 743px) {
  .ImgBanner {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .ImgBanner .banner_info:last-child {
    margin-bottom: 15px;
  }
  .ImgBanner .thumb_box {
    height: 234px;
  }
  .ImgBanner .text_modal {
    position: relative;
    background: #fff;
    color: var(--dark);
    padding-top: 8px;
    padding-bottom: 20px;
    padding-left: 24px;
    padding-right: 24px;
  }
  .ImgBanner .subtitle {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .ImgBanner .title {
    color: var(--dark);
    font-size: 32px;
  }
  .ImgBanner .desc {
    color: #979797;
    -webkit-line-clamp: 5 !important;
  }
  .ImgBanner .pc_article_arrow {
    display: none;
  }
  .ImgBanner .mobile_article_text {
    margin-right: 8px;
    font-size: 16px;
  }
  .ImgBanner .mobile_article_arrow {
    display: block;
  }
}

@media only screen and (min-width: 744px) {
  .ImgBanner .banner_info:last-child {
    margin-bottom: 15px;
  }
  .ImgBanner .thumb_box {
    height: 426px;
  }
}

@media only screen and (min-width: 1024px) {
  .ImgBanner .banner_info:last-child {
    margin-bottom: 15px;
  }
  .ImgBanner .thumb_box {
    height: 590px;
  }
}

@media only screen and (min-width: 1280px) {
  .ImgBanner .banner_info:last-child {
    margin-bottom: 15px;
  }
}
