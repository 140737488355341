.category_title {
  width: 100%;
  margin: 42px auto 24px auto;
  padding: 88px 140px 0 140px;
  /* width: 400px;
  height: 80px; */
  font-family: MaisonNeue-Bold;
  font-size: 60px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  text-align: left;
  color: var(--dark);
}

.column_item {
  position: relative;
  overflow: hidden;
}

@media only screen and (max-width: 743px) {
  .category_title {
    padding: 0 16px;
    font-size: 24px;
    line-height: 1.17;
    margin-top: 88px;
    width: 100%;
  }
}

@media only screen and (min-width: 744px) {
  .category_title {
    padding: 88px 40px 0 40px;
  }
}

@media only screen and (min-width: 1024px) {
  .category_title {
    padding: 88px 40px 0 40px;
  }
}

@media only screen and (min-width: 1280px) {
  .category_title {
    padding: 88px 140px 0 140px;
  }
}
