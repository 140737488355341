.pb100 {
  padding-top: 100px;
}

.mb100 {
  margin-bottom: 100px;
}

.carouselImgPc {
  vertical-align: middle;
  padding: 0 12px;
}

#fixedNav2 {
  display: none;
  min-height: 300px;
}

.video_link {
  position: relative;
  z-index: 5;
}

.VerWrap2 .exhibitionDetailWrap {
  text-align: left;
}

.VerWrap2 .exhibitionDetailInfo_title {
  font-family: "MaisonNeue-Demi";
  text-align: left;
  margin-bottom: 24px;
}

.VerWrap2 .exhibitionDetailInfo_sub_title {
  font-family: "MaisonNeue-Medium";
  margin-bottom: 20px;
  color: #979797;
  font-size: 18px;
}

.VerWrap2 .exhibitionDetailInfo_content {
  margin-bottom: 12px;
}

.VerWrap2 .exhibitionDetailInfo_content .content {
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  color: var(--dark);
}

.VerWrap2 .exhibitionDetailInfo_content .content .icon {
  margin-right: 10px;
}

.VerWrap2 .last_content {
  margin-bottom: 24px;
}

.VerWrap2 .smallSlidercard {
  margin-right: 40px;
}

.VerWrap2 .heroCarousel .slick-list {
  margin-top: 0;
}

.VerWrap2 .featurenewwork_container {
  margin: 0 20px;
}

.detail_link_group {
  display: flex;
  flex-direction: row;
  align-content: space-around;
  justify-content: space-between;
  padding: 24px 40px 64px 40px;
  font-size: 20px;
}

.detail_link_group .left_item .icon {
  margin-right: 10px;
}

.detail_link_group .right_item .icon {
  margin-left: 10px;
}

.detail_link_group a:active, .detail_link_group a:hover {
  color: var(--dark);
}

.mobile .downloadIconWrap {
  margin-left: 0;
  flex-direction: column;
}

.mobile .downloadIconWrap .downloadIcon,
.mobile .downloadIconWrap .downloadIcon-disable {
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  margin-left: 0;
  padding-bottom: 13px;
}

.mobile .downloadIconWrap .downloadIcon:last-child,
.mobile .downloadIconWrap .downloadIcon-disable:last-child {
  padding-bottom: 0;
}

.mobile .VrDialog {
  position: fixed;
  right: 0;
  top: 340px;
  z-index: 100;
}

.c_Description {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 40px;
  padding-bottom: 16px;
  text-align: left;
}

.c_Description .s_Overview {
  font-family: "MaisonNeue-Demi";
  margin-bottom: 24px;
  font-size: 24px;
  color: var(--dark);
}

.c_Description .s_Description {
  font-family: "MaisonNeue-Medium";
  margin-bottom: 12px;
  padding-bottom: 0;
}

.c_Description .s_Description p {
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
}

.c__slide {
  height: inherit;
}

.c__slide .smallSlider_image {
  position: relative;
  overflow: hidden;
}

.c__slide img {
  position: relative;
  margin: 0;
  max-width: 100%;
}

.presses_slider {
  height: inherit;
}

.presses_slider .horizontalSlide {
  height: inherit;
}

.presses_slider .smallSlider_image {
  width: 395px;
  height: 260px;
}

.presses_slider .c__title {
  font-family: "MaisonNeue-Demi";
  font-weight: normal;
  text-align: left;
  padding: 0;
  margin-top: 16px;
  margin-bottom: 8px;
  font-size: 16px;
  color: #979797;
}

.c__content {
  line-height: 1;
  position: relative;
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  color: var(--dark);
}

.c__content .text {
  padding-left: 8px;
}

.c__content .icon {
  position: absolute;
  top: -2px;
  left: -16px;
}

.c__download {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  padding-top: 0;
  text-align: left;
}

.m__download {
  position: relative;
}

.m__download .download_icon {
  vertical-align: text-bottom;
}

.m__download .download_text {
  vertical-align: middle;
  padding-left: 8px;
}

.pc_subTitle {
  font-family: "MaisonNeue-Medium";
  text-align: left;
  margin-bottom: 20px;
  color: #979797;
  font-size: 18px;
}

.pc_title {
  font-family: "MaisonNeue-Demi";
  text-align: left;
  font-size: 40px;
  max-width: 460px;
  padding-bottom: 0;
}

.view_content {
  margin-bottom: 12px;
  padding-bottom: 0;
}

.view_content:last-child {
  margin-bottom: 0;
}

.pc__download {
  line-height: 1;
  position: relative;
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  margin-bottom: 12px;
  margin-left: 0;
  cursor: pointer;
}

.pc__download:last-child {
  margin-bottom: 0;
}

#lockPage {
  width: 300px;
}

.spacer.s0 {
  min-height: 1px;
}

.work_module_tween {
  position: relative;
  padding-left: 60px;
  padding-right: 60px;
}

.work_module_tween .nav_btn {
  width: 400px;
}

.work_module_tween .nav_btn li {
  font-size: 24px;
}

.work_module_tween .scrollmagic-pin-spacer {
  float: left;
}

.work_module_tween .rights {
  padding-left: 300px;
  text-align: left;
}

.work_module_tween .rights .content {
  font-family: "MaisonNeue-Medium";
}

.pc_navTitle {
  transition: all 0.3s;
  background: transparent;
  border-bottom: 0;
  opacity: 0;
}

.pc_navTitle.active {
  opacity: 1;
}

.VrPopupPc {
  display: none;
  position: fixed;
  z-index: 10;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.8);
  padding: 72px;
}

.VrPopupPc .popup_c {
  width: 90%;
  height: 78vh;
  padding: 10px;
  margin: auto;
  color: #fff;
  border-radius: 2px;
  position: relative;
  overflow: hidden;
}

.VrPopupPc .closeBtn {
  margin-top: 25px;
  cursor: pointer;
}

.VrPopupPc iframe {
  border: 0;
  border-radius: 2px;
  position: relative;
  overflow: hidden;
}

.VrDialog {
  width: 549px;
  height: 176px;
  position: absolute;
  right: 0;
  bottom: 0px;
  z-index: 8;
  padding: 16px;
  background: rgba(0, 0, 0, 0.5);
  border-top-left-radius: 2px;
}

.VrDialog .vr_c {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.VrDialog .vr_c .vr_left {
  cursor: pointer;
  padding: 40px 0;
  width: 200px;
  margin-right: 40px;
  border-radius: 2px;
  background: url(/images/vr.png) no-repeat;
  background-size: 100% 100%;
}

.VrDialog .vr_c .vr_right {
  padding: 20px 0;
  font-size: 16px;
  color: #fff;
  text-align: left;
}

.VrDialog .vr_c .vr_right h1 {
  font-size: 24px;
  color: #fff;
}

.sm_VrDialog {
  position: absolute;
  right: 0;
  z-index: 6;
  top: 300px;
}

.artists_biography {
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  color: var(--dark);
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.pc_t_title {
  font-family: "MaisonNeue-Demi";
  text-align: left;
  font-size: 40px;
  max-width: 460px;
  padding-bottom: 0;
  transition: all 0.3s;
  background: transparent;
  border-bottom: 0;
  opacity: 1;
}

.pc_t_title.active {
  opacity: 1;
}

.pc .heroCarousel {
  position: relative;
  overflow: hidden;
}

.pc .heroCarousel .slick-arrow {
  height: 65px;
  width: 40px;
  border-radius: 0;
  border: none;
  background-color: rgba(255, 255, 255, 0.64) !important;
}

.pc .heroCarousel .slick-next {
  right: 0;
  z-index: 1;
}

.pc .heroCarousel .slick-prev {
  left: 0;
  z-index: 1;
}

.pc .heroCarousel .carouselImg {
  padding: 0;
}

.pc .exhibitionDetailWrap {
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 40px;
}

.pc .exhibitionDetailWrap .group .exhibitionDetailInfo_content:last-child {
  padding-bottom: 0;
}

.pc .exhibitionDetailWrap .downloadIconWrap {
  padding-top: 0;
  font-size: 18px;
}

.pc .exhibitionDetailWrap .exhibitionDetailInfo_title {
  font-size: 40px;
  max-width: 460px;
  padding-bottom: 0;
}

.work_module_tween .right_content {
  text-align: left;
  line-height: 28.8px;
}

.work_module_tween .right_content .content {
  font-size: 18px;
}

.work_module_tween .right_content .all_group {
  padding-bottom: 40px;
}

.work_module_tween .right_content .img_group {
  position: relative;
  line-height: 1;
}

.work_module_tween .right_content .name_group {
  padding-top: 24px;
  padding-bottom: 20px;
  font-size: 24px;
  font-weight: 700;
}

.work_module_tween .right_content .name_group p {
  margin: 0;
}

.work_module_tween .right_content .all_group_res {
  padding-bottom: 32px;
}

.work_module_tween .right_content .all_group_res .name_group {
  padding: 0;
  line-height: 28px;
  color: #fff;
  font-size: 19px;
  font-family: "MaisonNeue-Medium";
  font-style: normal;
  font-weight: 400;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: linear-gradient(180deg, transparent 0%, #3c3c3c 98.13%, #000000 98.13%);
  backdrop-filter: blur(2.71828px);
  z-index: 2;
}

.work_module_tween .right_content .all_group_res .name_group p {
  padding: 16px;
}

.work_module_tween .right_content .all_group_res img {
  -webkit-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
}

.work_module_tween .right_content .all_group_res .img_group {
  overflow: hidden;
}

.work_module_tween .right_content .all_group_res .img_group:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.work_module_tween .right_content .desc_group {
  padding-top: 20px;
  font-size: 18px;
  line-height: 28.8px;
}

.work_module_tween .right_content .more_group {
  text-align: left;
  padding-top: 20px;
  font-size: 18px;
}

.work_module_tween .right_content .more_group span {
  font-size: 18px;
}

.work_module_tween .right_content .video_group {
  padding-bottom: 40px;
}

.work_module_tween .right_content .video_group .video_title {
  font-weight: 700;
  font-size: 20px;
  padding-bottom: 20px;
}

.work_module_tween .right_content .press_all_group {
  display: flex;
  flex-wrap: wrap;
}

.work_module_tween .right_content .video_link .left {
  position: absolute;
  left: 140px;
}

.work_module_tween .right_content .video_link .right {
  position: absolute;
  right: 140px;
}

.work_module_tween .right_content .press_group {
  padding-right: 40px;
}

.work_module_tween .right_content .press_group .press_desc {
  padding: 15px 0;
  font-size: 16px;
  color: #979797;
}

.work_module_tween .home_nav {
  align-items: flex-start;
  flex-wrap: wrap;
}

.work_module_tween .left_nav {
  width: 400px;
  background: transparent;
  z-index: 6;
}

.work_module_tween .left_nav::after {
  display: none;
}

.work_module_tween .left_nav li {
  padding-bottom: 20px;
  font-size: 24px;
}

.work_module_tween .nav_group {
  padding-top: 120px;
  background: #fff;
}

.work_module_tween .pc_title {
  margin-bottom: 40px;
}

.work_module_tween .right_content {
  padding-left: 0;
}

.work_module_tween.fixed .left_nav {
  padding-top: 0px;
}

.work_module_tween.fixed .nav_group {
  padding-top: 140px;
}

.detail_card_list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

#DescriptionPc {
  padding-top: 120px;
  margin-bottom: 120px;
}

#ArtistsPc {
  margin-bottom: 120px;
}

#ArtistsPc .common_column {
  float: unset;
}

#ExhibitionPc {
  margin-bottom: 120px;
}

#ExhibitionPc .ant-row-flex {
  margin-bottom: 40px;
}

#ExhibitionPc .workImg img {
  max-width: 100%;
}

#ExhibitionPc .common_column {
  float: unset;
}

#ExhibitionPc .column_item {
  margin-bottom: 16px !important;
}

#ExhibitionPc .wpr {
  padding-right: 13px;
}

#ExhibitionPc .wprl {
  padding-left: 8px;
  padding-right: 8px;
}

#ExhibitionPc .wpl {
  padding-left: 13px;
}

#VideosPc {
  margin-bottom: 0px;
}

#VideosPc .list_video {
  height: 280px;
}

.heroCarousel_exhibitions {
  display: block;
}

.heroCarousel_exhibitions .slick-dots {
  bottom: 0;
}

.carouselImg_exhibitions {
  padding: 0 10px;
}

.render_artist_container .horizontal_slide {
  height: auto;
  min-height: 200px;
}

.render_artist_container .small_card {
  min-width: 256px;
  height: inherit;
  margin-right: 40px;
}

.render_artist_container .small_image {
  margin-right: 0px;
  width: 270px;
  height: 295px;
}

.render_works_container .small_card {
  float: left;
  width: auto !important;
  height: 295px;
}

.render_works_container .small_image {
  height: 295px;
}

.render_works_container .small_image img {
  max-width: unset;
  object-fit: unset;
}

.render_videos_container .title {
  font-family: "MaisonNeue-Demi";
  font-size: 24px;
  line-height: 1;
  text-align: left;
  margin-bottom: 24px;
}

.render_press_container .small_card {
  width: 395px;
  height: auto;
}

.render_press_container .small_image {
  height: 260px;
}

.mobile .slick-dots {
  position: relative;
}

.mobile .slick-dots li {
  width: 4px;
  height: 4px;
  margin-right: 10px;
}

.mobile .slick-dots li:last-child {
  margin-right: 0;
}

.mobile .slick-dots button {
  border-radius: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  padding: 0;
}

.mobile .slick-dots button::before {
  display: none;
}

.mobile .slick-active button {
  background: #000;
}

/**************************************************** media query ******************************************************/
@media only screen and (max-width: 1023px) {
  .mobile .ant-divider-horizontal {
    margin: 40px 0;
  }
}

@media only screen and (max-width: 743px) {
  .VerWrap2 .VrDialog {
    top: 370px;
  }
  .VerWrap2 .exhibitionDetailWrap {
    padding: 75px 24px 24px 24px;
    width: 100%;
    margin: 0;
  }
  .VerWrap2 .featurenewwork_container {
    margin: 0;
  }
  .VerWrap2 .smallSliderHeader .work_title {
    width: 100%;
  }
  .mobile .exhibitionDetailWrap {
    padding-top: 108px;
  }
  .mobile .c_Description {
    padding-left: 24px;
    padding-right: 24px;
  }
  .mobile .carouselImg {
    padding: 0 10px;
  }
  .detail_link_group {
    flex-direction: column;
  }
  .detail_link_group .text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;
    max-width: 75%;
    white-space: nowrap;
  }
  .detail_link_group .left_item {
    padding-bottom: 40px;
  }
  .presses_slider .smallSlider_image {
    width: 295px;
    height: 189px;
  }
  .render_press_container,
  .render_videos_container,
  .render_artist_container,
  .render_works_container {
    padding-left: 24px;
    padding-right: 24px;
  }
  .render_videos_container .list_video {
    height: 222px;
  }
  .render_press_container .small_card {
    width: 295px;
    height: auto;
  }
  .render_press_container .small_image {
    height: 190px;
  }
}

@media only screen and (min-width: 744px) and (max-width: 1023px) {
  .heroCarousel_exhibitions .carouselImg {
    padding: 0 12px;
  }
  .exhibitionDetailInfo_title {
    font-size: 40px;
  }
  .mobile .exhibitionDetailWrap {
    padding: 110px 40px 24px 40px;
    width: 100%;
    margin: 0;
  }
  .mobile .downloadIconWrap {
    padding-top: 0;
    margin-left: 0;
  }
  .mobile .Description {
    padding-top: 80px;
  }
  .VerWrap2 .horizontal_Wrap {
    height: auto;
  }
  .VerWrap2 .horizontal_slide {
    height: inherit;
    min-height: 200px;
  }
  .render_press_container,
  .render_videos_container,
  .render_artist_container,
  .render_works_container {
    padding-left: 40px;
    padding-right: 40px;
  }
  .render_videos_container .list_video {
    height: 272px;
  }
  .render_videos_container .video {
    width: 450px;
  }
}

@media only screen and (min-width: 1024px) {
  .VerWrap2 .heroCarousel .slick-track {
    height: inherit;
    overflow: hidden;
  }
}

@media only screen and (max-width: 1279px) {
  .pc .exhibitionDetailWrap {
    padding-top: 140px;
    padding-left: 60px;
    padding-right: 60px;
  }
  .pc .pc_title {
    max-width: 360px;
  }
}

@media only screen and (min-width: 1280px) {
  .pc .exhibitionDetailWrap {
    padding-top: 140px;
    padding-left: 140px;
    padding-right: 140px;
  }
  .work_module_tween {
    padding-left: 140px;
    padding-right: 140px;
  }
}
