.c__download .download_icon {
  position: absolute;
  top: -3px;
  left: -16px;
}

.c__download .download_text {
  padding-left: 8px;
}

.desktop-navigation {
  background: transparent;
  height: 100px;
  line-height: 1.25;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9;
  border-bottom: 1px solid var(--pale-blue);
}

.desktop-navigation .wrap {
  height: 100%;
}

#logo {
  text-align: left;
  margin-bottom: 0px;
}

#search {
  font-family: MaisonNeue-Book;
  font-size: 21px;
  color: var(--dark);
  line-height: 1.43;
  text-align: right;
}

.ant-menu-horizontal {
  border: none;
}

.ant-menu-horizontal > .ant-menu-item:hover {
  border-bottom: 2px solid white;
  color: white;
}

.ant-menu-horizontal > .ant-menu-item-selected {
  border-bottom: 2px solid white;
  color: white;
}

#midlayer {
  position: fixed;
  size: fill;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(28, 29, 34, 0.6);
  display: none;
}

.phoneNavVer2 {
  top: 50px !important;
}

.xsNav {
  text-align: left;
  padding-top: 28px;
}

.xsLogo {
  padding-top: 28px;
}

/************************************************************ dropdown menu  ******************************************************************/
.pcHeader .ant-menu-item,
.pcHeader .ant-menu-submenu {
  margin-left: 40px;
  padding: 0;
}

.ant-menu-sub .names {
  width: 75%;
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
  column-fill: auto;
  -webkit-column-gap: 40px;
  /* Chrome, Safari, Opera */
  -moz-column-gap: 40px;
  /* Firefox */
  column-gap: 40px;
}

.ant-menu-sub .viewallbtn {
  width: 20%;
  padding-left: 5%;
}

.navArtists .ant-menu-sub {
  width: 100%;
  padding: 0px 140px;
  display: flex;
  flex-direction: column;
}

.ant-menu-submenu-hidden {
  display: none !important;
}

.navArtists .ant-menu-item > a {
  font-family: MaisonNeue-Book;
  font-size: 18px;
  line-height: 1.67;
  text-align: left;
  color: var(--dark);
}

.ant-menu-submenu-popup {
  top: 80px;
  width: 100%;
  border-radius: 0;
}

.ant-dropdown {
  left: 0px;
  top: 70px;
  width: 100%;
  position: relative;
  text-align: left;
  background: white;
}

.ant-dropdown .ant-menu {
  padding-top: 40px;
}

.viewallbtn .anticon {
  top: -5px;
  right: -15px;
  /* position: absolute; */
}

.xsMenu {
  font-family: 'MaisonNeue-Medium';
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--dark);
}

#xsNav li {
  margin-bottom: 40px;
}

.ant-menu-vertical > .ant-menu-item {
  height: 50px;
}

.ant-menu-horizontal > .ant-menu-item > a {
  color: var(--dark);
}

.ant-menu-horizontal > .ant-menu-item > a:hover {
  text-decoration: none;
  color: var(--white);
}

.ant-menu-vertical > .ant-menu-item > a:hover {
  text-decoration: none;
  color: var(--dark);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: var(--white);
}

.xsMenu .anticon {
  position: absolute;
  top: -2px;
  left: 83px;
}

/******************************************* Search Bar **************************************************************************/
.search-container {
  float: left;
  padding: 24px 40px;
}

.hiddenSearchBar {
  overflow: hidden;
  background-color: var(--white);
  width: 100%;
  height: 96px;
  top: 80px;
  z-index: 999;
  display: none;
}

.hiddenSearchBar input[type='text'] {
  padding: 6px;
  margin-top: 8px;
  font-size: 16px;
  border: none;
}

/************************************************** footer bar ***************************************************************/
.copyright {
  visibility: visible;
}

/************************************************** Version 2 ***************************************************************/
.navWorks.ant-menu-submenu-popup {
  width: 250px;
}

.navWorks .artistListContainer {
  display: block;
}

.navWorks .artistListItems {
  display: block;
}

.navWorks .ant-menu-sub {
  width: 100%;
}

.navWorks .ant-menu-item > a {
  font-family: MaisonNeue-Book;
  font-size: 18px;
  line-height: 1.67;
  text-align: left;
  color: var(--dark);
}

.desktop-navigation {
  transition: all 0.3s;
  border-bottom: 0;
}

.desktop-navigation .xsNavBtn {
  border: 0;
  background: transparent;
}

.desktop-navigation .pc-submenu svg path {
  stroke: white;
}

.desktop-navigation .ant-menu-submenu-title {
  padding: 0;
}

.desktop-navigation .galleryAllLogo path {
  fill: transparent;
}

.desktop-navigation .galleryAllLogo g {
  fill: #fff;
}

.desktop-navigation .iconMenulogo path {
  fill: #fff;
}

.desktop-navigation .iconChevronDown path {
  fill: #fff;
}

.desktop-navigation .ant-menu {
  background: transparent;
}

.desktop-active {
  background: var(--white);
}

.desktop-active .galleryAllLogo path {
  fill: transparent;
}

.desktop-active .galleryAllLogo g {
  fill: #000;
}

.desktop-active .pc-submenu svg path {
  stroke: black;
}

.desktop-active .iconMenulogo path {
  fill: #1c1d22;
}

.desktop-active .iconChevronDown path {
  fill: #1c1d22;
}

.xsHide {
  display: none;
}

.xsHide.xsShow {
  display: block;
}

.xsNavVer2 {
  position: fixed;
  z-index: 8;
  top: 88px;
  width: 100%;
  height: calc(100% - 88px);
  left: 0;
  background: #fff;
  text-align: left;
  overflow-y: auto;
}

.xsNavVer2 .nTitle {
  padding: 18px 24px;
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  cursor: pointer;
}

.xsNavVer2 .nTitle .icon {
  float: right;
  transition: transform 0.3s;
}

.xsNavVer2 .nTitle .xsShow {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  transition: transform 0.3s;
}

.xsNavVer2 .linkItem {
  padding: 18px 24px;
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  cursor: pointer;
}

.xsNavVer2 .linkItem .xsMenuLink {
  display: inline-block;
  width: 100%;
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--dark);
}

.childrenList,
.artistsChild {
  padding-left: 24px;
  padding-right: 24px;
  background-color: var(--pale-blue);
}

.childrenList .viewMore,
.artistsChild .viewMore {
  font-family: "MaisonNeue-Medium";
  font-size: 16px;
  padding-top: 14px;
  padding-bottom: 14px;
}

.childrenList .viewMore span,
.artistsChild .viewMore span {
  margin-right: 8px;
}

.childrenList .namesItem,
.artistsChild .namesItem {
  font-family: "MaisonNeue-Medium";
  font-size: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.childrenList .artName,
.artistsChild .artName {
  font-family: "MaisonNeue-Medium";
  font-size: 16px;
  color: #979797;
  padding-top: 14px;
  padding-bottom: 10px;
}

.artistsChild .namesItem {
  padding-left: 16px;
}

/******************************************* Media Query **************************************************************************/
/**********************************************************************************************************************************/
@media only screen and (max-width: 576px) {
  .ant-menu-sub .names {
    width: 100%;
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
  .ant-menu-sub .viewallbtn {
    width: 100%;
    padding-left: 0px;
    margin-bottom: 24px;
    font-size: 14px;
    text-align: left;
  }
  .viewallbtn li {
    text-align: left;
  }
  .viewallbtn button {
    padding: 0;
  }
  .ant-dropdown .ant-menu {
    padding-top: 0px;
    width: 100% !important;
  }
  .navWorks .ant-menu-item > a {
    font-size: 16px;
  }
  .navArtists .ant-menu-item > a {
    font-size: 16px;
  }
  /* .ant-menu-submenu-arrow {
      display: none;
    } */
  .xsMenu .anticon {
    position: absolute;
    top: 2px;
  }
  .viewallbtn .anticon {
    top: -4px;
    right: 28px;
    /* position: absolute; */
  }
  .viewallbtn .isvg svg {
    width: 20px;
  }
  .names .ant-menu-item {
    margin: 8px 0 !important;
    padding-left: 0 !important;
  }
  .names .ant-menu-item {
    font-family: MaisonNeue-Book;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--dark);
  }
  /* phone screen header style */
  .xsNav .xsNavBtn {
    border: white;
    color: var(--dark);
    padding-left: 0;
  }
  #logo {
    font-size: 19.1px;
  }
  #search {
    margin-top: 18px;
  }
  /* artist submenu position */
  .ant-menu-submenu-placement-rightTop {
    top: 230px !important;
  }
  .thing1.extra_stuff::after {
    content: 'Some parts of me, changed!';
    background-color: #8888cc;
  }
  .ant-menu-submenu {
    margin: 40px 0 !important;
  }
  .ant-menu-item {
    padding-left: 16px !important;
    margin: 40px 0 !important;
  }
  .ant-menu-submenu-title {
    padding-left: 16px !important;
  }
  .ant-menu-item-selected > a,
  .ant-menu-item > a {
    color: var(--dark);
  }
  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    border-right: none;
  }
  .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
    right: 280px;
    color: black;
  }
  .menu-submenu-title:hover {
    color: var(--dark);
  }
  .dropdownartist .ant-menu-inline {
    padding: 24px 32px 40px 32px;
    background-color: var(--pale-blue);
  }
  .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
    background: var(--dark);
  }
  /************ footer bar ***********/
}

@media only screen and (max-width: 576px) and (max-width: 576px) {
  .gallery_footer .InputEmailAddress {
    width: 150px;
  }
  .gallery_footer .SignupButton {
    float: right;
  }
}

@media only screen and (max-width: 576px) {
  .socialMedia_container .socialIcon {
    margin-right: 19px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
  .navWorks .ant-menu-sub {
    padding: 24px 40px 40px 40px;
    background-color: var(--pale-blue);
    display: flex;
    flex-direction: column-reverse;
  }
  .navArtists .ant-menu-sub {
    padding: 0px 40px 0px 40px;
    background-color: var(--pale-blue);
    display: flex;
    flex-direction: column-reverse;
  }
  .ant-menu-sub .names {
    width: 100%;
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    column-fill: auto;
    -webkit-column-gap: 30px;
    /* Chrome, Safari, Opera */
    -moz-column-gap: 30px;
    /* Firefox */
    column-gap: 30px;
  }
  .ant-menu-sub .viewallbtn {
    width: 30%;
    padding-left: 5px;
    margin-bottom: 24px;
    font-size: 16px;
  }
  .viewallbtn button {
    padding: 0;
  }
  .navWorks .ant-menu-item > a {
    font-size: 16px;
  }
  .navArtists .ant-menu-item > a {
    font-size: 16px;
  }
  .ant-menu-submenu-arrow {
    display: none;
  }
  .xsMenu .anticon {
    position: absolute;
    top: 2px;
  }
  .viewallbtn .anticon {
    top: -4px;
    right: 28px;
    /* position: absolute; */
  }
  .viewallbtn .isvg svg {
    width: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .navWorks .ant-menu-sub {
    padding: 24px 40px 40px 40px;
  }
  .navArtists .ant-menu-sub {
    padding: 0px 40px 0px 40px;
  }
  .ant-menu-sub .names {
    width: 85%;
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    column-fill: auto;
    -webkit-column-gap: 30px;
    /* Chrome, Safari, Opera */
    -moz-column-gap: 30px;
    /* Firefox */
    column-gap: 30px;
  }
  .ant-menu-sub .viewallbtn {
    width: 10%;
    padding-left: 2%;
  }
  .ant-menu-horizontal > .ant-menu-item,
  .ant-menu-horizontal > .ant-menu-submenu {
    margin-left: 3%;
    padding: 0;
  }
  .ant-layout-header {
    padding: 0 0 0 10px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .navWorks .ant-menu-sub {
    padding-left: 22px;
  }
  .navArtists .ant-menu-sub {
    padding: 0px 40px 0px 40px;
  }
}

@media only screen and (max-width: 1023px) {
  .xsNav {
    display: block;
  }
  .xsNavBtn {
    padding-left: 0;
  }
  .xsLogo #logo {
    text-align: center;
  }
  .xsLogo.ant-col-md-4 {
    width: 66.66666667%;
  }
}

@media only screen and (max-width: 743px) {
  .ant-layout-header {
    padding-left: 24px;
    padding-right: 24px;
  }
  .xsNav {
    padding-top: 10px;
  }
  .xsLogo {
    padding-top: 14px;
  }
}

@media only screen and (min-width: 1px) and (max-width: 1023px) {
  .ant-layout-header {
    height: 88px;
  }
  .xsNavVer2 {
    top: 88px;
    height: calc(100% - 88px);
  }
  .xsNav {
    padding-top: 28px;
  }
  .xsLogo {
    padding-top: 22px;
    width: 66.66666667% !important;
  }
}

@media only screen and (max-width: 768px) {
  #logo {
    text-align: center;
  }
}

@media only screen and (min-width: 744px) {
  .ant-layout-header {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media only screen and (min-width: 1024px) {
  .ant-layout-header {
    padding-left: 60px;
    padding-right: 60px;
  }
  .xsNav {
    display: none;
  }
  .xsNavDesktop {
    display: block;
  }
}

@media only screen and (min-width: 1280px) {
  .ant-layout-header {
    padding-left: 140px;
    padding-right: 140px;
  }
}
