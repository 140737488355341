.aboutHeader_board.privacy {
    height: unset;
}

.about_middle_des.privacy {
    text-align: left;
}

.aboutHeader_content_list.privacy>li {
    margin: 5px 0;
    line-height: 1.5;
}

.privacy_subtitle {
    font-family: MaisonNeue-Bold;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--dark);
    margin: 20px 0;
}

.about_image_des.privacy_subcontent {
    width: 100%;
    line-height: 29px;
    font-size: 16px;
    font-family: MaisonNeue-Book;
}

.privacyVer2 .aboutHeader_title {
    text-align: center;
}

.privacyVer2 .aboutHeader {
    position: relative;
}

.privacy_tips {
    position: absolute;
    bottom: 0;
    left: 0px;
    background: rgba(0, 0, 0, 0.64);
    color: #fff;
    font-size: 12px;
    line-height: 28px;
    height: 28px;
    padding: 0 8px;
}

.mb0 {
    margin-bottom: 0;
}

.privacy_tips_mobile {
    display: none;
}

@media only screen and (max-width: 744px) {
    .privacyVer2 .aboutHeader {
        height: 355px;
        z-index: 1;
    }

    .privacyVer2 .about_middle_des.privacy {
        margin: 40px auto !important;
    }

    .privacy_tips {
        display: none;
    }

    .privacyVer2 .privacy_subtitle {
        margin-top: 0;
    }

    .privacyVer2 .about_image_des.privacy_subcontent {
        padding-bottom: 40px;
    }

    .privacyVer2 .about_image_des.privacy_subcontent:last-child {
        padding-bottom: 0;
    }

    .pb40 {
        padding-bottom: 40px;
        margin-bottom: 0;
    }

    .privacy_tips_mobile {
        display: block;
        padding: 0 24px;
        position: relative;
        background: #fff;
        color: #979797;
        opacity: 0.88;
        text-align: left;
        margin-top: 12px;
        line-height: 16px;
        height: auto;
    }
}

@media only screen and (min-width: 768px) {
    .privacyVer2 .privacy_subtitle {
        margin-top: 0;
    }

    .privacyVer2 .about_image_des.privacy_subcontent {
        padding-bottom: 40px;
    }

    .privacyVer2 .about_image_des.privacy_subcontent:last-child {
        padding-bottom: 0;
    }

    .pb40 {
        padding-bottom: 40px;
        margin-bottom: 0;
    }

    .privacyVer2 .about_middle_des.privacy {
        margin: 100px auto;
        padding: 0 60px;
    }
}

@media only screen and (max-width: 1023px) {
    .privacyVer2 .about_middle_des.privacy {
        margin: 64px auto;
    }
}


@media only screen and (min-width: 1280px) {
    .privacyVer2 .privacy_subtitle {
        margin-top: 0;
    }

    .privacyVer2 .about_image_des.privacy_subcontent {
        padding-bottom: 40px;
    }

    .privacyVer2 .about_image_des.privacy_subcontent:last-child {
        padding-bottom: 0;
    }

    .pb40 {
        padding-bottom: 40px;
        margin-bottom: 0;
    }

    .about_middle_des.privacy {
        margin: 120px auto !important;
    }
}