.both {
  clear: both;
}

.home_nav {
  width: 100%;
  display: flex;
  position: relative;
}

.nav_btn {
  margin: 0;
  padding: 0;
}

.nav_btn li {
  font-family: MaisonNeue-Demi;
  line-height: 1;
  list-style: none;
  font-size: 40px;
  text-align: left;
  padding-bottom: 32px;
  color: #979797;
  cursor: pointer;
}

.nav_btn i {
  font-style: unset;
  color: #979797;
}

.nav_btn li.active {
  color: var(--dark);
}

.nav_btn li.active i {
  color: var(--dark);
}

.left_nav {
  position: relative;
  width: 493px;
  height: 100%;
  background: #fff;
}

.left_nav::after {
  content: '';
  position: absolute;
  width: 1px;
  height: 80vh;
  background: #ebebeb;
  right: 0;
  top: 80px;
}

.left_nav .nav_group {
  padding-top: 120px;
  max-width: 364px;
  width: 100%;
  min-height: 340px;
}

.left_nav .nav_text {
  font-family: MaisonNeue-Medium;
  font-size: 18px;
  line-height: 1.6;
  text-align: left;
  padding-bottom: 40px;
}

#sectionNavs2 {
  display: none;
}

#Collection .left_nav {
  z-index: 6;
}

#Works .left_nav::after,
#Artists .left_nav::after {
  visibility: hidden;
}

.Artists-News .left_nav::after {
  visibility: visible !important;
}

.right_content {
  flex: 1;
  width: 62%;
  padding-left: 64px;
  padding-right: 0;
}

.right_content .more_group {
  font-family: 'MaisonNeue-Medium';
  font-size: 20px;
  clear: both;
  text-align: right;
}

.right_content .more_group span {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
}

.right_content .more_group svg {
  margin-left: 8px;
}

.collocation-link {
  display: block;
  width: 100%;
  margin-bottom: 40px;
  overflow: hidden;
}

.content-item {
  display: flex;
  justify-content: start;
}

.collection_group {
  position: relative;
}

.collection_group .collection_group_image {
  height: 295px;
  text-align: left;
  overflow: hidden;
  margin: 0;
}

.collection_group .collection_group_image img {
  transition: all 0.5s;
  height: 100%;
  width: 100%;
}

.collection_group .collection_name_group {
  opacity: 0;
  transition: all 0.5s;
}

.collection_group:hover img {
  transform: scale(1.1, 1.1);
}

.collection_group:hover .collection_name_group {
  opacity: 1;
}

.collection_name_group {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 101px;
  right: 0;
  padding: 15px;
  background: linear-gradient(180deg, transparent 0%, rgba(60, 60, 60, 0.8) 100%);
}

.collection_name_group .pub_name {
  text-align: left;
}

.collection_name_group .name1 {
  font-family: 'MaisonNeue-Book';
  font-style: normal;
  font-size: 12px;
  line-height: 12px;
  color: #ffffff;
  margin-bottom: 8px;
}

.collection_name_group .name2 {
  font-family: 'MaisonNeue-Book';
  font-style: normal;
  font-size: 20px;
  line-height: 20px;
  color: #ffffff;
  margin-bottom: 10px;
}

.collection_name_group .name3 {
  font-size: 20px;
}

.artists-thumb-image {
  width: 100%;
  height: 100%;
  background-image: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
}

.artists_centent {
  position: relative;
  overflow: hidden;
  width: 667px;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.artists_centent .artists_group {
  transition: all 0.5s;
  position: relative;
  width: 125px;
  height: 667px;
}

.artists_centent .collection_name_group {
  height: 85px;
}

.artists_centent .artists_group_image {
  width: 100%;
}

.artists_centent .artists_group_image img {
  height: 667px;
  width: 100%;
}

.artists_centent .artists_name_group {
  width: 100%;
  display: none;
  transition: all 0.5s;
}

.artists-link {
  display: block;
  margin-bottom: 40px;
  overflow: hidden;
  width: auto;
  height: auto;
  margin-right: 10px;
}

.artists-link:nth-child(2) {
  margin-top: -60px;
}

.artists-link:nth-child(4) {
  margin-top: 30px;
}

.artists-link:nth-child(5) {
  margin-top: 10px;
}

.artists-link:last-child {
  margin-right: 0;
}

.artists-link.active {
  min-width: 468px;
}

.artists-link.active .artists_group {
  min-width: 468px;
}

.artists-link.active .artists_name_group {
  display: block;
  opacity: 1;
}

.artists-link.unactive {
  width: 38px;
}

.artists-link:hover .artists_group {
  max-width: 100%;
  width: 600px;
}

.artists-link:hover .artists_name_group {
  display: block;
  opacity: 1;
}
