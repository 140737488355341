@charset "UTF-8";
/* 公共样式 */
.public_v2 {
  position: relative;
  padding-bottom: 20px;
  height: auto;
  overflow: hidden;
}

.v2Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}

.v2Title {
  font-family: "MaisonNeue-Demi";
  font-size: 24px;
  color: var(--dark);
  text-align: left;
}

.v2More {
  font-family: "MaisonNeue-Medium";
  font-size: 16px;
  margin-bottom: 0;
  font-weight: normal;
}

.v2More .icon {
  padding-left: 8px;
  position: relative;
  top: -3px;
}

.v2More a:active, .v2More a:hover {
  color: var(--dark);
}

.v2Slide {
  width: 100%;
  height: 310px;
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  margin-left: 0px;
  -webkit-overflow-scrolling: touch;
}

.v2Slide a:active, .v2Slide a:hover {
  color: var(--dark);
}

.v2Card {
  position: relative;
  margin-right: 24px;
  padding: 0;
  min-width: 200px;
}

.v2Image {
  text-align: center;
  overflow: hidden;
  margin: 0;
}

.v2Image img {
  height: 100%;
  width: auto;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.v__author {
  font-family: "MaisonNeue-Medium";
  font-weight: normal;
  font-size: 12px;
}

.v__name {
  font-family: "MaisonNeue-Demi";
  font-weight: normal;
  font-size: 20px;
  color: #fff;
  margin: 4px 0;
}

.v__artName {
  font-family: "MaisonNeue-Medium";
  font-weight: normal;
  font-size: 20px;
}

.v__more {
  visibility: visible;
}

.v2NameBox {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 15px;
  background: linear-gradient(180deg, transparent 0%, rgba(60, 60, 60, 0.8) 100%);
  color: #fff;
  text-align: left;
}

.v2ArtNameBox {
  padding-bottom: 5px;
  margin-top: 18px;
}

.v2Slide .v2Image {
  height: 295px;
}

/* v2 collection 重写的样式*/
.collection_v2 .v2Slide {
  height: 295px;
}

.collection_v2 .v2Card {
  min-width: 200px;
}

/* v2 work 重写的样式*/
.work_v2 {
  height: auto;
}

.work_v2 .v2Card {
  min-width: 200px;
}

/* v2 Artists 重写的样式*/
.artists_v2 {
  visibility: visible;
}

.artists_v2 .v2Slide {
  height: inherit;
}

.artists_v2 .v__artName {
  text-align: left;
}

@media only screen and (max-width: 743px) {
  .collection_v2 .v2Card {
    width: 280px;
  }
  .work_v2 .v2Card {
    width: 280px;
  }
  .artists_v2 .v2Card {
    width: 280px;
  }
  .v2Header {
    display: block;
  }
  .v2Title {
    margin-bottom: 24px;
  }
  .v2More {
    text-align: left;
  }
}
