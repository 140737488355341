body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 375px;
}

@font-face {
  font-family: "MaisonNeue-Medium";
  src: url("/fonts/MaisonNeue/MaisonNeue-Medium.ttf");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: "MaisonNeue-Bold";
  src: url("/fonts/MaisonNeue/MaisonNeue-Bold.ttf");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: "MaisonNeue-Light";
  src: url("/fonts/MaisonNeue/MaisonNeue-Light.ttf");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "MaisonNeue-Book";
  src: url("/fonts/MaisonNeue/MaisonNeue-Book.ttf");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "MaisonNeue-Demi";
  src: url("/fonts/MaisonNeue/MaisonNeue-Demi.ttf");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "MaisonNeue-Mono";
  src: url("/fonts/MaisonNeue/MaisonNeue-Mono.ttf");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}
/* @font-face {
  font-family: "RomanaBT";
  src: url("/fonts/romana/RomanaBT-Bold.otf");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: "RomanaBT-Roman";
  src: url("/fonts/romana/romanan.ttf");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
} */
