.common_wrapper {
  margin: 24px 0 81px 0;
}

.common_column {
  position: unset !important;
  flex-direction: column;
  float: left;
  box-sizing: border-box;
}

.img_banner {
  margin-top: 24px;
  margin-bottom: 40px;
}

.wpl {
  padding-left: 16px;
}

.wprl {
  padding-left: 16px;
  padding-right: 16px;
}

.wpr {
  padding-right: 16px;
}

.wp4_r {
  padding-right: 24px;
}

.wp4_c1 {
  padding-left: 8px;
  padding-right: 16px;
}

.wp4_c2 {
  padding-left: 16px;
  padding-right: 8px;
}

.wp4_l {
  padding-left: 24px;
}

.wp3_r {
  padding-right: 21px;
}

.wp3_c1 {
  padding-left: 12px;
  padding-right: 11px;
}

.wp3_l {
  padding-left: 21px;
}

.wt50 {
  width: 50% !important;
}

.wt100 {
  width: 100% !important;
}

.popup_box {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0.64));
  transition: all 0.4s linear 0s;
}

.popup_box .mask {
  line-height: 1;
}

.popup_box .popup_body {
  font-family: MaisonNeue-Medium;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-align: left;
  padding: 0 40px;
}

.popup_box .author {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #fff !important;
  margin-bottom: 8px;
  opacity: 0.9;
}

.popup_box .title {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 1.2;
  color: #fff !important;
}

.collections_wrapper .column_item {
  min-height: 100px;
  margin-bottom: 32px !important;
}

.collections_wrapper .lazyimg {
  position: relative;
  overflow: hidden;
}

.collections_wrapper .lazyimg img {
  margin-top: 0;
  max-width: 100%;
}

.popup_group {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 10px;
  opacity: 1;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  background: linear-gradient(180deg, transparent 0%, rgba(60, 60, 60, 0.8) 100%);
}

.popup_group .t_body {
  text-align: left;
  color: #fff;
}

.popup_group .t_author {
  font-size: 12px;
  line-height: 1.2;
  font-family: 'MaisonNeue-Medium';
  padding-bottom: 8px;
}

.popup_group .t_title {
  font-family: 'MaisonNeue-Medium';
  font-size: 19px;
  line-height: 1.4;
  padding-bottom: 8px;
}

.popup_group .t_icon {
  visibility: visible;
}

.modelsEx {
  padding: 16px;
}

.modelsEx .t_title {
  padding-bottom: 0;
}

.artists_wrapper {
  margin: 40px 0 81px 0;
}

.artists_wrapper .column_item {
  margin-bottom: 25px;
}

.artists_wrapper .lazyimg {
  position: relative;
  overflow: hidden;
}

.artists_wrapper .lazyimg img {
  margin-top: 0;
  height: 100%;
}

.artists_wrapper .desc_group {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 10px;
  opacity: 1;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  background: linear-gradient(180deg, transparent 0%, rgba(60, 60, 60, 0.8) 100%);
}

.artists_wrapper .card_title {
  font-family: MaisonNeue-Demi;
  font-size: 20px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: -0.1px;
  text-align: left;
  color: #fff;
}

.artists_wrapper .card_details {
  text-align: left;
  color: #fff;
  display: none;
  opacity: 0;
  transition: all 0.5s;
}

.artists_wrapper .card_details .text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.artists_wrapper .column_item img {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  height: 100%;
  width: 100%;
}

.artists_wrapper .column_item:hover .desc_group {
  opacity: 1;
}

.artists_wrapper .column_item:hover .card_title {
  display: none;
}

.artists_wrapper .column_item:hover .card_details {
  display: block;
  opacity: 1;
}

.artists_wrapper .column_item:hover img {
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
}

.exhibitions_wrapper .column_item {
  min-height: 100px;
  margin-bottom: 32px !important;
}

.exhibitions_wrapper .lazyimg {
  position: relative;
  overflow: hidden;
}

.exhibitions_wrapper .lazyimg img {
  margin-top: 0;
  max-width: 100%;
}

.presses_wrapper .ant-row-flex {
  margin-bottom: 32px;
}

.presses_wrapper .lazyimg {
  margin-bottom: 16px;
  position: relative;
  overflow: hidden;
}

.presses_wrapper .lazyimg img {
  margin-top: 0px;
  width: 100%;
}

.presses_wrapper .card_name {
  font-family: MaisonNeue-Demi;
  color: #979797;
  font-size: 16px;
  line-height: 1.2;
}

.worksItems_wrapper .lazyimg {
  position: relative;
  overflow: hidden;
}

.worksItems_wrapper .lazyimg img {
  margin-top: 0px;
  width: 100%;
}

.worksItems_wrapper .column_item:hover .popup_box {
  opacity: 1;
}

.worksItems_wrapper .wpr {
  padding-right: 8px;
}

.worksItems_wrapper .wprl {
  padding-left: 8px;
  padding-right: 8px;
}

.worksItems_wrapper .wpl {
  padding-left: 8px;
}

.Items_Styled_Masonry {
  position: unset !important;
  height: unset !important;
  display: flex;
  flex-flow: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.Items_Styled_Masonry .lazyimg img {
  margin-top: 0;
}

.Items_Styled_Masonry .column_item {
  margin-bottom: 25px;
}

.Items_Styled_Masonry .column_item:hover .popup_box {
  opacity: 1;
}

.c_Masonry {
  position: unset;
  height: unset !important;
  display: flex;
  flex-flow: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.c_text_div {
  margin-top: 5px;
  margin-bottom: 15px;
  text-align: left;
}

.c_card_name {
  font-family: MaisonNeue-Demi;
  color: #979797;
  font-size: 16px;
  line-height: 1.2;
}

@media screen and (max-width: 743px) {
  .common_column {
    width: 100%;
  }
  .common_column .column_item {
    margin-bottom: 40px;
  }
  .common_column:last-child {
    margin-right: 0px;
  }
}

@media screen and (min-width: 744px) {
  .common_column {
    width: 50%;
  }
  .common_column .column_item {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1024px) {
  .common_column {
    width: 33.3333%;
  }
}

@media screen and (min-width: 1280px) {
  .common_column {
    width: 25%;
  }
}

@media screen and (min-width: 1440px) {
  .collections_wrapper .lazyimg {
    width: 100%;
    height: auto;
  }
  .collections_wrapper .lazyimg img {
    top: unset;
    left: unset;
    transform: unset;
  }
}
