.wk_work-selected-image {
  width: 100%;
  height: 505px;
  overflow: hidden;
}

.wk_work-selected-image div {
  height: 100%;
}

.wk_work-selected-image img {
  height: 100%;
}

.wk_work-slider-outside-div {
  margin-top: 10px;
  overflow: hidden;
  width: 600px;
}

.wk_work-slider-div {
  max-width: 100%;
  max-height: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}

.wk_slider-img-div {
  min-width: 150px;
  height: 100px;
  margin: 0 20px 0 0;
  overflow: hidden;
  text-align: left;
  cursor: pointer;
}

.wk_slider_image {
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  object-fit: scale-down;
}

.wk_artist_container {
  display: flex;
  margin-top: 40px;
}

.wk_artist_container .info_left {
  width: 260px;
  margin-right: 82px;
  text-align: left;
}

.wk_artist_container .info_right {
  flex: 1 1;
  font-family: "MaisonNeue-Medium";
  text-align: left;
  font-size: 18px;
}

.wk_artist_container .info_right p:last-child {
  margin-bottom: 0;
}

.wk_artist_container .info_img {
  width: 260px;
  height: 295px;
  margin-bottom: 24px;
}

.wk_artist_container .info_img img {
  max-width: 100%;
  max-height: 100%;
}

.wk_artist_container .info_name {
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 49px;
  font-family: "MaisonNeue-Demi";
}

.w_Artist .info_link {
  text-align: left;
}

.w_Artist .m_a_t_Artist {
  display: block;
  padding-bottom: 24px;
  margin: 0;
}

.w_Artist .m_a_t_Artist:last-child {
  padding: 0;
}

.w_Artist span.text {
  font-family: "MaisonNeue-Medium";
  font-size: 20px;
  margin-right: 8px;
}

.wk_renderWorkDetail_text {
  flex: 1;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.wk_renderWorkDetail_text .ant-divider-horizontal {
  margin: 0 0 24px 0;
}

.wk_workdetail_artist {
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  letter-spacing: normal;
  color: var(--dark);
  margin-bottom: 20px;
}

.wk_workdetail_artist:hover, .wk_workdetail_artist:hover {
  text-decoration: underline;
}

.wk_workdetail_title {
  font-family: "MaisonNeue-Demi";
  font-size: 40px;
  letter-spacing: normal;
  color: var(--dark);
  margin-bottom: 20px;
}

.wk_title_gray {
  font-family: "MaisonNeue-Medium";
  font-size: 14px;
  color: #979797;
  padding-bottom: 12px;
}

.wk_workdetail_edition {
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  color: var(--dark);
  margin-bottom: 20px;
}

.wk_workdetail_container .title {
  font-family: "MaisonNeue-Medium";
  font-size: 14px;
  color: var(--steel-grey);
  margin-bottom: 12px;
}

.wk_workdetail_container .data {
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  color: var(--dark);
  margin-bottom: 20px;
}

.wk_workdetail_container span {
  font-family: "MaisonNeue-Demi";
}

.wk_ButtonWrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  flex-direction: column;
}

.wk_ButtonWrap .wk_button_pc {
  margin-top: 0;
  width: 400px;
  height: 42px;
  border-radius: 0;
  text-align: center;
  height: 48px;
  line-height: 48px;
  border-radius: 0;
  background-color: var(--dark);
  font-family: "MaisonNeue-Demi";
  font-size: 18px;
  letter-spacing: 2px;
  color: var(--white);
}

.wk_ButtonWrap .wk_button_pc:hover {
  cursor: pointer;
}

.wk_downloadIconWrap {
  margin-top: 25px;
}

.wk_downloadIconWrap .c__download {
  flex-direction: row;
}

.wk_downloadIconWrap .pc__download {
  margin-bottom: 0;
}

.wk_downloadIconWrap .pc__download:first-child {
  margin-right: 12px;
}

.wk_downloadIconWrap .download_icon {
  position: relative;
  left: 0;
  top: 0;
  line-height: 1;
  vertical-align: text-top;
}

.wk_info_pc {
  margin-bottom: 120px;
}

.wMobile .ant-divider {
  margin: 40px 0;
}

.wk_warp_mobile {
  display: flex;
  flex-direction: column;
  padding-top: 110px;
}

.w_m_text {
  flex: 1;
  text-align: left;
  display: flex;
  flex-direction: column;
  padding-top: 17px;
  margin-bottom: 20px;
}

.w_m_text .s_name {
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  margin-bottom: 20px;
}

.w_m_text .s_title {
  font-family: "MaisonNeue-Demi";
  font-size: 40px;
  max-width: 80%;
  line-height: 1.2;
}

.w_m_text a:active, .w_m_text a:hover {
  color: var(--dark);
}

.w_button_div {
  text-align: center;
  background-color: var(--dark);
  font-family: "MaisonNeue-Demi";
  font-size: 18px;
  letter-spacing: 2px;
  color: var(--white);
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  line-height: 1;
}

.w_button_div:hover {
  cursor: pointer;
}

.w_m_buttonWrap {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.w_m_buttonWrap.fiexd {
  position: absolute;
  right: 0;
  top: 110px;
}

.w_m_downloadWrap {
  position: relative;
  margin-bottom: 24px;
}

.w_m_downloadWrap .download_icon {
  position: relative;
  left: 0;
  top: 0;
  display: inline-block;
  vertical-align: text-top;
}

.wk_collections_warp {
  flex: 1 1;
  padding-top: 24px;
  text-align: left;
  display: flex;
  flex-direction: row;
}

.wm_ctitle {
  margin-bottom: 20px;
  padding-right: 20px;
  min-width: 38%;
}

.wm_ctitle .title_gray {
  font-family: "MaisonNeue-Medium";
  font-size: 14px;
  color: #979797;
  margin-bottom: 12px;
}

.wm_ctitle .workdetail_edition {
  font-family: "MaisonNeue-Demi";
  font-size: 18px;
}

.wm_ctitle .workdetail_edition .icon {
  margin-left: 8px;
}

.wm_ctitle a:active, .wm_ctitle a:hover {
  color: var(--dark);
  text-decoration: underline;
}

.wm_csize {
  margin-bottom: 20px;
  min-width: 26%;
  padding-right: 20px;
}

.wm_csize .title {
  font-family: "MaisonNeue-Medium";
  font-size: 14px;
  margin-bottom: 12px;
}

.wm_csize .data {
  font-family: "MaisonNeue-Demi";
  font-size: 18px;
  margin-bottom: 0px;
}

.wm_ctable {
  margin-bottom: 20px;
  min-width: 200px;
}

.wm_ctable .title {
  font-family: "MaisonNeue-Medium";
  font-size: 14px;
  margin-bottom: 12px;
}

.wm_ctable .data {
  font-family: "MaisonNeue-Medium";
  font-size: 14px;
}

.wm_ctable .data span {
  font-family: "MaisonNeue-Demi";
}

.wm_ctable2 {
  text-align: left;
}

.w_Materials {
  flex: 1 1;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.w_Materials .title {
  font-family: "MaisonNeue-Medium";
  font-size: 14px;
  margin-bottom: 12px;
}

.w_Materials .data {
  font-family: "MaisonNeue-Demi";
  font-size: 18px;
  margin-bottom: 0px;
}

.w_youtubeLink {
  visibility: visible;
}

.wk_warp_mobile .w_button_div {
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

.wm_Video .title {
  font-family: "MaisonNeue-Demi";
  text-align: left;
  font-size: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #efefef;
}

.wm_Artist {
  visibility: visible;
}

.vw__Desktop {
  display: block;
}

.vw__Mobile {
  display: none;
}

.wk_collections_container {
  margin-bottom: 120px;
}

.wk_collections_container .v2Slide {
  height: auto;
}

.wk_collections_container .v2Card {
  float: left;
  width: auto !important;
}

.wk_collections_container .v2Image img {
  left: 0;
  top: 0;
  object-fit: unset;
  transform: unset;
}

.wk_collections_container.s_border {
  margin-bottom: 0 !important;
  border-bottom: 0 !important;
}

.wk_works_container {
  padding-bottom: 120px;
}

.wk_works_container .cc_header {
  margin-bottom: 40px;
}

.wk_works_container .common_wrapper {
  margin: 0;
}

@media only screen and (max-width: 1023px) {
  .vw__Desktop {
    display: none;
  }
  .vw__Mobile {
    display: block;
  }
  .w_m_text {
    padding-right: 40px;
    padding-left: 40px;
  }
  .wk_collections_warp {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 48px;
  }
  .wm_ctable2 {
    display: none;
  }
  .swDetail .wm_ctable {
    display: none;
  }
  .swDetail .wk_collections_warp .wm_ctable {
    display: block;
  }
  .wk_collections_warp .title {
    color: #979797;
  }
  .w_Materials .title {
    color: #979797;
  }
  .wm_ctable2 .title {
    color: #979797;
  }
  .wm_Video {
    border-bottom: 1px solid #efefef;
    padding-bottom: 40px;
    margin-bottom: 40px;
    padding-right: 40px;
    padding-left: 40px;
  }
  .wm_Video .title {
    border-bottom: 0;
  }
  .wm_Video .video {
    height: 402px;
  }
  .wk_artist_container .info_left {
    margin-right: 40px;
  }
  .wk_collections_container,
  .wk_works_container {
    border-bottom: 1px solid #efefef;
    margin-bottom: 40px;
  }
  .wk_collections_container .common_wrapper,
  .wk_works_container .common_wrapper {
    padding-left: 40px;
    padding-right: 40px;
  }
  .wk_collections_container {
    padding-right: 40px;
    padding-left: 40px;
  }
  .wk_collections_container .work_v2 {
    padding-bottom: 40px;
  }
  .wk_works_container {
    border: 0;
    margin: 0;
    padding-right: 40px;
    padding-left: 40px;
    padding-bottom: 40px;
  }
  .wk_works_container .cc_header {
    border: 0;
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 743px) {
  .w_m_text {
    padding-right: 24px;
    padding-left: 24px;
  }
  .w_m_text .s_title {
    max-width: 100%;
  }
  .wk_collections_warp {
    padding-left: 24px;
    padding-right: 24px;
  }
  .wk_warp_mobile .wm_ctable {
    display: none;
  }
  .wk_warp_mobile .wm_ctable2 {
    display: block;
  }
  .swDetail .wm_ctable {
    display: block;
  }
  .swDetail .wm_ctable2 {
    display: none;
  }
  .wm_ctable2 {
    padding-left: 24px;
    padding-right: 24px;
  }
  .wk_collections_container,
  .wk_works_container {
    padding-left: 24px;
    padding-right: 24px;
  }
  .wk_collections_container .cc_header,
  .wk_works_container .cc_header {
    margin-left: 0;
    margin-right: 0;
  }
  .wk_collections_container .cc_header .title,
  .wk_works_container .cc_header .title {
    margin-bottom: 24px;
  }
  .wk_collections_container .common_wrapper,
  .wk_works_container .common_wrapper {
    padding-left: 24px;
    padding-right: 24px;
  }
  .wm_Video {
    padding-left: 24px;
    padding-right: 24px;
  }
  .wm_Video .title {
    border: 0;
  }
  .wm_Video .video {
    height: 222px;
  }
  .wk_artist_container {
    flex-direction: column;
  }
  .wk_artist_container .info_left {
    width: 100%;
    margin-right: 0;
  }
  .wk_artist_container .info_img {
    width: 100%;
    height: auto;
  }
  .wk_artist_container .info_name {
    margin-bottom: 24px;
  }
  .wk_artist_container .p__show {
    display: none;
  }
  .wm_Artist .info_img {
    height: auto;
  }
  .wm_Artist .info_link span.text {
    font-size: 16px;
  }
  .wm_Artist .ant-divider {
    display: block;
    margin: 24px 0;
  }
  .wm_Artist .m__show {
    display: block;
  }
}

@media only screen and (min-width: 1024px) {
  .wk_work-slider-outside-div,
  .wk_slick-slider-customize {
    width: 544px;
  }
  .wk_warp_pc {
    padding-left: 60px;
    padding-right: 60px;
    margin-bottom: 120px;
  }
  .w_Artist {
    padding-left: 60px;
    padding-right: 60px;
  }
  .wk_collections_container .cc_header,
  .wk_works_container .cc_header {
    margin-left: 60px;
    margin-right: 60px;
  }
  .wk_collections_container .common_wrapper,
  .wk_works_container .common_wrapper {
    padding-left: 60px;
    padding-right: 60px;
  }
  .wk_ButtonWrap .wk_button_pc {
    width: 320px !important;
  }
  .wm_Video {
    padding-left: 60px;
    padding-right: 60px;
    margin-bottom: 120px;
  }
  .wm_Video .list_video {
    margin-top: 40px;
    height: 508px;
  }
}

@media only screen and (min-width: 1280px) {
  .wk_warp_pc {
    padding-left: 140px;
    padding-right: 140px;
    margin-bottom: 160px;
  }
  .wk_collections_container .cc_header {
    margin-left: 140px;
    margin-right: 140px;
  }
  .wk_collections_container .worksItems_wrapper {
    padding-left: 140px;
    padding-right: 140px;
  }
  .w_Artist {
    padding-left: 140px;
    padding-right: 140px;
  }
  .wk_works_container .cc_header {
    margin-left: 140px;
    margin-right: 140px;
  }
  .wk_works_container .common_wrapper {
    padding-left: 140px;
    padding-right: 140px;
  }
  .wm_Video {
    padding-left: 140px;
    padding-right: 140px;
  }
  .wm_Video .list_video {
    height: 702px;
  }
}

@media only screen and (min-width: 1440px) {
  .wk_work-slider-outside-div,
  .wk_slick-slider-customize {
    width: 720px;
  }
  .wk_slick-slider-outside-div {
    width: 720px;
  }
  .wk_slick-selected-image {
    width: 720px;
  }
}
