.press-artist-title,
.press-image-title {
  padding-top: 100px;
  font-weight: 600;
  text-align: left;
}

.press-title {
  margin: 100px 0 30px 0;
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  background-color: #eaeaea;
  align-items: center;
  padding: 30px 10vw 30px 20vw;
  width: 100%;
  color: #333;
}

.press-title .press-title-text {
  font-size: 23px;
  font-weight: 600;
  max-width: 32.5vw;
  word-wrap: break-word;
  word-break: break-word;
  text-align: left;
}

.press-title .press-title-year {
  font-size: 16px;
  font-weight: normal;
  text-align: left;
  padding-top: 30px;
  width: 40vw;
}

.press-title-image {
  width: 30vw;
}

.press-title-content {
  width: 40vw;
}

.press-title-image img {
  width: 15vw;
  height: auto;
}

.press-title-description {
  text-align: left;
}

.press-link {
  margin-top: 20px;
  float: left;
  text-decoration: underline;
}

.press-link a {
  color: #ca970a;
}

.press-view-all-button {
  margin-bottom: 50px;
}

.press-block .FeaturedCarousel_Container.artist {
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .press-block .FeaturedCarousel_Container.artist {
    margin: 70px 16px 50px 16px;
  }
  .press-block .horizontalSlide {
    height: fit-content;
  }
  .press-block .horizontalSlide_Wrap {
    height: fit-content;
  }
  .press-artist-title,
  .press-image-title {
    padding-top: 70px;
  }
  .featurenewwork_container {
    padding-top: 0;
  }
  .press-title-image img {
    width: 20vw;
    height: auto;
  }
  .press-block {
    margin: 0 calc(7.22% + 16px);
  }
}

@media only screen and (max-width: 767px) {
  .press-title {
    padding: 30px 5vw 30px 5vw;
  }
  .press-title .press-title-text {
    font-size: 15px;
    max-width: 100%;
  }
  .press-title-image {
    width: 100%;
  }
  .press-title-image img {
    width: 35vw;
  }
}

@media only screen and (min-width: 768px) {
  .press-block {
    margin: 0 calc(20px + 2.78%);
  }
  .press_works {
    margin: 0;
  }
  .press-title {
    padding: 30px 15vw 30px 15vw;
  }
  .press-title .press-title-text {
    font-size: 20px;
    max-width: 100%;
  }
  .press-title-image {
    width: 100%;
  }
  .press-title-image img {
    width: 25vw;
  }
}

@media only screen and (min-width: 1920px) {
  .press-block {
    margin: 0 159px;
  }
  .press_works {
    margin: 0;
  }
  .press-title .press-title-text {
    font-size: 30px;
    max-width: 100%;
  }
  .press-artist-title,
  .press-image-title {
    margin-left: 20px;
  }
}

@media only screen and (min-width: 1024px) {
  .press-titles {
    font-size: 28px;
  }
}

@media only screen and (min-width: 768px) {
  .press-titles {
    font-size: 26px;
  }
}

@media only screen and (max-width: 767px) {
  .press-titles {
    font-size: 18px;
  }
  .heroCarousel2 .slick-dots {
    position: relative;
    bottom: inherit !important;
  }
}

.press-line {
  margin-bottom: 80px;
}

.pressDetail_artists {
  margin: 0;
}

.pressDetail_artists .ant-col {
  padding: 0;
}

.pressDetail_artists .featurenewwork_container {
  margin: 0;
  margin-top: 40px;
}

.pressDetail_artists .smallSliderHeader {
  display: none;
}

.artist_slide {
  margin-top: 40px;
  height: inherit;
}

.artist_slide .v2Card {
  width: 280px;
}

.artist_slide .v2Image {
  height: 295px;
}

.artist_slide .v2ArtNameBox {
  margin-top: 16px;
  text-align: left;
}

.artist_slide .v2Image img {
  width: 100%;
  left: 0;
  top: 0;
  transform: unset;
}

.artist_slide a:hover, .artist_slide a:active {
  color: var(--dark);
}

.pressDetail_exhibtions .horizontal_Slide {
  margin-top: 40px;
  height: 295px;
}

.pressDetail_exhibtions .column_item {
  max-width: 280px;
}

.pressDetail_exhibtions .lazyimg {
  height: 100%;
}

.pressDetail_exhibtions .lazyimg img {
  margin-top: 0;
  height: 100%;
  max-height: 100%;
}

.press_works .common_wrapper {
  margin: 0;
  margin-top: 40px;
}

.Other_Presses .presses_wrapper {
  margin: 0;
  margin-top: 40px;
}

.press_detail_wrap {
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
}

.press_block {
  padding-bottom: 80px;
}

.pressDetail_flex {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 80px;
}

.pressDetail_swiper {
  width: 566px;
  height: 380px;
  margin-right: 40px;
  overflow: hidden;
}

.pressDetail_swiper .heroCarousel2 {
  line-height: 1;
  height: 100%;
}

.pressDetail_swiper .heroCarousel2 button {
  height: 65px;
  width: 40px;
  border-radius: 0;
  border: none;
  background-color: rgba(255, 255, 255, 0.64) !important;
}

.pressDetail_swiper .heroCarousel2 button.slick-arrow.slick-next {
  background-color: white;
}

.pressDetail_swiper .heroCarousel2 button.slick-arrow.slick-prev {
  background-color: white;
}

.pressDetail_swiper .heroCarousel2 .slick-prev {
  left: 0;
  top: 158px;
  z-index: 1;
}

.pressDetail_swiper .heroCarousel2 .slick-next {
  right: 0;
  top: 158px;
}

.pressDetail_swiper .heroCarousel2 .slick-prev:before {
  content: url("/images/miscellaneous/icon-chevron-left.svg") !important;
}

.pressDetail_swiper .heroCarousel2 .slick-next:before {
  content: url("/images/miscellaneous/icon-chevron-right.svg") !important;
}

.mb_40 {
  margin-bottom: 40px;
}

.pressDetail_info {
  overflow: hidden;
  text-align: left;
  flex: 1;
}

.pressDetail_info a:active, .pressDetail_info a:hover {
  color: var(--dark);
}

.pressDetail_info .title {
  font-family: "MaisonNeue-Demi";
  font-size: 24px;
  margin-bottom: 20px;
}

.pressDetail_info .time {
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
}

.pressDetail_info .desc {
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
}

.pressDetail_info .more {
  font-family: "MaisonNeue-Medium";
  font-size: 16px;
  cursor: pointer;
}

.pressDetail_info .more .anticon {
  padding-left: 8px;
}

.pressDetail_info .download {
  margin-left: 0;
  font-family: "MaisonNeue-Demi";
  font-size: 18px;
  cursor: pointer;
}

.pressDetail_info .anticon {
  padding-right: 8px;
  vertical-align: text-top;
}

.pressDetail_iphone,
.pressDetail_ipad {
  border-bottom: 1px solid #efefef;
  margin-bottom: 40px;
}

.pressDetail_iphone .slick-dots li,
.pressDetail_ipad .slick-dots li {
  width: 4px;
  height: 4px;
  background: #000;
  opacity: 0.3;
  margin-right: 18px;
}

.pressDetail_iphone .slick-dots li:last-child,
.pressDetail_ipad .slick-dots li:last-child {
  margin-right: 0;
}

.pressDetail_iphone .slick-dots li.slick-active,
.pressDetail_ipad .slick-dots li.slick-active {
  background: #000;
  opacity: 1;
}

.pressDetail_iphone .slick-dots li button,
.pressDetail_ipad .slick-dots li button {
  width: 4px;
  height: 4px;
  padding: 0;
}

.pressDetail_iphone .slick-dots li button:before,
.pressDetail_ipad .slick-dots li button:before {
  width: 4px;
  height: 4px;
  content: "";
}

.pressDetail_ipad {
  padding-bottom: 80px;
}

.pressDetail_ipad .slick-arrow {
  display: none !important;
}

.pressDetail_iphone .pressDetail_info {
  margin-top: 48px;
}

.worksItems_wrapper {
  margin-bottom: 0;
}

@media only screen and (max-width: 1023px) {
  .press_detail_wrap .cc_header {
    border: 0;
    padding-bottom: 8px;
  }
  .mb_40 {
    margin-bottom: 32px;
  }
  .press_detail_wrap {
    padding-top: 120px;
    padding-bottom: 24px;
  }
  .pressDetail_ipad {
    padding-bottom: 50px;
    margin-bottom: 64px;
  }
  .press_block {
    padding-bottom: 40px;
  }
  .pressDetail_ipad,
  .pressDetail_flex,
  .pressDetail_exhibtions,
  .pressDetail_artists {
    padding-left: 40px;
    padding-right: 40px;
  }
  .press_works .cc_header {
    margin-left: 40px;
    margin-right: 40px;
  }
  .Other_Presses .cc_header {
    margin-left: 40px;
    margin-right: 40px;
  }
  .press-line {
    border-bottom: 1px solid #efefef;
    margin-bottom: 40px;
  }
  .pressDetail_flex {
    display: none;
  }
  .pressDetail_iphone {
    display: none;
  }
  .heroCarousel2 .slick-dots {
    position: relative;
    bottom: inherit !important;
  }
}

@media only screen and (max-width: 744px) {
  .press_detail_wrap {
    padding-bottom: 8px;
  }
  .press_detail_wrap .cc_header {
    border: 0;
    display: block;
  }
  .press_detail_wrap .cc_header .title {
    padding-bottom: 20px;
  }
  .pressDetail_info .mb_40:last-child {
    margin-bottom: 0;
  }
  .pressDetail_iphone,
  .pressDetail_ipad,
  .pressDetail_flex,
  .pressDetail_exhibtions,
  .pressDetail_artists {
    padding-left: 24px;
    padding-right: 24px;
  }
  .press_works .cc_header {
    margin-left: 24px;
    margin-right: 24px;
  }
  .press_works .common_wrapper {
    margin: 0;
    margin-top: 32px;
    padding-top: 0;
  }
  .Other_Presses {
    padding: 0;
  }
  .Other_Presses .cc_header {
    margin-left: 24px;
    margin-right: 24px;
  }
  .Other_Presses .common_wrapper {
    margin: 0;
    margin-top: 32px;
    padding-top: 0;
  }
  .pressDetail_ipad {
    display: none;
  }
  .pressDetail_iphone {
    display: block;
  }
}

@media only screen and (min-width: 1024px) {
  .press_detail_wrap {
    padding-top: 137px;
  }
  .press_detail_wrap .press_block {
    padding-bottom: 20px;
  }
  .pressDetail_flex,
  .pressDetail_exhibtions,
  .pressDetail_artists {
    padding-left: 60px;
    padding-right: 60px;
  }
  .press_works .cc_header {
    margin-left: 60px;
    margin-right: 60px;
  }
  .Other_Presses .cc_header {
    margin-left: 60px;
    margin-right: 60px;
  }
  .pressDetail_ipad {
    display: none;
  }
  .pressDetail_iphone {
    display: none;
  }
}

@media only screen and (min-width: 1280px) {
  .press_detail_wrap {
    padding-top: 169px;
  }
  .press_detail_wrap .press_block {
    padding-bottom: 80px;
  }
  .pressDetail_flex,
  .pressDetail_exhibtions,
  .pressDetail_artists {
    padding-left: 140px;
    padding-right: 140px;
  }
  .press_works .cc_header {
    margin-left: 140px;
    margin-right: 140px;
  }
  .Other_Presses .cc_header {
    margin-left: 140px;
    margin-right: 140px;
  }
}
