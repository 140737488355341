.mondrian_wrapper .card_name {
  font-family: MaisonNeue-Bold;
  font-size: 21px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: var(--dark);
}

/* work_artist_names 非静态生成的类名 */
.mondrian_wrapper .author_names,
.mondrian_wrapper .years {
  font-family: MaisonNeue;
  font-size: 14px;
  color: var(--dark);
}
