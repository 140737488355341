.v__mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.about_fixed {
  height: 760px;
  width: 100%;
}

.aboutHeader {
  background-image: url("/images/aboutus2/image1.jpg");
  background-size: cover;
  background-position: center;
  height: 740px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.aboutHeader_board {
  bottom: 160px;
  width: 100%;
  background: transparent;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

.aboutHeader_title {
  font-family: "MaisonNeue-Demi";
  font-size: 60px;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.aboutHeader_content {
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  margin-top: 40px;
}

.chinese {
  margin-top: 32px;
}

.scoll_body {
  position: relative;
  background: #fff;
  width: 100%;
}

.scoll_body .c_page {
  position: relative;
  overflow: hidden;
}

.v_img {
  width: 100%;
  height: 740px;
  max-height: 100%;
}

.v__image2 {
  position: relative;
  height: 740px;
}

.v__image3 {
  position: relative;
  height: 720px;
  margin-bottom: 120px;
}

.v__image3 img {
  height: 100%;
}

.about_middle_des {
  margin: 136px auto;
  display: flex;
  flex-direction: column;
}

.about_description {
  width: 100%;
  margin-bottom: 100px;
}

.about_image {
  position: relative;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}

.image_before_des {
  width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.v_image_before_des {
  width: 100%;
  max-height: 100%;
  object-fit: cover;
  height: 660px;
}

.about_image_des {
  width: 100%;
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  text-align: left;
  color: var(--dark);
  padding-bottom: 48px;
}

.about_address_wrap {
  width: 90%;
  border-top: 1px solid var(--pale-blue);
  border-bottom: 1px solid var(--pale-blue);
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  padding: 80px 0;
}

.about_address {
  text-align: left;
  font-family: MaisonNeue-Book;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--dark);
}

.about_address .about_address_title {
  font-family: MaisonNeue-Book;
  font-size: 21px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--dark);
}

.aboutusBtn {
  margin: 139px auto;
  width: 464px;
  height: 69px;
  border-radius: 40px;
  border: solid 1px var(--dark);
  background-color: var(--white);
  font-family: MaisonNeue-Demi;
  font-size: 21px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  text-align: center;
  letter-spacing: 2px;
  color: var(--dark);
  padding: 25px 0;
}

.aboutusWrap .sc-eHgmQL {
  margin-top: 136px;
}

.about_tips {
  font-family: "MaisonNeue-Medium";
  font-size: 12px;
  text-align: left;
  position: absolute;
  bottom: 0;
  left: 0px;
  background: rgba(0, 0, 0, 0.64);
  color: #fff;
  padding: 8px;
}

.about_tips_mobile {
  font-family: "MaisonNeue-Medium";
  font-size: 12px;
  text-align: left;
  display: none;
  background: #fff;
  color: #979797;
  opacity: 0.88;
  margin-top: 12px;
  padding: 0;
}

/******************************************* Media Query **************************************************************************/
/**********************************************************************************************************************************/
@media only screen and (min-width: 744px) {
  .about_middle_des {
    padding: 0 40px;
    margin: 64px auto;
  }
  .about_image_des {
    padding-bottom: 40px;
  }
  .about_description {
    margin-bottom: 48px;
  }
  .aboutHeader_board {
    bottom: 160px;
    padding: 0 40px;
  }
  .aboutHeader_content {
    margin-top: 24px;
  }
  .aboutHeader_content.chinese {
    margin-top: 16px;
  }
  .aboutusWrap .cc_header {
    margin: 0 40px;
  }
}

@media only screen and (max-width: 1023px) {
  .aboutHeader_title {
    font-size: 40px;
  }
  .about_middle_des {
    padding: 0 40px;
    margin: 64px auto;
  }
  .aboutHeader_board {
    bottom: 86px;
    padding-left: 40px;
    padding-right: 40px;
  }
  .aboutHeader {
    height: 480px;
  }
  .about_fixed {
    height: 500px;
  }
  .v__image2 {
    height: 480px;
  }
  .v_image_before_des {
    height: 400px;
  }
  .v__image3 {
    height: 480px;
    margin-bottom: 64px;
  }
}

@media only screen and (max-width: 743px) {
  .aboutHeader {
    height: 600px;
  }
  .aboutHeader_board {
    width: 100%;
    bottom: 40px;
    padding-left: 24px;
    padding-right: 24px;
  }
  .about_fixed {
    height: 620px;
  }
  .about_tips {
    display: none;
  }
  .about_tips_mobile {
    display: block;
  }
  .about_middle_des {
    margin-top: 80px;
  }
  .about_image_des {
    padding-bottom: 32px;
  }
  .about_middle_des {
    padding: 0 24px;
    margin: 40px auto;
  }
  .about_image_des-haschild {
    padding-bottom: 24px;
  }
  .aboutHeader_title {
    font-size: 40px;
    line-height: 1.11;
  }
  .aboutHeader_content {
    font-size: 18px;
    line-height: 1.43;
  }
  .about_description {
    width: 100%;
    margin-bottom: 40px;
  }
  .v_image_before_des {
    height: 240px;
  }
  .about_image_des {
    width: 100%;
  }
  .aboutusWrap iframe {
    height: 192px;
  }
  .about_address_wrap {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  .about_address {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
  }
  .about_address_title {
    margin-bottom: 16px;
  }
  .aboutusBtn {
    width: 318px;
    height: 48px;
    font-size: 14px;
    margin: 64px auto;
    padding-top: 17px;
  }
  .aboutusWrap .cc_header {
    margin: 0 24px;
  }
  .scoll_body .common_wrapper {
    margin: 0;
    padding-top: 0;
  }
  .v__image2 {
    height: auto;
  }
  .v__image2 .about_tips_mobile {
    padding-left: 24px;
    padding-right: 24px;
  }
  .v__image2 .v_img {
    height: 355px;
  }
  .v__image3 {
    height: 207px;
  }
  .v__image3 {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media only screen and (min-width: 1024px) {
  .aboutHeader_board {
    padding-left: 60px;
    padding-right: 60px;
  }
  .aboutusWrap .cc_header {
    margin: 0 60px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
  .aboutHeader_title {
    font-size: 48px;
  }
  .about_middle_des {
    padding: 0 60px;
  }
  .aboutHeader {
    height: 560px;
  }
  .about_fixed {
    height: 580px;
  }
  .v__image2 {
    height: 560px;
  }
  .v_image_before_des {
    height: 560px;
  }
  .about_image_des {
    padding-bottom: 40px;
  }
  .v__image3 {
    height: 560px;
    margin-bottom: 100px;
  }
}

@media only screen and (min-width: 1280px) {
  .aboutHeader_board {
    bottom: 160px;
    padding: 0 140px;
  }
  .aboutHeader_content {
    margin-top: 40px;
  }
  .about_middle_des {
    padding: 0 140px;
    margin: 120px auto;
  }
  .about_description {
    margin-bottom: 100px;
  }
  .v_image_before_des {
    height: 660px;
  }
  .about_address_wrap {
    width: 94%;
  }
  .about_image_des-haschild {
    padding-bottom: 24px;
  }
  .aboutHeader_content.chinese {
    margin-top: 32px;
  }
  .aboutusWrap .cc_header {
    margin: 0 140px;
  }
}
