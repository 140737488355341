.pcHeader {
  padding-bottom: 0px;
}

.artistListContainer {
  display: flex;
  flex-direction: row;
}

.navWorks .menu-item-artist {
  width: 100%;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}

.navArtists .menu-item-artist {
  text-align: left;
  line-height: 1;
  padding: 14px 0;
  white-space: normal;
}

@media only screen and (min-width: 1024px) {
  .navArtists .menu-item-artist {
    max-width: 33%;
    width: 33%;
  }
}

@media only screen and (min-width: 1024px) {
  .navArtists .menu-item-artist {
    max-width: 25%;
    width: 25%;
  }
}

.artistListItems {
  display: flex;
  flex-wrap: wrap;
  font-size: 18px;
  width: 100%;
}

.navWorks .artistListItems {
  width: 100%;
}

.navWorks .ant-menu-vertical .ant-menu-item {
  height: fit-content;
}

.navArtists .ant-menu-vertical .ant-menu-item {
  height: fit-content;
  margin-top: 0px;
  margin-bottom: 0px;
}

/*  */
.v_viewallbtn {
  width: 100% !important;
  text-align: right;
  border-top: 1px solid #ebebeb;
}

.v_viewallbtn a {
  display: inline-block;
  line-height: 1;
}

.ViewAllButton {
  width: 126px;
  height: 24px;
  border: none;
}

@media (max-width: 576px) {
  .ViewAllButton {
    background: var(--ice-blue);
    text-align: left;
  }
}

.ViewAllText {
  display: inline-block;
  font-family: MaisonNeue-Demi;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: 2px;
  color: var(--dark);
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.ViewAllText .t_text {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  margin-right: 8px;
}

@media (max-width: 576px) {
  .ViewAllText {
    font-size: 14px;
    line-height: 1;
  }
}

/*  */
.art_item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.art_item_name {
  font-size: 18px;
  min-width: 220px;
  color: #979797;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 0;
  padding-right: 26px;
}

.art_item_name .anticon {
  vertical-align: middle;
  line-height: 1;
  fill: var(--dark);
}

.art_item_name .t_text {
  font-family: 'MaisonNeue-Demi';
  display: inline-block;
  line-height: 1.76;
}

.art_item_name.actives {
  font-family: 'MaisonNeue-Demi';
  color: var(--dark);
}

.art_item_name.unactive {
  font-family: 'MaisonNeue-Medium';
  color: #979797;
}

.art_item_name.unactive .anticon {
  display: none;
}

.art_list {
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 120px;
  position: relative;
}

.art_lists {
  width: 100%;
  min-height: 160px;
  position: relative;
  border-left: 1px solid #ebebeb;
}

.art_lists .artistListContainer {
  padding-top: 10px;
  padding-left: 20px;
  padding-bottom: 10px;
}

.art_lists .show {
  display: block;
}

.art_lists .hide {
  display: none;
}

.xsNavDesktop {
  text-align: right;
}

#nav .xsMenu {
  color: var(--white);
}

.desktop-navigation .ant-menu-item {
  border-bottom: 0px !important;
}

.desktop-active #nav {
  color: var(--dark);
}

.desktop-active #nav .xsMenu {
  color: var(--dark);
}

.xsNavDesktop {
  line-height: 100px;
}

.pc_nav_header {
  width: 100%;
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: middle;
  border-bottom: 2px solid transparent;
  text-align: right;
  font-family: 'MaisonNeue-Medium';
  font-size: 21px;
  color: var(--dark);
  line-height: 1.43;
  text-align: right;
}

.pc_nav_header {
  font-feature-settings: 'tnum', 'tnum';
  margin-bottom: 0;
  padding-left: 0;
  color: rgba(0, 0, 0, 0.65);
  line-height: 100px;
  list-style: none;
}

.pc_nav_header .pc_menu_item {
  margin: 0;
  white-space: nowrap;
  cursor: pointer;
  position: relative;
  top: 0px;
  display: inline-block;
  vertical-align: bottom;
  border-bottom: 2px solid transparent;
  margin-left: 3%;
  padding: 0;
}

.pc_nav_header .xsMenu {
  position: relative;
}

.pc_nav_header .xsMenu::after {
  visibility: hidden;
  content: ' ';
  position: absolute;
  width: 100%;
  right: 0;
  bottom: -10px;
  border-bottom: 2px solid #000000;
}

.pc_nav_header .actives::after {
  visibility: visible;
}

.pc_nav_header a:active,
.pc_nav_header a:hover {
  text-decoration: none;
  outline: 0;
  color: var(--dark);
}

.header_items {
  position: absolute;
  top: 99px;
  background: #ffffff;
  border-top: 1px solid #ebebeb;
  line-height: 1;
}

.pc_works_items {
  border: 0;
  width: 256px;
  left: -50px;
  padding: 32px;
}

.pc_works_items::before {
  position: fixed;
  border-top: 1px solid #ebebeb;
  width: 100%;
  content: ' ';
  left: 0;
  top: 99px;
}

.pc_works_items .menu-item-artist {
  margin-bottom: 28px;
}

.pc_works_items .menu-item-artist:last-child {
  margin-bottom: 0;
}

.pc_artists_item {
  position: fixed;
  width: 100%;
  left: 0;
}

@media only screen and (max-width: 1023px) {
  .xsNavDesktop {
    display: none;
  }
}

@media only screen and (min-width: 1024px) {
  .pc_artists_item {
    padding: 0 60px;
  }
}

@media only screen and (max-width: 1279px) {
  .pc_artists_item .menu-item-artist {
    max-width: 33.33%;
    width: 33.33%;
  }
}

@media only screen and (min-width: 1280px) {
  .pc_artists_item {
    padding: 0 140px;
  }
}
