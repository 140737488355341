.smallSliderHeader {
  display: flex;
  justify-content: space-between;
}

.smallSliderHeader .work_title {
  margin-top: 3px;
  width: 60%;
}

.smallSliderButton .bdgBJg.bdgBJg {
  width: 100px;
  height: 32px;
  border-radius: 16px;
  background-color: var(--dark);
}

.smallSliderButton .JhMeT {
  display: none;
}

.smallSlider_name {
  font-family: MaisonNeue-Bold;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: var(--dark);
  text-align: left;
  margin: 4px 2px;
}

.smallSlider_name_artist {
  font-family: MaisonNeue-Bold;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: var(--dark);
  text-align: left;
  margin: 4px 2px;
}

.FeaturedCollection_Container .smallSliderHeader {
  width: 100%;
  margin: 0 auto;
}

.FeaturedCollection_Container .smallSliderWrap {
  padding: 0 16px;
}

.smallSliderWrap .slick-slider button {
  display: none !important;
}

.smallSlider_image {
  height: 200px;
  margin: 0 6px;
  text-align: left;
  overflow: hidden;
}

.smallSlider_image img {
  height: 100%;
  width: auto;
}

/* new */
.horizontalSlide_Wrap {
  height: 350px;
  width: 100%;
  overflow: hidden;
}

.horizontalSlide {
  width: 100%;
  height: 310px;
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  margin-left: 0px;
  -webkit-overflow-scrolling: touch;
}

.horizontalSlide .smallSlider_image {
  height: 200px;
  text-align: left;
  overflow: hidden;
  margin: 0;
}

.smallSlidercard {
  margin-right: 8px;
}

.horizontal_Slide {
  width: 100%;
  height: 310px;
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  margin-left: 0px;
  -webkit-overflow-scrolling: touch;
}

.horizontal_Slide .column_item {
  position: relative;
  margin-right: 24px;
  padding: 0;
  min-width: 200px;
  max-height: 100%;
}

.horizontal_Slide .lazyimg_image {
  height: 310px;
  text-align: left;
  overflow: hidden;
  margin: 0;
}

.horizontal_Slide .lazyimg_image img {
  height: 100%;
}

.exhibition_slide {
  height: 295px;
}

.exhibition_slide .lazyimg_image {
  height: 295px;
  width: 280px;
}

.presses_slide {
  height: auto;
}

.presses_slide .column_item {
  min-width: 395px !important;
}

.presses_slide .lazyimg_image {
  height: auto;
}

.presses_slide .lazyimg_image img {
  width: 100%;
  max-width: 100%;
}

.presses_slide .c_text_div {
  margin-top: 16px;
  margin-bottom: 24px;
}

.horizontal_Wrap {
  height: 350px;
  width: 100%;
  overflow: hidden;
}

.horizontal_header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.horizontal_header .title {
  font-family: "MaisonNeue-Demi";
  font-size: 24px;
  line-height: 1.2;
  text-align: left;
  color: var(--dark);
}

.horizontal_slide {
  width: 100%;
  height: 310px;
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  margin-left: 0px;
  -webkit-overflow-scrolling: touch;
}

.small_card {
  height: 280px;
  margin-right: 8px;
}

.small_image {
  height: 100%;
  margin-right: 8px;
  text-align: left;
  overflow: hidden;
}

.small_image img {
  height: 100%;
  width: auto;
  max-width: 100%;
}

.small_art_name {
  font-family: "MaisonNeue-Medium";
  font-size: 20px;
  margin-top: 16px;
  color: var(--dark);
  text-align: left;
}

@media only screen and (max-width: 744px) {
  .presses_slide .column_item {
    min-width: 295px !important;
  }
}

.category_title {
  width: 100%;
  margin: 42px auto 24px auto;
  padding: 88px 140px 0 140px;
  /* width: 400px;
  height: 80px; */
  font-family: MaisonNeue-Bold;
  font-size: 60px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  text-align: left;
  color: var(--dark);
}

.column_item {
  position: relative;
  overflow: hidden;
}

@media only screen and (max-width: 743px) {
  .category_title {
    padding: 0 16px;
    font-size: 24px;
    line-height: 1.17;
    margin-top: 88px;
    width: 100%;
  }
}

@media only screen and (min-width: 744px) {
  .category_title {
    padding: 88px 40px 0 40px;
  }
}

@media only screen and (min-width: 1024px) {
  .category_title {
    padding: 88px 40px 0 40px;
  }
}

@media only screen and (min-width: 1280px) {
  .category_title {
    padding: 88px 140px 0 140px;
  }
}

.common_page,
.common-page {
  padding-left: 140px;
  padding-right: 140px;
}

.common_title,
.common-title {
  min-width: 200px;
  font-family: MaisonNeue-Demi;
  font-size: 32px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: -0.1px;
  text-align: left;
  color: var(--dark);
  margin-bottom: 32px;
  padding-top: 128px;
}

.header_box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 128px;
  margin-bottom: 32px;
}

.header_box .common_title {
  padding-top: 0;
  margin-bottom: 0;
}

.header_box .filter_bar {
  min-width: 200px;
  text-align: right;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.header_box .filter_name {
  font-family: MaisonNeue-Medium;
  font-size: 18px;
}

.header_box .filter_name .text {
  margin-right: 6px;
}

.names_hook {
  margin-right: 20px;
}

.s_btn_group {
  border: 1px solid #000000;
  padding: 0px 10px;
}

.header_box .filter_name {
  margin-right: 6px;
  cursor: pointer;
  line-height: 1.8;
}

.header_box .ant-btn {
  border-color: transparent !important;
  box-shadow: none;
}

.header_box .ant-btn:hover,
.header_box .ant-btn:focus {
  border-color: transparent !important;
  color: unset !important;
}

.header_box .ant-select-selection {
  border: 0px;
}

.header_box .ant-select-selection {
  font-size: 16px;
  color: var(--dark);
}

.header_box .ant-btn {
  padding: 0;
}

.header_solid {
  display: block;
}

.filter_title {
  font-family: 'MaisonNeue-Demi';
  font-size: 32px;
  margin-bottom: 10px;
}

.filter_title .anticon {
  vertical-align: middle;
  margin-left: 10px;
}

.type_ul {
  display: none;
  font-family: 'MaisonNeue-Demi';
  font-size: 32px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.type_ul li {
  color: #979797;
  margin-bottom: 20px;
}

.type_ul .active {
  color: var(--dark);
}

.type_ul .active::before {
  display: inline-block;
  vertical-align: middle;
  content: ' ';
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: #000000;
  margin-right: 20px;
}

.a_dropdown_link {
  color: var(--dark);
  border: 1px solid #000000;
  cursor: pointer;
  padding: 5px 10px;
}

.a_dropdown_link .text {
  margin-right: 2px;
}

.a_dropdown_link:hover, .a_dropdown_link:focus {
  color: var(--dark);
  border: 1px solid #000000;
  color: unset !important;
}

.a_dropdown_link:active {
  color: var(--dark);
  border: 1px solid #000000;
  color: unset !important;
}

.a_dropdown_link:active span {
  position: relative;
}

/* Artists */
.artists_page {
  overflow: hidden;
}

.artists_page .column_item:hover .card_name {
  display: none;
}

.artists_page .column_item:hover .card_desc {
  display: block;
}

.fileterBarRow .Large_Screen_Frame {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 743px) {
  .common-page {
    padding-left: 24px;
    padding-right: 24px;
  }
  .common_title,
  .common-title {
    text-align: center;
    padding-top: 80px;
    margin-bottom: 0;
  }
  .header_box {
    padding-top: 108px;
    display: block;
  }
  .header_box .common_title {
    width: 100%;
    text-align: center;
  }
  .header_box .filter_bar {
    width: 100%;
    display: block;
    text-align: center;
  }
  .flex_header {
    display: flex !important;
    justify-content: center !important;
    margin-top: 10px;
  }
  .c_page .ImgSwiper .swiper_info::before {
    bottom: -35px;
  }
  .c_page .header_box,
  .c_page .common_wrapper {
    padding-left: 24px;
    padding-right: 24px;
  }
  .c_page .common_wrapper {
    padding-top: 35px;
  }
  .header_box {
    border-bottom: 1px solid #efefef;
    padding-bottom: 20px !important;
  }
}

@media only screen and (min-width: 744px) {
  .common-page {
    padding-left: 40px;
    padding-right: 40px;
  }
  .c_page .header_box,
  .c_page .ImgBanner,
  .c_page .common_wrapper {
    padding-left: 40px;
    padding-right: 40px;
  }
  .c_page .header_box {
    border-bottom: 1px solid #efefef;
    padding-bottom: 20px !important;
  }
  .filter_title {
    display: none;
  }
}

@media only screen and (max-width: 743px) {
  .artist_header .common_title {
    display: none;
  }
  .artist_header .filter_title {
    display: block;
  }
}

@media only screen and (min-width: 1024px) {
  .common-page {
    padding-left: 60px;
    padding-right: 60px;
  }
  .c_page .header_box,
  .c_page .ImgBanner,
  .c_page .common_wrapper {
    padding-left: 60px;
    padding-right: 60px;
  }
  .c_page .header_box {
    border-bottom: none;
    padding-bottom: 0 !important;
  }
  .filter_title {
    display: none;
  }
}

@media only screen and (min-width: 1280px) {
  .common-page {
    padding-left: 140px;
    padding-right: 140px;
  }
  .c_page .header_box,
  .c_page .ImgBanner,
  .c_page .common_wrapper {
    padding-left: 140px;
    padding-right: 140px;
  }
  .c_page .header_box {
    border-bottom: none;
    padding-bottom: 0 !important;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 375px;
}

@font-face {
  font-family: "MaisonNeue-Medium";
  src: url("/fonts/MaisonNeue/MaisonNeue-Medium.ttf");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: "MaisonNeue-Bold";
  src: url("/fonts/MaisonNeue/MaisonNeue-Bold.ttf");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: "MaisonNeue-Light";
  src: url("/fonts/MaisonNeue/MaisonNeue-Light.ttf");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "MaisonNeue-Book";
  src: url("/fonts/MaisonNeue/MaisonNeue-Book.ttf");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "MaisonNeue-Demi";
  src: url("/fonts/MaisonNeue/MaisonNeue-Demi.ttf");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "MaisonNeue-Mono";
  src: url("/fonts/MaisonNeue/MaisonNeue-Mono.ttf");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}
/* @font-face {
  font-family: "RomanaBT";
  src: url("/fonts/romana/RomanaBT-Bold.otf");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: "RomanaBT-Roman";
  src: url("/fonts/romana/romanan.ttf");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
} */

:root {
  --steel-grey: #797c86;
  --cloudy-blue: #bcbec2;
  --pale-blue: #e4e5e7;
  --ice-blue: #f1f2f3;
  --white: #ffffff;
  --dark: #1c1d22;
}
.ant-layout {
  overflow: hidden;
  min-height: 100vh;
}
a {
  color: #1c1d22;
  color: var(--dark);
}
a:active{
  color: #1c1d22;
  color: var(--dark);
}
a:hover{
  color: #1c1d22;
  color: var(--dark);
}
.ant-btn {
  border: none;
}

/******************************************* Global CSS **************************************************************************/
img {
  object-fit: cover;
}

.cc_header {
  padding-bottom: 24px;
  border-bottom: 1px solid #efefef;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.cc_header .title {
  font-family: "MaisonNeue-Demi";
  font-size: 24px;
  line-height: 1;
}

.cc_header .title_link {
  font-family: "MaisonNeue-Medium";
  font-size: 16px;
  line-height: 1.5;
}

.cc_header .title_link a {
  display: inline-block;
}

.cc_header .title_link a:hover, .cc_header .title_link a:active, .cc_header .title_link a:focus {
  color: var(--dark);
}

.cc_header .title_link .anticon {
  position: relative;
  top: -3px;
}

.cc_header .title_link span {
  font-family: "MaisonNeue-Medium";
  margin-right: 8px;
}

@media only screen and (max-width: 744px) {
  .cc_header {
    border: 0;
    padding: 0;
    display: block;
    text-align: left;
  }
  .mini_header .title {
    margin-bottom: 24px;
  }
  .mini_header .title_link {
    margin-bottom: 32px;
  }
}

.lazyimg img {
  margin-top: 25px;
  font-size: 21px;
  text-align: center;
  animation: fadein 2s;
  -moz-animation: fadein 2s; /* Firefox */
  -webkit-animation: fadein 2s; /* Safari and Chrome */
  -o-animation: fadein 2s; /* Opera */
}
@keyframes fadein {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}

.mondrian_wrapper .card_name {
  font-family: MaisonNeue-Bold;
  font-size: 21px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: var(--dark);
}

/* work_artist_names 非静态生成的类名 */
.mondrian_wrapper .author_names,
.mondrian_wrapper .years {
  font-family: MaisonNeue;
  font-size: 14px;
  color: var(--dark);
}

.common_wrapper {
  margin: 24px 0 81px 0;
}

.common_column {
  position: unset !important;
  flex-direction: column;
  float: left;
  box-sizing: border-box;
}

.img_banner {
  margin-top: 24px;
  margin-bottom: 40px;
}

.wpl {
  padding-left: 16px;
}

.wprl {
  padding-left: 16px;
  padding-right: 16px;
}

.wpr {
  padding-right: 16px;
}

.wp4_r {
  padding-right: 24px;
}

.wp4_c1 {
  padding-left: 8px;
  padding-right: 16px;
}

.wp4_c2 {
  padding-left: 16px;
  padding-right: 8px;
}

.wp4_l {
  padding-left: 24px;
}

.wp3_r {
  padding-right: 21px;
}

.wp3_c1 {
  padding-left: 12px;
  padding-right: 11px;
}

.wp3_l {
  padding-left: 21px;
}

.wt50 {
  width: 50% !important;
}

.wt100 {
  width: 100% !important;
}

.popup_box {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.64)), to(rgba(0, 0, 0, 0.64)));
  background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0.64));
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0.64));
  -webkit-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
}

.popup_box .mask {
  line-height: 1;
}

.popup_box .popup_body {
  font-family: MaisonNeue-Medium;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  text-align: left;
  padding: 0 40px;
}

.popup_box .author {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #fff !important;
  margin-bottom: 8px;
  opacity: 0.9;
}

.popup_box .title {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 1.2;
  color: #fff !important;
}

.collections_wrapper .column_item {
  min-height: 100px;
  margin-bottom: 32px !important;
}

.collections_wrapper .lazyimg {
  position: relative;
  overflow: hidden;
}

.collections_wrapper .lazyimg img {
  margin-top: 0;
  max-width: 100%;
}

.popup_group {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 10px;
  opacity: 1;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(60, 60, 60, 0.8)));
  background: -webkit-linear-gradient(top, transparent 0%, rgba(60, 60, 60, 0.8) 100%);
  background: linear-gradient(180deg, transparent 0%, rgba(60, 60, 60, 0.8) 100%);
}

.popup_group .t_body {
  text-align: left;
  color: #fff;
}

.popup_group .t_author {
  font-size: 12px;
  line-height: 1.2;
  font-family: 'MaisonNeue-Medium';
  padding-bottom: 8px;
}

.popup_group .t_title {
  font-family: 'MaisonNeue-Medium';
  font-size: 19px;
  line-height: 1.4;
  padding-bottom: 8px;
}

.popup_group .t_icon {
  visibility: visible;
}

.modelsEx {
  padding: 16px;
}

.modelsEx .t_title {
  padding-bottom: 0;
}

.artists_wrapper {
  margin: 40px 0 81px 0;
}

.artists_wrapper .column_item {
  margin-bottom: 25px;
}

.artists_wrapper .lazyimg {
  position: relative;
  overflow: hidden;
}

.artists_wrapper .lazyimg img {
  margin-top: 0;
  height: 100%;
}

.artists_wrapper .desc_group {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 10px;
  opacity: 1;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(60, 60, 60, 0.8)));
  background: -webkit-linear-gradient(top, transparent 0%, rgba(60, 60, 60, 0.8) 100%);
  background: linear-gradient(180deg, transparent 0%, rgba(60, 60, 60, 0.8) 100%);
}

.artists_wrapper .card_title {
  font-family: MaisonNeue-Demi;
  font-size: 20px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: -0.1px;
  text-align: left;
  color: #fff;
}

.artists_wrapper .card_details {
  text-align: left;
  color: #fff;
  display: none;
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.artists_wrapper .card_details .text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.artists_wrapper .column_item img {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  height: 100%;
  width: 100%;
}

.artists_wrapper .column_item:hover .desc_group {
  opacity: 1;
}

.artists_wrapper .column_item:hover .card_title {
  display: none;
}

.artists_wrapper .column_item:hover .card_details {
  display: block;
  opacity: 1;
}

.artists_wrapper .column_item:hover img {
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
}

.exhibitions_wrapper .column_item {
  min-height: 100px;
  margin-bottom: 32px !important;
}

.exhibitions_wrapper .lazyimg {
  position: relative;
  overflow: hidden;
}

.exhibitions_wrapper .lazyimg img {
  margin-top: 0;
  max-width: 100%;
}

.presses_wrapper .ant-row-flex {
  margin-bottom: 32px;
}

.presses_wrapper .lazyimg {
  margin-bottom: 16px;
  position: relative;
  overflow: hidden;
}

.presses_wrapper .lazyimg img {
  margin-top: 0px;
  width: 100%;
}

.presses_wrapper .card_name {
  font-family: MaisonNeue-Demi;
  color: #979797;
  font-size: 16px;
  line-height: 1.2;
}

.worksItems_wrapper .lazyimg {
  position: relative;
  overflow: hidden;
}

.worksItems_wrapper .lazyimg img {
  margin-top: 0px;
  width: 100%;
}

.worksItems_wrapper .column_item:hover .popup_box {
  opacity: 1;
}

.worksItems_wrapper .wpr {
  padding-right: 8px;
}

.worksItems_wrapper .wprl {
  padding-left: 8px;
  padding-right: 8px;
}

.worksItems_wrapper .wpl {
  padding-left: 8px;
}

.Items_Styled_Masonry {
  position: unset !important;
  height: unset !important;
  display: flex;
  flex-flow: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.Items_Styled_Masonry .lazyimg img {
  margin-top: 0;
}

.Items_Styled_Masonry .column_item {
  margin-bottom: 25px;
}

.Items_Styled_Masonry .column_item:hover .popup_box {
  opacity: 1;
}

.c_Masonry {
  position: unset;
  height: unset !important;
  display: flex;
  flex-flow: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.c_text_div {
  margin-top: 5px;
  margin-bottom: 15px;
  text-align: left;
}

.c_card_name {
  font-family: MaisonNeue-Demi;
  color: #979797;
  font-size: 16px;
  line-height: 1.2;
}

@media screen and (max-width: 743px) {
  .common_column {
    width: 100%;
  }
  .common_column .column_item {
    margin-bottom: 40px;
  }
  .common_column:last-child {
    margin-right: 0px;
  }
}

@media screen and (min-width: 744px) {
  .common_column {
    width: 50%;
  }
  .common_column .column_item {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1024px) {
  .common_column {
    width: 33.3333%;
  }
}

@media screen and (min-width: 1280px) {
  .common_column {
    width: 25%;
  }
}

@media screen and (min-width: 1440px) {
  .collections_wrapper .lazyimg {
    width: 100%;
    height: auto;
  }
  .collections_wrapper .lazyimg img {
    top: unset;
    left: unset;
    -webkit-transform: unset;
            transform: unset;
  }
}

.v__mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.about_fixed {
  height: 760px;
  width: 100%;
}

.aboutHeader {
  background-image: url("/images/aboutus2/image1.jpg");
  background-size: cover;
  background-position: center;
  height: 740px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.aboutHeader_board {
  bottom: 160px;
  width: 100%;
  background: transparent;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

.aboutHeader_title {
  font-family: "MaisonNeue-Demi";
  font-size: 60px;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.aboutHeader_content {
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  margin-top: 40px;
}

.chinese {
  margin-top: 32px;
}

.scoll_body {
  position: relative;
  background: #fff;
  width: 100%;
}

.scoll_body .c_page {
  position: relative;
  overflow: hidden;
}

.v_img {
  width: 100%;
  height: 740px;
  max-height: 100%;
}

.v__image2 {
  position: relative;
  height: 740px;
}

.v__image3 {
  position: relative;
  height: 720px;
  margin-bottom: 120px;
}

.v__image3 img {
  height: 100%;
}

.about_middle_des {
  margin: 136px auto;
  display: flex;
  flex-direction: column;
}

.about_description {
  width: 100%;
  margin-bottom: 100px;
}

.about_image {
  position: relative;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}

.image_before_des {
  width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.v_image_before_des {
  width: 100%;
  max-height: 100%;
  object-fit: cover;
  height: 660px;
}

.about_image_des {
  width: 100%;
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  text-align: left;
  color: var(--dark);
  padding-bottom: 48px;
}

.about_address_wrap {
  width: 90%;
  border-top: 1px solid var(--pale-blue);
  border-bottom: 1px solid var(--pale-blue);
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  padding: 80px 0;
}

.about_address {
  text-align: left;
  font-family: MaisonNeue-Book;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--dark);
}

.about_address .about_address_title {
  font-family: MaisonNeue-Book;
  font-size: 21px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--dark);
}

.aboutusBtn {
  margin: 139px auto;
  width: 464px;
  height: 69px;
  border-radius: 40px;
  border: solid 1px var(--dark);
  background-color: var(--white);
  font-family: MaisonNeue-Demi;
  font-size: 21px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  text-align: center;
  letter-spacing: 2px;
  color: var(--dark);
  padding: 25px 0;
}

.aboutusWrap .sc-eHgmQL {
  margin-top: 136px;
}

.about_tips {
  font-family: "MaisonNeue-Medium";
  font-size: 12px;
  text-align: left;
  position: absolute;
  bottom: 0;
  left: 0px;
  background: rgba(0, 0, 0, 0.64);
  color: #fff;
  padding: 8px;
}

.about_tips_mobile {
  font-family: "MaisonNeue-Medium";
  font-size: 12px;
  text-align: left;
  display: none;
  background: #fff;
  color: #979797;
  opacity: 0.88;
  margin-top: 12px;
  padding: 0;
}

/******************************************* Media Query **************************************************************************/
/**********************************************************************************************************************************/
@media only screen and (min-width: 744px) {
  .about_middle_des {
    padding: 0 40px;
    margin: 64px auto;
  }
  .about_image_des {
    padding-bottom: 40px;
  }
  .about_description {
    margin-bottom: 48px;
  }
  .aboutHeader_board {
    bottom: 160px;
    padding: 0 40px;
  }
  .aboutHeader_content {
    margin-top: 24px;
  }
  .aboutHeader_content.chinese {
    margin-top: 16px;
  }
  .aboutusWrap .cc_header {
    margin: 0 40px;
  }
}

@media only screen and (max-width: 1023px) {
  .aboutHeader_title {
    font-size: 40px;
  }
  .about_middle_des {
    padding: 0 40px;
    margin: 64px auto;
  }
  .aboutHeader_board {
    bottom: 86px;
    padding-left: 40px;
    padding-right: 40px;
  }
  .aboutHeader {
    height: 480px;
  }
  .about_fixed {
    height: 500px;
  }
  .v__image2 {
    height: 480px;
  }
  .v_image_before_des {
    height: 400px;
  }
  .v__image3 {
    height: 480px;
    margin-bottom: 64px;
  }
}

@media only screen and (max-width: 743px) {
  .aboutHeader {
    height: 600px;
  }
  .aboutHeader_board {
    width: 100%;
    bottom: 40px;
    padding-left: 24px;
    padding-right: 24px;
  }
  .about_fixed {
    height: 620px;
  }
  .about_tips {
    display: none;
  }
  .about_tips_mobile {
    display: block;
  }
  .about_middle_des {
    margin-top: 80px;
  }
  .about_image_des {
    padding-bottom: 32px;
  }
  .about_middle_des {
    padding: 0 24px;
    margin: 40px auto;
  }
  .about_image_des-haschild {
    padding-bottom: 24px;
  }
  .aboutHeader_title {
    font-size: 40px;
    line-height: 1.11;
  }
  .aboutHeader_content {
    font-size: 18px;
    line-height: 1.43;
  }
  .about_description {
    width: 100%;
    margin-bottom: 40px;
  }
  .v_image_before_des {
    height: 240px;
  }
  .about_image_des {
    width: 100%;
  }
  .aboutusWrap iframe {
    height: 192px;
  }
  .about_address_wrap {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  .about_address {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
  }
  .about_address_title {
    margin-bottom: 16px;
  }
  .aboutusBtn {
    width: 318px;
    height: 48px;
    font-size: 14px;
    margin: 64px auto;
    padding-top: 17px;
  }
  .aboutusWrap .cc_header {
    margin: 0 24px;
  }
  .scoll_body .common_wrapper {
    margin: 0;
    padding-top: 0;
  }
  .v__image2 {
    height: auto;
  }
  .v__image2 .about_tips_mobile {
    padding-left: 24px;
    padding-right: 24px;
  }
  .v__image2 .v_img {
    height: 355px;
  }
  .v__image3 {
    height: 207px;
  }
  .v__image3 {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media only screen and (min-width: 1024px) {
  .aboutHeader_board {
    padding-left: 60px;
    padding-right: 60px;
  }
  .aboutusWrap .cc_header {
    margin: 0 60px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
  .aboutHeader_title {
    font-size: 48px;
  }
  .about_middle_des {
    padding: 0 60px;
  }
  .aboutHeader {
    height: 560px;
  }
  .about_fixed {
    height: 580px;
  }
  .v__image2 {
    height: 560px;
  }
  .v_image_before_des {
    height: 560px;
  }
  .about_image_des {
    padding-bottom: 40px;
  }
  .v__image3 {
    height: 560px;
    margin-bottom: 100px;
  }
}

@media only screen and (min-width: 1280px) {
  .aboutHeader_board {
    bottom: 160px;
    padding: 0 140px;
  }
  .aboutHeader_content {
    margin-top: 40px;
  }
  .about_middle_des {
    padding: 0 140px;
    margin: 120px auto;
  }
  .about_description {
    margin-bottom: 100px;
  }
  .v_image_before_des {
    height: 660px;
  }
  .about_address_wrap {
    width: 94%;
  }
  .about_image_des-haschild {
    padding-bottom: 24px;
  }
  .aboutHeader_content.chinese {
    margin-top: 32px;
  }
  .aboutusWrap .cc_header {
    margin: 0 140px;
  }
}

.m_filter {
  position: fixed;
  z-index: 6;
  top: 89px;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 24px;
  box-sizing: border-box;
}

.m_filter .f_top {
  padding-top: 8px;
  padding-bottom: 34px;
  text-align: left;
}

.m_filter .f_top .f_title {
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  vertical-align: bottom;
}

.m_filter .f_close {
  float: right;
  cursor: pointer;
}

.m_filter .f_body {
  height: calc(100% - 88px);
  overflow-y: auto;
}

.m_filter .f_item {
  position: relative;
  margin-bottom: 26px;
  text-align: left;
}

.m_filter .f_btn_group {
  margin-top: 40px;
}

.m_filter .t__text {
  font-family: "MaisonNeue-Medium";
  font-size: 14px;
  width: 100%;
  height: 44px;
  line-height: 44px;
  text-align: center;
  cursor: pointer;
}

.m_filter .reset {
  border: 1px solid #000;
  color: var(--dark);
  margin-bottom: 24px;
}

.m_filter .confirm {
  color: #fff;
  background: #000;
}

.m_filter_list {
  text-align: left;
}

.m_filter_item {
  margin-bottom: 26px;
}

@media only screen and (max-width: 743px) {
  .m_filter {
    height: calc(100% - 88px);
  }
}

@media only screen and (min-width: 744px) and (max-width: 1023px) {
  .m_filter {
    height: calc(100% - 88px);
  }
  .m_filter_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }
  .m_filter_item {
    min-width: 33.33%;
  }
}

/**
  Checkbox Panel 样式

  由于ant-dropmenu 是独立于当前页面node的，
  所以无法添加当前页面的className
*/
.ant-dropdown.ant-dropdown-placement-bottomRight {
  background-color: unset !important;
  padding-top: 0 !important;
}

@media only screen and (max-width: 767px) {

  .sc-EHOje .ant-btn:focus>span,
  .sc-EHOje .ant-btn:active>span {
    position: relative;
  }
}

.filter_name {
  font-family: MaisonNeue-Book;
  font-size: 16px;
  line-height: 1.5;
}

.Large_Screen_Frame {
  text-align: left;
}

.Small_Screen_Frame {
  visibility: visible;
}

.Small_Screen_Frame .small_button {
  margin-top: 10px;
  border: 1px solid var(--dark) !important;
  font-family: MaisonNeue-Demi;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: 2px;
  color: var(--dark);
  height: 40px;
  width: 107px;
}

.Small_Screen_Frame .small_button:hover, .Small_Screen_Frame .small_button:focus {
  color: var(--dark);
  border: 1px solid #000000;
  color: unset !important;
}

.Small_Screen_Frame .small_button:active {
  color: var(--dark);
  border: 1px solid #000000;
  color: unset !important;
}

.Small_Screen_Frame .small_button:active span {
  position: relative;
}

.Styled_Dropdown_Button .ant-btn {
  border-color: transparent !important;
  box-shadow: none;
}

.Styled_Dropdown_Button .ant-btn:hover,
.Styled_Dropdown_Button .ant-btn:focus {
  border-color: transparent !important;
  color: unset !important;
}

.Styled_Dropdown_Button .ant-select-selection {
  border: 0px;
}

.Styled_Dropdown_Button .ant-select-selection {
  font-size: 16px;
  color: var(--dark);
}

.s_textFilter_list {
  width: 360px;
}

.s_textFilter {
  display: inline-block;
  position: relative;
  height: 24px;
  margin-right: 20px;
  font-family: MaisonNeue-Book;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--dark);
  cursor: pointer;
}

.s_textFilter.active::after {
  content: " ";
  position: absolute;
  height: 2px;
  width: 100%;
  margin: auto;
  bottom: -9px;
  left: 0;
  right: 0;
  display: block;
  background: #000;
}

.s_textFilter .filter_select {
  float: right;
  border: 1px solid #000;
}

.Styled_Menu {
  padding: 24px;
  width: 100%;
  overflow: hidden;
  background-color: white;
}

.S_overlay {
  width: 780px;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}

.S_overlay.ant-dropdown {
  width: 780px;
}

@media only screen and (min-width: 1px) {
  .Small_Screen_Frame {
    display: none;
  }
}

@media only screen and (max-width: 743px) {
  .Large_Screen_Frame {
    display: none !important;
  }
  .Small_Screen_Frame {
    display: block;
  }
  .S_overlay {
    width: 100%;
  }
}

@media screen and (min-width: 744px) {
  .S_overlay {
    width: 288px;
  }
}

@media only screen and (min-width: 768px) {
  .Small_Screen_Frame {
    display: none;
  }
}

@media only screen and (min-width: 1024px) {
  .S_overlay {
    width: 528px;
  }
}

.ScrollToTopPc {
  display: none;
  position: fixed;
  right: 10px;
  z-index: 100;
  top: 500px;
  cursor: pointer;
}

.f_checked .ant-checkbox-inner {
  border-radius: 0;
  border: 1px solid #000000;
}

.f_checked .ant-checkbox-checked::after {
  border: 1px solid #000000;
  border-radius: 0;
}

.f_checked .ant-checkbox-checked .ant-checkbox-inner {
  border-color: #000000;
  background-color: #000000;
}

.f_button_footer {
  text-align: right;
  padding-top: 30px;
}

.f_button_footer .b_btn {
  box-sizing: border-box;
  border: 1px solid #000000;
  font-family: 'MaisonNeue-Medium';
  font-size: 14px;
  display: inline-block;
  width: 78px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10+ and Edge */
  -moz-user-select: none;
       user-select: none;
  /* Standard syntax */
}

.f_button_footer .b_reset {
  margin-right: 8px;
}

.f_button_footer .b_confirm {
  color: #fff;
  background: #000000;
}

.f_button_content {
  display: none;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #000000;
  position: absolute;
  z-index: 5;
  top: 44px;
  right: 0;
  padding: 24px;
}

.f_button_content .f_filter_item {
  overflow: hidden;
}

.f_button_header {
  z-index: 6;
  position: relative;
  background: #fff;
  border: 1px solid #000000;
  padding: 10px 15px;
  text-align: center;
  cursor: pointer;
}

.f_button_header .f__text {
  font-family: 'MaisonNeue-Medium';
  font-size: 18px;
  margin-right: 8px;
  display: inline-block;
  vertical-align: text-top;
  line-height: 1;
}

.f_button_header .anticon {
  vertical-align: text-top;
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.f_button_header.actives, .f_button_header:hover {
  border-bottom-color: transparent;
}

.f_button_header.actives .anticon, .f_button_header:hover .anticon {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.f_button {
  position: relative;
  box-sizing: border-box;
}

.f_button_content .c_StyledCol_ver {
  margin-bottom: 18px;
}

.f_button_content .c_StyledRow_ver {
  display: flex;
  align-items: baseline;
}

.f_button_content .c_StyledText_span_ver {
  padding-left: 8px;
  display: block;
  text-align: left;
  font-family: 'MaisonNeue-Book';
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: var(--dark);
}

.mobile_filter_btn {
  position: relative;
  background: #fff;
  border: 1px solid #000000;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  line-height: 44px;
  padding: 0 15px;
}

.mobile_filter_btn .f__text {
  font-family: 'MaisonNeue-Medium';
  font-size: 18px;
  margin-right: 8px;
  display: inline-block;
  vertical-align: text-top;
  line-height: 1;
}

.mobile_filter_btn .anticon {
  vertical-align: middle;
  margin-left: 10px;
}

.artists_menu {
  width: 640px;
}

.collections_menu {
  width: 640px;
  visibility: visible;
}

.exhibitions_menu {
  width: 210px;
}

.exhibitions_menu .f_button_footer {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.worksItems_menu {
  width: 640px;
}

@media only screen and (min-width: 744px) and (max-width: 1023px) {
  .worksItems_menu {
    width: 500px;
  }
}

.ImgBanner {
  margin-top: 24px;
  margin-bottom: 40px;
}

.ImgBanner .banner_info {
  position: relative;
  margin-bottom: 40px;
}

.ImgBanner .thumb_box {
  height: 590px;
}

.ImgBanner .thumb_box img {
  width: 100%;
  height: 100%;
}

.ImgBanner .text_modal {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 15px;
  text-align: left;
  color: #fff;
  opacity: 1;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(60, 60, 60, 0.8)));
  background: -webkit-linear-gradient(top, transparent 0%, rgba(60, 60, 60, 0.8) 100%);
  background: linear-gradient(180deg, transparent 0%, rgba(60, 60, 60, 0.8) 100%);
}

.ImgBanner .subtitle {
  font-family: 'MaisonNeue-Medium';
  font-size: 12px;
  margin-bottom: 8px;
}

.ImgBanner .title {
  font-family: 'MaisonNeue-Demi';
  font-size: 20px;
  color: #fff;
  margin-bottom: 15px;
}

.ImgBanner .desc {
  font-family: 'MaisonNeue-Book';
  margin-bottom: 15px;
  font-size: 16px;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.mobile_article_arrow {
  font-family: 'MaisonNeue-Medium';
  display: none;
}

.mobile_article_arrow .anticon {
  vertical-align: text-top;
}

@media only screen and (max-width: 743px) {
  .ImgBanner {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .ImgBanner .banner_info:last-child {
    margin-bottom: 15px;
  }
  .ImgBanner .thumb_box {
    height: 234px;
  }
  .ImgBanner .text_modal {
    position: relative;
    background: #fff;
    color: var(--dark);
    padding-top: 8px;
    padding-bottom: 20px;
    padding-left: 24px;
    padding-right: 24px;
  }
  .ImgBanner .subtitle {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .ImgBanner .title {
    color: var(--dark);
    font-size: 32px;
  }
  .ImgBanner .desc {
    color: #979797;
    -webkit-line-clamp: 5 !important;
  }
  .ImgBanner .pc_article_arrow {
    display: none;
  }
  .ImgBanner .mobile_article_text {
    margin-right: 8px;
    font-size: 16px;
  }
  .ImgBanner .mobile_article_arrow {
    display: block;
  }
}

@media only screen and (min-width: 744px) {
  .ImgBanner .banner_info:last-child {
    margin-bottom: 15px;
  }
  .ImgBanner .thumb_box {
    height: 426px;
  }
}

@media only screen and (min-width: 1024px) {
  .ImgBanner .banner_info:last-child {
    margin-bottom: 15px;
  }
  .ImgBanner .thumb_box {
    height: 590px;
  }
}

@media only screen and (min-width: 1280px) {
  .ImgBanner .banner_info:last-child {
    margin-bottom: 15px;
  }
}

.m_filter {
  position: fixed;
  z-index: 6;
  top: 89px;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 24px;
  box-sizing: border-box;
}

.m_filter .f_top {
  padding-top: 8px;
  padding-bottom: 34px;
  text-align: left;
}

.m_filter .f_top .f_title {
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  vertical-align: bottom;
}

.m_filter .f_close {
  float: right;
  cursor: pointer;
}

.m_filter .f_body {
  height: calc(100% - 88px);
  overflow-y: auto;
}

.m_filter .f_item {
  position: relative;
  margin-bottom: 26px;
  text-align: left;
}

.m_filter .f_btn_group {
  margin-top: 40px;
}

.m_filter .t__text {
  font-family: "MaisonNeue-Medium";
  font-size: 14px;
  width: 100%;
  height: 44px;
  line-height: 44px;
  text-align: center;
  cursor: pointer;
}

.m_filter .reset {
  border: 1px solid #000;
  color: var(--dark);
  margin-bottom: 24px;
}

.m_filter .confirm {
  color: #fff;
  background: #000;
}

.m_filter_list {
  text-align: left;
}

.m_filter_item {
  margin-bottom: 26px;
}

@media only screen and (max-width: 743px) {
  .m_filter {
    height: calc(100% - 88px);
  }
}

@media only screen and (min-width: 744px) and (max-width: 1023px) {
  .m_filter {
    height: calc(100% - 88px);
  }
  .m_filter_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }
  .m_filter_item {
    min-width: 33.33%;
  }
}

.ImgSwiper {
  text-align: left;
}

.ImgSwiper .mb12 {
  margin-bottom: 12px;
}

.ImgSwiper .mb20 {
  margin-bottom: 20px;
}

.ImgSwiper .swiper_info {
  position: relative;
  margin-bottom: 80px;
}

.ImgSwiper .swiper_info::before {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: -40px;
  height: 1px;
  background: #efefef;
}

.ImgSwiper .subtitle {
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
}

.ImgSwiper .title {
  font-family: "MaisonNeue-Demi";
  font-size: 40px;
  font-weight: 600;
}

.ImgSwiper .time {
  font-size: 18px;
}

.ImgSwiper .time .icon {
  margin-right: 10px;
  vertical-align: middle;
}

.ImgSwiper .address {
  font-size: 18px;
}

.ImgSwiper .address .icon {
  margin-right: 10px;
  vertical-align: middle;
}

.ImgSwiper .desc {
  font-family: "MaisonNeue-Medium";
  font-size: 16px;
  color: #979797;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ImgSwiper .more {
  font-size: 16px;
}

.ImgSwiper .more .icon {
  margin-left: 8px;
}

.ImgSwiper a:hover,
.ImgSwiper a:active {
  color: var(--dark);
}

.swiper_slide_info img {
  height: 234px;
}

.swiper_slide_info .slick-prev,
.swiper_slide_info .slick-next {
  width: 45px;
  height: 65px;
  background: rgba(255, 255, 255, 0.64);
  z-index: 5;
}

.swiper_slide_info .slick-prev {
  left: 0px;
}

.swiper_slide_info .slick-next {
  right: 0px;
}

.swiper_slide_info .slick-dots {
  bottom: -25px;
}

@media only screen and (max-width: 743px) {
  .ImgSwiper {
    margin: 24px 24px 0 24px !important;
  }
  .ImgSwiper .swiper_info:last-child {
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 744px) {
  .swiper_slide_info img {
    height: 426px;
  }
  .ImgSwiper {
    margin: 24px 40px 0 40px !important;
  }
  .ImgSwiper .swiper_info:last-child {
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 1024px) {
  .swiper_slide_info img {
    height: 426px;
  }
  .ImgSwiper {
    margin: 24px 60px 40px 60px !important;
  }
  .ImgSwiper .swiper_info:last-child {
    margin-bottom: 80px;
  }
}

@media only screen and (min-width: 1280px) {
  .swiper_slide_info img {
    height: 590px;
  }
  .ImgSwiper {
    margin: 24px 140px 40px 140px !important;
  }
  .ImgSwiper .swiper_info:last-child {
    margin-bottom: 80px;
  }
}

.both {
  clear: both;
}

.home_nav {
  width: 100%;
  display: flex;
  position: relative;
}

.nav_btn {
  margin: 0;
  padding: 0;
}

.nav_btn li {
  font-family: MaisonNeue-Demi;
  line-height: 1;
  list-style: none;
  font-size: 40px;
  text-align: left;
  padding-bottom: 32px;
  color: #979797;
  cursor: pointer;
}

.nav_btn i {
  font-style: unset;
  color: #979797;
}

.nav_btn li.active {
  color: var(--dark);
}

.nav_btn li.active i {
  color: var(--dark);
}

.left_nav {
  position: relative;
  width: 493px;
  height: 100%;
  background: #fff;
}

.left_nav::after {
  content: '';
  position: absolute;
  width: 1px;
  height: 80vh;
  background: #ebebeb;
  right: 0;
  top: 80px;
}

.left_nav .nav_group {
  padding-top: 120px;
  max-width: 364px;
  width: 100%;
  min-height: 340px;
}

.left_nav .nav_text {
  font-family: MaisonNeue-Medium;
  font-size: 18px;
  line-height: 1.6;
  text-align: left;
  padding-bottom: 40px;
}

#sectionNavs2 {
  display: none;
}

#Collection .left_nav {
  z-index: 6;
}

#Works .left_nav::after,
#Artists .left_nav::after {
  visibility: hidden;
}

.Artists-News .left_nav::after {
  visibility: visible !important;
}

.right_content {
  flex: 1 1;
  width: 62%;
  padding-left: 64px;
  padding-right: 0;
}

.right_content .more_group {
  font-family: 'MaisonNeue-Medium';
  font-size: 20px;
  clear: both;
  text-align: right;
}

.right_content .more_group span {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
}

.right_content .more_group svg {
  margin-left: 8px;
}

.collocation-link {
  display: block;
  width: 100%;
  margin-bottom: 40px;
  overflow: hidden;
}

.content-item {
  display: flex;
  justify-content: start;
}

.collection_group {
  position: relative;
}

.collection_group .collection_group_image {
  height: 295px;
  text-align: left;
  overflow: hidden;
  margin: 0;
}

.collection_group .collection_group_image img {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  height: 100%;
  width: 100%;
}

.collection_group .collection_name_group {
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.collection_group:hover img {
  -webkit-transform: scale(1.1, 1.1);
          transform: scale(1.1, 1.1);
}

.collection_group:hover .collection_name_group {
  opacity: 1;
}

.collection_name_group {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 101px;
  right: 0;
  padding: 15px;
  background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(60, 60, 60, 0.8)));
  background: -webkit-linear-gradient(top, transparent 0%, rgba(60, 60, 60, 0.8) 100%);
  background: linear-gradient(180deg, transparent 0%, rgba(60, 60, 60, 0.8) 100%);
}

.collection_name_group .pub_name {
  text-align: left;
}

.collection_name_group .name1 {
  font-family: 'MaisonNeue-Book';
  font-style: normal;
  font-size: 12px;
  line-height: 12px;
  color: #ffffff;
  margin-bottom: 8px;
}

.collection_name_group .name2 {
  font-family: 'MaisonNeue-Book';
  font-style: normal;
  font-size: 20px;
  line-height: 20px;
  color: #ffffff;
  margin-bottom: 10px;
}

.collection_name_group .name3 {
  font-size: 20px;
}

.artists-thumb-image {
  width: 100%;
  height: 100%;
  background-image: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
}

.artists_centent {
  position: relative;
  overflow: hidden;
  width: 667px;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.artists_centent .artists_group {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  position: relative;
  width: 125px;
  height: 667px;
}

.artists_centent .collection_name_group {
  height: 85px;
}

.artists_centent .artists_group_image {
  width: 100%;
}

.artists_centent .artists_group_image img {
  height: 667px;
  width: 100%;
}

.artists_centent .artists_name_group {
  width: 100%;
  display: none;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.artists-link {
  display: block;
  margin-bottom: 40px;
  overflow: hidden;
  width: auto;
  height: auto;
  margin-right: 10px;
}

.artists-link:nth-child(2) {
  margin-top: -60px;
}

.artists-link:nth-child(4) {
  margin-top: 30px;
}

.artists-link:nth-child(5) {
  margin-top: 10px;
}

.artists-link:last-child {
  margin-right: 0;
}

.artists-link.active {
  min-width: 468px;
}

.artists-link.active .artists_group {
  min-width: 468px;
}

.artists-link.active .artists_name_group {
  display: block;
  opacity: 1;
}

.artists-link.unactive {
  width: 38px;
}

.artists-link:hover .artists_group {
  max-width: 100%;
  width: 600px;
}

.artists-link:hover .artists_name_group {
  display: block;
  opacity: 1;
}

.smallSliderHeader {
  display: flex;
  justify-content: space-between;
}

.smallSliderHeader .work_title {
  margin-top: 3px;
  width: 60%;
}

.smallSliderButton .bdgBJg.bdgBJg {
  width: 100px;
  height: 32px;
  border-radius: 16px;
  background-color: var(--dark);
}

.smallSliderButton .JhMeT {
  display: none;
}

.smallSlider_name {
  font-family: MaisonNeue-Bold;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: var(--dark);
  text-align: left;
  margin: 4px 2px;
}

.smallSlider_name_artist {
  font-family: MaisonNeue-Bold;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: var(--dark);
  text-align: left;
  margin: 4px 2px;
}

.FeaturedCollection_Container .smallSliderHeader {
  width: 100%;
  margin: 0 auto;
}

.FeaturedCollection_Container .smallSliderWrap {
  padding: 0 16px;
}

.smallSliderWrap .slick-slider button {
  display: none !important;
}

.smallSlider_image {
  height: 200px;
  margin: 0 6px;
  text-align: left;
  overflow: hidden;
}

.smallSlider_image img {
  height: 100%;
  width: auto;
}

/* new */
.horizontalSlide_Wrap {
  height: 350px;
  width: 100%;
  overflow: hidden;
}

.horizontalSlide {
  width: 100%;
  height: 310px;
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  margin-left: 0px;
  -webkit-overflow-scrolling: touch;
}

.horizontalSlide .smallSlider_image {
  height: 200px;
  text-align: left;
  overflow: hidden;
  margin: 0;
}

.smallSlidercard {
  margin-right: 8px;
}

.horizontal_Slide {
  width: 100%;
  height: 310px;
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  margin-left: 0px;
  -webkit-overflow-scrolling: touch;
}

.horizontal_Slide .column_item {
  position: relative;
  margin-right: 24px;
  padding: 0;
  min-width: 200px;
  max-height: 100%;
}

.horizontal_Slide .lazyimg_image {
  height: 310px;
  text-align: left;
  overflow: hidden;
  margin: 0;
}

.horizontal_Slide .lazyimg_image img {
  height: 100%;
}

.exhibition_slide {
  height: 295px;
}

.exhibition_slide .lazyimg_image {
  height: 295px;
  width: 280px;
}

.presses_slide {
  height: auto;
}

.presses_slide .column_item {
  min-width: 395px !important;
}

.presses_slide .lazyimg_image {
  height: auto;
}

.presses_slide .lazyimg_image img {
  width: 100%;
  max-width: 100%;
}

.presses_slide .c_text_div {
  margin-top: 16px;
  margin-bottom: 24px;
}

@media only screen and (max-width: 744px) {
  .presses_slide .column_item {
    min-width: 295px !important;
  }
}

@charset "UTF-8";
/* 公共样式 */
.public_v2 {
  position: relative;
  padding-bottom: 20px;
  height: auto;
  overflow: hidden;
}

.v2Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}

.v2Title {
  font-family: "MaisonNeue-Demi";
  font-size: 24px;
  color: var(--dark);
  text-align: left;
}

.v2More {
  font-family: "MaisonNeue-Medium";
  font-size: 16px;
  margin-bottom: 0;
  font-weight: normal;
}

.v2More .icon {
  padding-left: 8px;
  position: relative;
  top: -3px;
}

.v2More a:active, .v2More a:hover {
  color: var(--dark);
}

.v2Slide {
  width: 100%;
  height: 310px;
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  margin-left: 0px;
  -webkit-overflow-scrolling: touch;
}

.v2Slide a:active, .v2Slide a:hover {
  color: var(--dark);
}

.v2Card {
  position: relative;
  margin-right: 24px;
  padding: 0;
  min-width: 200px;
}

.v2Image {
  text-align: center;
  overflow: hidden;
  margin: 0;
}

.v2Image img {
  height: 100%;
  width: auto;
  position: relative;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.v__author {
  font-family: "MaisonNeue-Medium";
  font-weight: normal;
  font-size: 12px;
}

.v__name {
  font-family: "MaisonNeue-Demi";
  font-weight: normal;
  font-size: 20px;
  color: #fff;
  margin: 4px 0;
}

.v__artName {
  font-family: "MaisonNeue-Medium";
  font-weight: normal;
  font-size: 20px;
}

.v__more {
  visibility: visible;
}

.v2NameBox {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 15px;
  background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(60, 60, 60, 0.8)));
  background: -webkit-linear-gradient(top, transparent 0%, rgba(60, 60, 60, 0.8) 100%);
  background: linear-gradient(180deg, transparent 0%, rgba(60, 60, 60, 0.8) 100%);
  color: #fff;
  text-align: left;
}

.v2ArtNameBox {
  padding-bottom: 5px;
  margin-top: 18px;
}

.v2Slide .v2Image {
  height: 295px;
}

/* v2 collection 重写的样式*/
.collection_v2 .v2Slide {
  height: 295px;
}

.collection_v2 .v2Card {
  min-width: 200px;
}

/* v2 work 重写的样式*/
.work_v2 {
  height: auto;
}

.work_v2 .v2Card {
  min-width: 200px;
}

/* v2 Artists 重写的样式*/
.artists_v2 {
  visibility: visible;
}

.artists_v2 .v2Slide {
  height: inherit;
}

.artists_v2 .v__artName {
  text-align: left;
}

@media only screen and (max-width: 743px) {
  .collection_v2 .v2Card {
    width: 280px;
  }
  .work_v2 .v2Card {
    width: 280px;
  }
  .artists_v2 .v2Card {
    width: 280px;
  }
  .v2Header {
    display: block;
  }
  .v2Title {
    margin-bottom: 24px;
  }
  .v2More {
    text-align: left;
  }
}

:root {
  --xs-width: 300px;
  --s-width: 400px;
  --m-width: 500px;
  --l-width: 600px;
  --xl-width: 700px;
}

.work_title {
  font-family: MaisonNeue-Demi;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: var(--dark);
  text-align: left;
  margin-bottom: 24px;
}

.ant-btn:active > span {
  position: absolute;
}

.grid:after {
  content: "";
  display: block;
  clear: both;
}

.grid-item,
.grid-sizer {
  width: 1%;
}

.grid-item {
  float: left;
  overflow: hidden;
  margin-right: 24px;
  margin-bottom: 24px;
  overflow: hidden;
  position: relative;
}

.featurenewwork_container img {
  margin: 20px 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.featurenewwork_container img:hover {
  /* IE 9 */
  -webkit-transform: scale(1.1);
  /* Safari 3-8 */
  transform: scale(1.1);
}

.featurenewwork_container .ant-col {
  overflow: hidden;
  padding: 20px;
}

.grid-item-button {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: left;
  margin: 50px 0 30px 0;
}

.workdetail .bdgBJg.bdgBJg {
  width: 280px;
}

/* ---- grid-item ---- */
.sizer,
.item {
  float: left;
  width: 20%;
  height: 20vw;

  /* vertical gutter */
  margin: 0px;
  border: none;
  border-color: hsla(0, 0%, 0%, 0.5);
  overflow: hidden;
}

.item img {
  width: 100%;
  height: 100%;
}

.layout {
  margin: 0 auto;
  margin-bottom: 32px;
}

.layoutv2 {
  margin-top: 40px;
}

.layout:after {
  content: "";
  display: block;
  clear: both;
}

.Instagram-video {
  width: 100%;
  height: 100%;
}

/* more */
.follow-us {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 137px;
  text-align: right;
  font-family: "MaisonNeue-Medium";
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.9;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  cursor: pointer;
}
.follow-us .icon {
  margin-left: 5px;
}

@media only screen and (max-width: 743px) {
  .follow-us {
    display: none;
  }
}
@media only screen and (min-width: 744px) {
  .follow-us {
    display: none;
  }
}
@media only screen and (min-width: 1024px) {
  .follow-us {
    display: block;
  }
}

@media only screen and (max-width: 576px) {
  .sizer,
  .item {
    width: 50%;
    height: 50vw;
  }
  .layout {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .follow-us {
    line-height: 1.2;
  }
}
@media only screen and (min-width: 576px) and (max-width: 768px) {
  .sizer,
  .item {
    width: 50%;
    height: 50vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .sizer,
  .item {
    width: 33.3%;
    height: 33.3vw;
  }
}
@media only screen and (min-width: 1023px) and (max-width: 1439px) {
  .sizer,
  .item {
    width: 25%;
    height: 25vw;
  }
}
@media only screen and (min-width: 1440px) {
  .sizer,
  .item {
    width: 20%;
    height: 20vw;
  }
}
@media only screen and (min-width: 1920px) {
  .sizer,
  .item {
    width: 20%;
    height: 20vw;
  }
}
@media only screen and (min-width: 2020px) {
  .sizer,
  .item {
    width: 20%;
    height: 20vw;
  }
}

/* 
    CSS Nav
1. General Classes
2. Hero image
3. Featured Collection
4. Featured New Work
5. Artists 
6. Instagram
7. Media Query

*/

/*************************************** 1. General Classes *********************************************************************/
/****************************************************************************************************************************/
body {
  font-family: MaisonNeue-Book;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: var(--dark);
}

.ant-layout-content {
  background-color: var(--white);
}

/******************************************* 2. Hero Image *******************************************************************/
/**********************************************************************************************************************************/
.ant_carousel {
  position: relative;
  /* margin-top: 88px; */
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.ant_carousel .placeholder {
  width: 100%;
  height: 100%;
  background-color: #efeff1;
}

.ant_carousel img {
  min-width: 100%;
  min-height: 100vh;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
}

.ant_carousel .board {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-color: var(--white);
  z-index: 10;
  position: absolute;
  left: 40px;
  bottom: 0px;
  color: var(--dark);
}

.board_name {
  width: 80%;
  font-size: 12px;
  font-family: MaisonNeue-Bold;
  font-size: 36px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  text-align: center;
  color: var(--dark);
  margin-top: 8px;
}

.board_category {
  width: 74px;
  /* height: 14px; */
  font-family: MaisonNeue-Book;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1.5px;
  color: var(--steel-grey);
  margin-top: 40px;
}

.board_description {
  width: 88%;
  /* height: 48px; */
  font-family: MaisonNeue-Book;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  margin-top: 16px;
}

.board_more {
  width: 88%;
  margin-top: 16px;
  margin-bottom: 32px;
  font-family: MaisonNeue-Demi;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: 2px;
  text-align: center;
  color: var(--dark);
}

.slick-dots li {
  margin: 0px;
}

/******************************************* Featured New Work *******************************************************************/
/**********************************************************************************************************************************/
.featurenewwork_container {
  display: flex;
  flex-direction: column;
  margin: 96px 2.78%;
}

/******************************************* Media Query **************************************************************************/
/**********************************************************************************************************************************/
@media only screen and (max-width: 767px) {
  .ant_carousel {
    display: flex;
    /* margin-top: 56px; */
    flex-direction: column;
    height: 700px;
  }

  .ant_carousel img {
    min-height: 408px;
    top: 0;
  }

  .ant_carousel .board {
    left: 0px;
    top: 408px;
    width: 100%;
  }

  .board_description {
    font-size: 14px;
    margin-top: 8px;
    line-height: 1.43;
    display: none;
  }

  .featurenewwork_container {
    margin: 28px 16px 6px 16px;
  }

  .first_row .ant-col {
    padding: 16px 16px 16px;
  }

  .FeaturedCollection_Container {
    margin-top: 18px !important;
  }

  .slick-dots {
    bottom: 275px;
  }

  .homeArtists {
    margin: 0px 16px;
  }

  .homeArtists .FeaturedCollection_Container {
    margin: 0 auto;
  }
}

@media only screen and (min-width: 768px) {
  .ant_carousel .board {
    width: 61.5%;
    /* height: 346px; */
  }

  .board .board_more {
    font-size: 18px;
    line-height: 1.33;
  }

  .homeArtists .FeaturedCollection_Container .slick-slider {
    height: 320px;
  }

  .homeArtists .FeaturedCollection_Container .slick-list {
    height: 320px;
  }

  .homeArtists .FeaturedCollection_Container {
    height: 320px;
  }

  .homeArtists .FeaturedCollection_Image_Container {
    width: 48.9% !important;
    height: 320px;
    /*必须是数值*/
  }
}

@media only screen and (min-width: 1024px) {
  /************* 2. Hero Image ***************/
  .ant_carousel .board {
    width: 46%;
    /* height: 407px; */
  }
}

@media only screen and (min-width: 1440px) {
  .Section_Outer_Container {
    margin: 96px 159px 0px 159px;
  }

  .featurenewwork_container {
    margin: 96px 41px;
  }

  /************* 2. Hero Image *************/
  .ant_carousel .board {
    width: 40%;
    /* height: 358px; */
  }

  .homeArtists .FeaturedCollection_Container .slick-slider {
    margin-left: 2.85%;
    width: 94.3% !important;
    height: 420px;
  }

  .homeArtists .FeaturedCollection_Container .slick-list {
    width: 100% !important;
    height: 420px;
    /*必须写数值*/
    padding: 0px !important;
    margin: 0px !important;
  }

  .homeArtists .FeaturedCollection_Container {
    height: 420px;
  }

  .homeArtists .FeaturedCollection_Image_Container {
    width: 60% !important;
    height: 420px;
    /*必须是数值*/
  }
}

@media only screen and (min-width: 1920px) {
  .Section_Outer_Container {
    margin: 96px 159px 0px 159px;
  }

  /************* 2. Hero Image *******************/
  .ant_carousel .board {
    width: 30%;
    /* height: 358px; */
  }

  /************* 4. Featured New Work *******************/
  .featurenewwork_container {
    margin: 96px 159px;
  }

  .homeArtists .FeaturedCollection_Container .slick-slider {
    margin-left: 8.5%;
    width: 83% !important;
    height: 500px;
  }

  .homeArtists .FeaturedCollection_Container .slick-list {
    width: 100% !important;
    height: 500px;
    padding: 0px !important;
    margin: 0px !important;
  }

  .homeArtists .FeaturedCollection_Container {
    height: 500px;
  }

  .homeArtists .FeaturedCollection_Image_Container {
    width: 60% !important;
    height: 500px;
  }
}

/*********** Default  ***********/
.pl20 {
  padding-left: 20px;
}

.pr20 {
  padding-right: 20px;
}

.Anchor {
  height: 1px;
  visibility: visible;
  position: relative;
  overflow: hidden;
  display: block;
}

/*********** Banner  ***********/
.ant_carousel {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.desc_modal {
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 10;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}

.desc_modal .desc_group {
  position: absolute;
  width: 581px;
  left: 40px;
  text-align: left;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.desc_modal a {
  color: var(--white);
}

.desc_modal .desc_category {
  font-family: MaisonNeue-Medium;
  font-size: 18px;
  line-height: normal;
  color: var(--steel-grey);
}

.desc_modal .desc_name {
  font-family: MaisonNeue-Demi;
  font-style: normal;
  font-size: 60px;
  line-height: 1.2;
  margin-top: 20px;
}

.desc_modal .desc_description {
  width: 88%;
  font-size: 18px;
  margin-top: 28px;
  font-family: MaisonNeue-Medium;
  line-height: 1.6;
}

.desc_modal .desc_more {
  margin-top: 32px;
  font-family: MaisonNeue-Medium;
  color: var(--dark);
  font-size: 18px;
  line-height: 1.6;
}

.desc_modal .desc_more svg {
  margin-left: 8px;
}

.galleryV2 .board {
  display: none;
}

.galleryV2 .slick-dots {
  bottom: 30px;
}

.galleryV2 .slick-dots li {
  width: 6%;
  height: 4px;
  margin-right: 12px;
  background: rgba(255, 255, 255, 0.4);
}

.galleryV2 .slick-dots li:last-child {
  margin-right: 0px;
}

.galleryV2 .slick-dots button {
  padding: 0;
  width: 100%;
}

.galleryV2 .slick-dots .slick-active {
  background: #ffffff;
}

.galleryV2 .slick-dots li button:before {
  display: none;
}

/*********** Collections Works Artists ***********/
.v2-NewsOfGalleryAll,
.v2-Collections,
.v2-Works,
.v2-Artists {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  background: #fff;
}

.v2-NewsOfGalleryAll::after,
.v2-Collections::after,
.v2-Works::after,
.v2-Artists::after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom: 1px solid #ebebeb;
}

.v2-NewsOfGalleryAll:last-child:after,
.v2-Collections:last-child:after,
.v2-Works:last-child:after,
.v2-Artists:last-child:after {
  display: none;
}

.v2-Collections .both {
  padding-top: 120px;
}

.v2-Works .v2Card {
  float: left;
  width: auto !important;
}

.v2-Works .v2Image {
  text-align: left;
}

.v2-Works .work_content {
  overflow: hidden;
  padding-top: 120px;
  margin-bottom: 30px;
}

.v2-Artists .v2Card {
  float: left;
  width: auto !important;
}

.v2-Artists .v2Image {
  text-align: left;
}

.work_content .ant-row-flex {
  margin-bottom: 22px;
}

.workPop {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.64)), to(rgba(0, 0, 0, 0.64)));
  background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0.64));
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0.64));
  -webkit-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
}

.workPopContent {
  font-family: MaisonNeue-Medium;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  text-align: left;
  padding-left: 40px;
}

.workPopContent h2 {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #fff !important;
}

.workImg {
  position: relative;
  overflow: hidden;
}

.workImg img {
  max-width: 100%;
  -webkit-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
}

.workImg:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.workImg:hover .workPop {
  opacity: 1;
}

.collections_info {
  font-size: 18px;
  text-align: left;
  padding: 40px 0;
}

.v2-Artists .content-item {
  padding-top: 180px;
  margin-bottom: 40px;
}

.artists_centent {
  display: inline-block;
  overflow: hidden;
}

.artists-1024 {
  display: none;
}

.artists-1024 .ant-row-flex {
  margin-bottom: 40px;
}

.artists-item {
  width: 100%;
}

.artists-item-image {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.artists-item-image img {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  width: 100%;
  max-width: 100%;
  height: 295px;
  margin: auto;
}

.artists-item-info {
  font-family: "MaisonNeue-Medium";
  width: 100%;
  text-align: left;
}

.artists-item-info .artists-item-vrt {
  font-style: normal;
  font-size: 12px;
  line-height: 12px;
  color: var(--dark);
  mix-blend-mode: normal;
  opacity: 0.88;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 8px 0px;
}

.artists-item-info .artists-item-name {
  font-style: normal;
  font-size: 20px;
  line-height: 20px;
  color: var(--dark);
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 8px 0px;
}

.artists-item-link {
  visibility: visible;
}

.artists-item-link:hover img {
  -webkit-transform: scale(1.1, 1.1);
          transform: scale(1.1, 1.1);
}

/*********** News of Gallery All ***********/
.v2-NewsOfGalleryAll .smallSliderHeader {
  position: relative;
  width: 100%;
  margin-bottom: 40px;
}

.v2-NewsOfGalleryAll .smallSliderHeader::after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom: 1px solid #ebebeb;
}

.newsCard {
  width: 100%;
  margin-right: 8px;
  padding: 0 10px;
}

.newsCard .news-thumb {
  width: 100%;
  height: 240px;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.newsCard .news-thumb img {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  width: 100% !important;
  height: 240px;
}

.newsCard .news-thumb:hover img {
  -webkit-transform: scale(1.1, 1.1);
          transform: scale(1.1, 1.1);
}

.newsCard .news-content {
  visibility: visible;
}

.newsCard .news_title {
  font-family: "MaisonNeue-Demi";
  font-size: 20px;
  line-height: 1.2;
  color: var(--dark);
  text-align: left;
  margin-top: 24px;
}

.newsCard .news_description {
  font-family: "MaisonNeue-Medium";
  font-size: 16px;
  line-height: 1.6;
  color: #808080;
  mix-blend-mode: normal;
  text-align: left;
  margin-top: 16px;
}

.newsCard .news_read_more {
  font-family: "MaisonNeue-Medium";
  font-size: 16px;
  line-height: 1.6;
  text-align: right;
  color: #979797;
  margin-top: 16px;
  text-align: left;
}

.small_news .v2Slide {
  height: inherit;
}

.small_news .v2Card {
  width: 288px;
  padding: 0;
  margin-right: 40px;
}

.v2-NewsOfGalleryAll {
  padding-top: 20px;
}

.v2-NewsOfGalleryAll .slick-prev,
.v2-NewsOfGalleryAll .slick-next {
  width: 32px;
  height: 32px;
  line-height: 1;
  top: -80px;
}

.v2-NewsOfGalleryAll .slick-prev {
  left: unset;
  right: 80px;
}

.v2-NewsOfGalleryAll .slick-next {
  right: 4px;
}

.v2-NewsOfGalleryAll .slick-track {
  margin-left: unset;
  margin-right: unset;
}

.v2-NewsOfGalleryAll .slick-prev:before {
  font-size: 32px;
  content: url("/images/miscellaneous/icon-chevron-left.svg");
}

.v2-NewsOfGalleryAll .slick-next:before {
  font-size: 32px;
  content: url("/images/miscellaneous/icon-chevron-right.svg");
}

@media only screen and (max-width: 743px) {
  .ant_carousel {
    flex-direction: column;
    height: 700px;
  }
  .desc_modal .desc_group {
    width: 334px;
    left: unset;
  }
  .desc_modal .desc_category {
    font-size: 16px;
  }
  .desc_modal .desc_name {
    font-size: 40px;
  }
  .desc_modal .desc_description {
    font-size: 16px;
  }
  .desc_modal .desc_more {
    font-size: 16px;
  }
  .ant_carousel img {
    height: 100%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    -webkit-transform: translateY(-50%) translateX(-50%);
  }
  .v2-Collections,
  .v2-Works,
  .v2-Artists {
    margin: 0 24px;
    padding: 20px 0;
  }
  .collections_info {
    width: 100%;
  }
  .v2-NewsOfGalleryAll {
    margin: 0 24px;
    padding: 20px 0;
    margin-bottom: 40px;
  }
  .small_news .smallSlidercard {
    width: 288px;
    margin-right: 24px;
  }
}

@media only screen and (min-width: 744px) {
  .collections_info {
    width: 516px;
  }
  .ant-col-lg-24 {
    display: none;
  }
  .ant-col-lg-0 {
    display: block;
  }
  .v2-Collections {
    padding: 0 40px 40px 40px;
  }
  .v2-Works {
    padding: 40px;
  }
  .v2-Artists {
    padding: 40px;
  }
  .v2-NewsOfGalleryAll {
    padding: 40px;
    margin-bottom: 40px;
  }
  .v2-NewsOfGalleryAll .smallSliderHeader {
    margin-bottom: 32px;
  }
  .v2-NewsOfGalleryAll .smallSliderHeader::after {
    visibility: hidden;
  }
}

@media only screen and (min-width: 1024px) {
  .desc_modal .desc_group {
    left: 60px;
  }
  .left_nav {
    padding-right: 40px;
  }
  .left_nav .nav_group {
    padding-top: 100px;
  }
  .right_content {
    padding-left: 40px;
  }
  .v2-Collections {
    padding: 0 60px;
  }
  .v2-Collections::after {
    visibility: hidden;
  }
  .v2-Works {
    padding: 0 60px;
    margin-bottom: 40px;
  }
  .v2-Works::after {
    visibility: hidden;
  }
  .v2-Collections .both {
    padding-top: 100px;
  }
  .v2-Works .work_content {
    padding-top: 100px;
  }
  .v2-Artists {
    padding: 0 60px;
    margin-bottom: 120px;
  }
  .v2-Artists::after {
    visibility: hidden;
  }
  .v2-Artists .content-item {
    padding-top: 100px;
  }
  .v2-NewsOfGalleryAll {
    padding: 40px 60px;
    margin-bottom: 120px;
  }
  .v2-NewsOfGalleryAll::after {
    visibility: hidden;
  }
  .v2-NewsOfGalleryAll .smallSliderHeader {
    margin-bottom: 40px;
  }
  .v2-NewsOfGalleryAll .smallSliderHeader::after {
    visibility: visible;
  }
  .ant-col-lg-24 {
    display: block;
  }
  .ant-col-lg-0 {
    display: none;
  }
  .lg_footer {
    position: relative;
    width: 100%;
    padding: 48px 40px !important;
  }
  .lg_address {
    padding: 0 40px !important;
  }
  .left_nav {
    width: 300px;
  }
  .artists_centent {
    display: none;
  }
  .artists-1024 {
    display: block;
  }
}

@media only screen and (min-width: 1280px) {
  .desc_modal .desc_group {
    left: 140px;
  }
  .v2-Collections,
  .v2-Works {
    padding: 0 140px;
  }
  .v2-Collections::after,
  .v2-Works::after {
    visibility: hidden;
  }
  .v2-Artists {
    padding: 0 140px;
    margin-bottom: 120px;
  }
  .v2-Artists::after {
    visibility: hidden;
  }
  .v2-NewsOfGalleryAll {
    margin: 0;
    margin-bottom: 160px;
    padding-left: 140px;
    padding-right: 140px;
  }
  .v2-NewsOfGalleryAll::after {
    visibility: hidden;
  }
  .left_nav {
    width: 428px;
  }
  .left_nav .nav_group {
    padding-top: 120px;
  }
  .v2-Collections .both {
    padding-top: 120px;
  }
  .artists_centent {
    display: flex;
  }
  .artists-1024 {
    display: none;
  }
  .lg_footer {
    position: relative;
    width: 100%;
    padding: 48px 140px !important;
  }
  .lg_footer .pc_group {
    display: block;
  }
  .lg_footer .mobile_group {
    display: none;
  }
  .lg_address {
    padding: 0 140px !important;
  }
}

.c__download .download_icon {
  position: absolute;
  top: -3px;
  left: -16px;
}

.c__download .download_text {
  padding-left: 8px;
}

.desktop-navigation {
  background: transparent;
  height: 100px;
  line-height: 1.25;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9;
  border-bottom: 1px solid var(--pale-blue);
}

.desktop-navigation .wrap {
  height: 100%;
}

#logo {
  text-align: left;
  margin-bottom: 0px;
}

#search {
  font-family: MaisonNeue-Book;
  font-size: 21px;
  color: var(--dark);
  line-height: 1.43;
  text-align: right;
}

.ant-menu-horizontal {
  border: none;
}

.ant-menu-horizontal > .ant-menu-item:hover {
  border-bottom: 2px solid white;
  color: white;
}

.ant-menu-horizontal > .ant-menu-item-selected {
  border-bottom: 2px solid white;
  color: white;
}

#midlayer {
  position: fixed;
  size: fill;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(28, 29, 34, 0.6);
  display: none;
}

.phoneNavVer2 {
  top: 50px !important;
}

.xsNav {
  text-align: left;
  padding-top: 28px;
}

.xsLogo {
  padding-top: 28px;
}

/************************************************************ dropdown menu  ******************************************************************/
.pcHeader .ant-menu-item,
.pcHeader .ant-menu-submenu {
  margin-left: 40px;
  padding: 0;
}

.ant-menu-sub .names {
  width: 75%;
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
  -webkit-column-fill: auto;
     -moz-column-fill: auto;
          column-fill: auto;
  -webkit-column-gap: 40px;
  /* Chrome, Safari, Opera */
  -moz-column-gap: 40px;
  /* Firefox */
  grid-column-gap: 40px;
  column-gap: 40px;
}

.ant-menu-sub .viewallbtn {
  width: 20%;
  padding-left: 5%;
}

.navArtists .ant-menu-sub {
  width: 100%;
  padding: 0px 140px;
  display: flex;
  flex-direction: column;
}

.ant-menu-submenu-hidden {
  display: none !important;
}

.navArtists .ant-menu-item > a {
  font-family: MaisonNeue-Book;
  font-size: 18px;
  line-height: 1.67;
  text-align: left;
  color: var(--dark);
}

.ant-menu-submenu-popup {
  top: 80px;
  width: 100%;
  border-radius: 0;
}

.ant-dropdown {
  left: 0px;
  top: 70px;
  width: 100%;
  position: relative;
  text-align: left;
  background: white;
}

.ant-dropdown .ant-menu {
  padding-top: 40px;
}

.viewallbtn .anticon {
  top: -5px;
  right: -15px;
  /* position: absolute; */
}

.xsMenu {
  font-family: 'MaisonNeue-Medium';
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--dark);
}

#xsNav li {
  margin-bottom: 40px;
}

.ant-menu-vertical > .ant-menu-item {
  height: 50px;
}

.ant-menu-horizontal > .ant-menu-item > a {
  color: var(--dark);
}

.ant-menu-horizontal > .ant-menu-item > a:hover {
  text-decoration: none;
  color: var(--white);
}

.ant-menu-vertical > .ant-menu-item > a:hover {
  text-decoration: none;
  color: var(--dark);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: var(--white);
}

.xsMenu .anticon {
  position: absolute;
  top: -2px;
  left: 83px;
}

/******************************************* Search Bar **************************************************************************/
.search-container {
  float: left;
  padding: 24px 40px;
}

.hiddenSearchBar {
  overflow: hidden;
  background-color: var(--white);
  width: 100%;
  height: 96px;
  top: 80px;
  z-index: 999;
  display: none;
}

.hiddenSearchBar input[type='text'] {
  padding: 6px;
  margin-top: 8px;
  font-size: 16px;
  border: none;
}

/************************************************** footer bar ***************************************************************/
.copyright {
  visibility: visible;
}

/************************************************** Version 2 ***************************************************************/
.navWorks.ant-menu-submenu-popup {
  width: 250px;
}

.navWorks .artistListContainer {
  display: block;
}

.navWorks .artistListItems {
  display: block;
}

.navWorks .ant-menu-sub {
  width: 100%;
}

.navWorks .ant-menu-item > a {
  font-family: MaisonNeue-Book;
  font-size: 18px;
  line-height: 1.67;
  text-align: left;
  color: var(--dark);
}

.desktop-navigation {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-bottom: 0;
}

.desktop-navigation .xsNavBtn {
  border: 0;
  background: transparent;
}

.desktop-navigation .pc-submenu svg path {
  stroke: white;
}

.desktop-navigation .ant-menu-submenu-title {
  padding: 0;
}

.desktop-navigation .galleryAllLogo path {
  fill: transparent;
}

.desktop-navigation .galleryAllLogo g {
  fill: #fff;
}

.desktop-navigation .iconMenulogo path {
  fill: #fff;
}

.desktop-navigation .iconChevronDown path {
  fill: #fff;
}

.desktop-navigation .ant-menu {
  background: transparent;
}

.desktop-active {
  background: var(--white);
}

.desktop-active .galleryAllLogo path {
  fill: transparent;
}

.desktop-active .galleryAllLogo g {
  fill: #000;
}

.desktop-active .pc-submenu svg path {
  stroke: black;
}

.desktop-active .iconMenulogo path {
  fill: #1c1d22;
}

.desktop-active .iconChevronDown path {
  fill: #1c1d22;
}

.xsHide {
  display: none;
}

.xsHide.xsShow {
  display: block;
}

.xsNavVer2 {
  position: fixed;
  z-index: 8;
  top: 88px;
  width: 100%;
  height: calc(100% - 88px);
  left: 0;
  background: #fff;
  text-align: left;
  overflow-y: auto;
}

.xsNavVer2 .nTitle {
  padding: 18px 24px;
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  cursor: pointer;
}

.xsNavVer2 .nTitle .icon {
  float: right;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.xsNavVer2 .nTitle .xsShow {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.xsNavVer2 .linkItem {
  padding: 18px 24px;
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  cursor: pointer;
}

.xsNavVer2 .linkItem .xsMenuLink {
  display: inline-block;
  width: 100%;
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--dark);
}

.childrenList,
.artistsChild {
  padding-left: 24px;
  padding-right: 24px;
  background-color: var(--pale-blue);
}

.childrenList .viewMore,
.artistsChild .viewMore {
  font-family: "MaisonNeue-Medium";
  font-size: 16px;
  padding-top: 14px;
  padding-bottom: 14px;
}

.childrenList .viewMore span,
.artistsChild .viewMore span {
  margin-right: 8px;
}

.childrenList .namesItem,
.artistsChild .namesItem {
  font-family: "MaisonNeue-Medium";
  font-size: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.childrenList .artName,
.artistsChild .artName {
  font-family: "MaisonNeue-Medium";
  font-size: 16px;
  color: #979797;
  padding-top: 14px;
  padding-bottom: 10px;
}

.artistsChild .namesItem {
  padding-left: 16px;
}

/******************************************* Media Query **************************************************************************/
/**********************************************************************************************************************************/
@media only screen and (max-width: 576px) {
  .ant-menu-sub .names {
    width: 100%;
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
  .ant-menu-sub .viewallbtn {
    width: 100%;
    padding-left: 0px;
    margin-bottom: 24px;
    font-size: 14px;
    text-align: left;
  }
  .viewallbtn li {
    text-align: left;
  }
  .viewallbtn button {
    padding: 0;
  }
  .ant-dropdown .ant-menu {
    padding-top: 0px;
    width: 100% !important;
  }
  .navWorks .ant-menu-item > a {
    font-size: 16px;
  }
  .navArtists .ant-menu-item > a {
    font-size: 16px;
  }
  /* .ant-menu-submenu-arrow {
      display: none;
    } */
  .xsMenu .anticon {
    position: absolute;
    top: 2px;
  }
  .viewallbtn .anticon {
    top: -4px;
    right: 28px;
    /* position: absolute; */
  }
  .viewallbtn .isvg svg {
    width: 20px;
  }
  .names .ant-menu-item {
    margin: 8px 0 !important;
    padding-left: 0 !important;
  }
  .names .ant-menu-item {
    font-family: MaisonNeue-Book;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--dark);
  }
  /* phone screen header style */
  .xsNav .xsNavBtn {
    border: white;
    color: var(--dark);
    padding-left: 0;
  }
  #logo {
    font-size: 19.1px;
  }
  #search {
    margin-top: 18px;
  }
  /* artist submenu position */
  .ant-menu-submenu-placement-rightTop {
    top: 230px !important;
  }
  .thing1.extra_stuff::after {
    content: 'Some parts of me, changed!';
    background-color: #8888cc;
  }
  .ant-menu-submenu {
    margin: 40px 0 !important;
  }
  .ant-menu-item {
    padding-left: 16px !important;
    margin: 40px 0 !important;
  }
  .ant-menu-submenu-title {
    padding-left: 16px !important;
  }
  .ant-menu-item-selected > a,
  .ant-menu-item > a {
    color: var(--dark);
  }
  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    border-right: none;
  }
  .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
    right: 280px;
    color: black;
  }
  .menu-submenu-title:hover {
    color: var(--dark);
  }
  .dropdownartist .ant-menu-inline {
    padding: 24px 32px 40px 32px;
    background-color: var(--pale-blue);
  }
  .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
    background: var(--dark);
  }
  /************ footer bar ***********/
}

@media only screen and (max-width: 576px) and (max-width: 576px) {
  .gallery_footer .InputEmailAddress {
    width: 150px;
  }
  .gallery_footer .SignupButton {
    float: right;
  }
}

@media only screen and (max-width: 576px) {
  .socialMedia_container .socialIcon {
    margin-right: 19px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
  .navWorks .ant-menu-sub {
    padding: 24px 40px 40px 40px;
    background-color: var(--pale-blue);
    display: flex;
    flex-direction: column-reverse;
  }
  .navArtists .ant-menu-sub {
    padding: 0px 40px 0px 40px;
    background-color: var(--pale-blue);
    display: flex;
    flex-direction: column-reverse;
  }
  .ant-menu-sub .names {
    width: 100%;
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-fill: auto;
       -moz-column-fill: auto;
            column-fill: auto;
    -webkit-column-gap: 30px;
    /* Chrome, Safari, Opera */
    -moz-column-gap: 30px;
    /* Firefox */
    grid-column-gap: 30px;
    column-gap: 30px;
  }
  .ant-menu-sub .viewallbtn {
    width: 30%;
    padding-left: 5px;
    margin-bottom: 24px;
    font-size: 16px;
  }
  .viewallbtn button {
    padding: 0;
  }
  .navWorks .ant-menu-item > a {
    font-size: 16px;
  }
  .navArtists .ant-menu-item > a {
    font-size: 16px;
  }
  .ant-menu-submenu-arrow {
    display: none;
  }
  .xsMenu .anticon {
    position: absolute;
    top: 2px;
  }
  .viewallbtn .anticon {
    top: -4px;
    right: 28px;
    /* position: absolute; */
  }
  .viewallbtn .isvg svg {
    width: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .navWorks .ant-menu-sub {
    padding: 24px 40px 40px 40px;
  }
  .navArtists .ant-menu-sub {
    padding: 0px 40px 0px 40px;
  }
  .ant-menu-sub .names {
    width: 85%;
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-fill: auto;
       -moz-column-fill: auto;
            column-fill: auto;
    -webkit-column-gap: 30px;
    /* Chrome, Safari, Opera */
    -moz-column-gap: 30px;
    /* Firefox */
    grid-column-gap: 30px;
    column-gap: 30px;
  }
  .ant-menu-sub .viewallbtn {
    width: 10%;
    padding-left: 2%;
  }
  .ant-menu-horizontal > .ant-menu-item,
  .ant-menu-horizontal > .ant-menu-submenu {
    margin-left: 3%;
    padding: 0;
  }
  .ant-layout-header {
    padding: 0 0 0 10px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .navWorks .ant-menu-sub {
    padding-left: 22px;
  }
  .navArtists .ant-menu-sub {
    padding: 0px 40px 0px 40px;
  }
}

@media only screen and (max-width: 1023px) {
  .xsNav {
    display: block;
  }
  .xsNavBtn {
    padding-left: 0;
  }
  .xsLogo #logo {
    text-align: center;
  }
  .xsLogo.ant-col-md-4 {
    width: 66.66666667%;
  }
}

@media only screen and (max-width: 743px) {
  .ant-layout-header {
    padding-left: 24px;
    padding-right: 24px;
  }
  .xsNav {
    padding-top: 10px;
  }
  .xsLogo {
    padding-top: 14px;
  }
}

@media only screen and (min-width: 1px) and (max-width: 1023px) {
  .ant-layout-header {
    height: 88px;
  }
  .xsNavVer2 {
    top: 88px;
    height: calc(100% - 88px);
  }
  .xsNav {
    padding-top: 28px;
  }
  .xsLogo {
    padding-top: 22px;
    width: 66.66666667% !important;
  }
}

@media only screen and (max-width: 768px) {
  #logo {
    text-align: center;
  }
}

@media only screen and (min-width: 744px) {
  .ant-layout-header {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media only screen and (min-width: 1024px) {
  .ant-layout-header {
    padding-left: 60px;
    padding-right: 60px;
  }
  .xsNav {
    display: none;
  }
  .xsNavDesktop {
    display: block;
  }
}

@media only screen and (min-width: 1280px) {
  .ant-layout-header {
    padding-left: 140px;
    padding-right: 140px;
  }
}

.pcHeader {
  padding-bottom: 0px;
}

.artistListContainer {
  display: flex;
  flex-direction: row;
}

.navWorks .menu-item-artist {
  width: 100%;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}

.navArtists .menu-item-artist {
  text-align: left;
  line-height: 1;
  padding: 14px 0;
  white-space: normal;
}

@media only screen and (min-width: 1024px) {
  .navArtists .menu-item-artist {
    max-width: 33%;
    width: 33%;
  }
}

@media only screen and (min-width: 1024px) {
  .navArtists .menu-item-artist {
    max-width: 25%;
    width: 25%;
  }
}

.artistListItems {
  display: flex;
  flex-wrap: wrap;
  font-size: 18px;
  width: 100%;
}

.navWorks .artistListItems {
  width: 100%;
}

.navWorks .ant-menu-vertical .ant-menu-item {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.navArtists .ant-menu-vertical .ant-menu-item {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 0px;
  margin-bottom: 0px;
}

/*  */
.v_viewallbtn {
  width: 100% !important;
  text-align: right;
  border-top: 1px solid #ebebeb;
}

.v_viewallbtn a {
  display: inline-block;
  line-height: 1;
}

.ViewAllButton {
  width: 126px;
  height: 24px;
  border: none;
}

@media (max-width: 576px) {
  .ViewAllButton {
    background: var(--ice-blue);
    text-align: left;
  }
}

.ViewAllText {
  display: inline-block;
  font-family: MaisonNeue-Demi;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: 2px;
  color: var(--dark);
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.ViewAllText .t_text {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  margin-right: 8px;
}

@media (max-width: 576px) {
  .ViewAllText {
    font-size: 14px;
    line-height: 1;
  }
}

/*  */
.art_item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.art_item_name {
  font-size: 18px;
  min-width: 220px;
  color: #979797;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 0;
  padding-right: 26px;
}

.art_item_name .anticon {
  vertical-align: middle;
  line-height: 1;
  fill: var(--dark);
}

.art_item_name .t_text {
  font-family: 'MaisonNeue-Demi';
  display: inline-block;
  line-height: 1.76;
}

.art_item_name.actives {
  font-family: 'MaisonNeue-Demi';
  color: var(--dark);
}

.art_item_name.unactive {
  font-family: 'MaisonNeue-Medium';
  color: #979797;
}

.art_item_name.unactive .anticon {
  display: none;
}

.art_list {
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 120px;
  position: relative;
}

.art_lists {
  width: 100%;
  min-height: 160px;
  position: relative;
  border-left: 1px solid #ebebeb;
}

.art_lists .artistListContainer {
  padding-top: 10px;
  padding-left: 20px;
  padding-bottom: 10px;
}

.art_lists .show {
  display: block;
}

.art_lists .hide {
  display: none;
}

.xsNavDesktop {
  text-align: right;
}

#nav .xsMenu {
  color: var(--white);
}

.desktop-navigation .ant-menu-item {
  border-bottom: 0px !important;
}

.desktop-active #nav {
  color: var(--dark);
}

.desktop-active #nav .xsMenu {
  color: var(--dark);
}

.xsNavDesktop {
  line-height: 100px;
}

.pc_nav_header {
  width: 100%;
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: middle;
  border-bottom: 2px solid transparent;
  text-align: right;
  font-family: 'MaisonNeue-Medium';
  font-size: 21px;
  color: var(--dark);
  line-height: 1.43;
  text-align: right;
}

.pc_nav_header {
  -webkit-font-feature-settings: 'tnum', 'tnum';
          font-feature-settings: 'tnum', 'tnum';
  margin-bottom: 0;
  padding-left: 0;
  color: rgba(0, 0, 0, 0.65);
  line-height: 100px;
  list-style: none;
}

.pc_nav_header .pc_menu_item {
  margin: 0;
  white-space: nowrap;
  cursor: pointer;
  position: relative;
  top: 0px;
  display: inline-block;
  vertical-align: bottom;
  border-bottom: 2px solid transparent;
  margin-left: 3%;
  padding: 0;
}

.pc_nav_header .xsMenu {
  position: relative;
}

.pc_nav_header .xsMenu::after {
  visibility: hidden;
  content: ' ';
  position: absolute;
  width: 100%;
  right: 0;
  bottom: -10px;
  border-bottom: 2px solid #000000;
}

.pc_nav_header .actives::after {
  visibility: visible;
}

.pc_nav_header a:active,
.pc_nav_header a:hover {
  text-decoration: none;
  outline: 0;
  color: var(--dark);
}

.header_items {
  position: absolute;
  top: 99px;
  background: #ffffff;
  border-top: 1px solid #ebebeb;
  line-height: 1;
}

.pc_works_items {
  border: 0;
  width: 256px;
  left: -50px;
  padding: 32px;
}

.pc_works_items::before {
  position: fixed;
  border-top: 1px solid #ebebeb;
  width: 100%;
  content: ' ';
  left: 0;
  top: 99px;
}

.pc_works_items .menu-item-artist {
  margin-bottom: 28px;
}

.pc_works_items .menu-item-artist:last-child {
  margin-bottom: 0;
}

.pc_artists_item {
  position: fixed;
  width: 100%;
  left: 0;
}

@media only screen and (max-width: 1023px) {
  .xsNavDesktop {
    display: none;
  }
}

@media only screen and (min-width: 1024px) {
  .pc_artists_item {
    padding: 0 60px;
  }
}

@media only screen and (max-width: 1279px) {
  .pc_artists_item .menu-item-artist {
    max-width: 33.33%;
    width: 33.33%;
  }
}

@media only screen and (min-width: 1280px) {
  .pc_artists_item {
    padding: 0 140px;
  }
}

.sm_footer {
  visibility: visible;
}

.lg_footer {
  padding: 48px 60px 64px 60px;
}

.mobile_group {
  display: none;
}

.footer_code_dailog {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 101;
}

.footer_code_dailog.active {
  display: block;
}

.footer_code_dailog .mask {
  position: fixed;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.footer_code_dailog .footer_dailog_content {
  position: absolute;
  z-index: 2;
  width: 256px;
  height: 256px;
  top: 50%;
  left: 50%;
  margin-top: -128px;
  margin-left: -128px;
}

.lg_footer_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-content: center;
}

.lg_footer_item .NewsLetter {
  width: 400px;
}

.lg_address {
  position: relative;
  height: 42px;
  line-height: 42px;
  padding: 0 30px;
  background-color: #000000;
  border-bottom: 1px solid rgba(151, 151, 151, 0.64);
}

.lg_address span {
  color: #fff;
}

.lg_address .copyright {
  float: right;
  line-height: 42px;
  padding: 0;
}

.lg_address .copyright span {
  color: #fff;
}

.followUs .code_item {
  display: none;
  position: absolute;
  left: 45px;
  bottom: -8px;
  background: #fff;
  flex: 1 1;
  width: 112px;
  border: 2px solid #000000;
  box-sizing: border-box;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  z-index: 1;
}

.followUs .code_item:first-child {
  margin-right: 16px;
}

.followUs .code_item span {
  position: absolute;
  width: 20px;
  top: 2px;
  left: 5px;
  font-size: 12px;
  word-wrap: break-word;
  color: var(--dark);
}

.followUs .code_item img {
  float: right;
  width: 80px;
  height: 80px;
  margin: 6px;
}

.lg_title {
  font-family: MaisonNeue-Book;
  font-style: normal;
  font-size: 21px;
  line-height: 21px;
  color: var(--dark);
  margin-bottom: 20px;
}

.lg_content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  align-content: center;
}

.icon_group .icon_item {
  cursor: pointer;
  position: relative;
  margin-right: 20px;
}

.icon_group .icon_item.wechat:hover .code_item {
  display: block;
}

.icon_group .icon_item.redbook:hover .code_item {
  display: block;
}

.small_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-content: center;
  margin-bottom: 10px;
}

.footer-text-style {
  font-family: MaisonNeue-Book;
  font-style: normal;
  font-size: 12px;
}

.footer-text-style a {
  color: #fff;
}

.footer-text-style span {
  color: #fff !important;
}

.sm_address {
  position: relative;
  line-height: 36px;
  background: #000000;
  padding: 0 36px;
  color: #fff;
}

.sm_address::after {
  display: block;
  content: " ";
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  bottom: 0;
  background: rgba(151, 151, 151, 0.64);
}

.sm_address svg {
  margin-right: 5px;
}

.sm_address span {
  color: #fff;
}

.pc744 {
  display: block;
  font-size: 12px;
}

.pc743 {
  position: relative;
  display: none;
  padding: 12px 0;
  padding-left: 24px;
  font-size: 12px;
}

.pc743 p {
  margin-bottom: 0;
  line-height: 16px;
}

.pc743 p:last-child {
  margin-top: 2px;
  padding-left: 15px;
}

.sm_copyright {
  height: 36px;
  line-height: 36px;
  background: #000000;
  padding: 0 36px;
  color: #fff;
}

.sm_copyright span {
  color: #fff;
}

.socialMedia_container {
  margin-bottom: 20px;
}

.contact-group {
  font-size: 16px;
}

.contact-group .contact-item {
  padding-bottom: 12px;
}

.contact-group .contact-item .icon {
  padding-right: 9px;
}

.gallery_footer {
  border-top: 1px solid #e4e5e7;
  background: white;
  padding: 0;
  text-align: left;
  width: 100%;
  position: relative;
}

@media only screen and (max-width: 743px) {
  .gallery_footer .pub_footer {
    padding: 48px 24px 40px 24px !important;
    width: 100% !important;
  }
  .gallery_footer .copyright {
    padding: 0 24px !important;
  }
  .gallery_footer .followUs {
    width: 100% !important;
  }
  .gallery_footer .followUs .code_item {
    width: 175px;
  }
  .gallery_footer .followUs .code_item span {
    top: 30px;
    left: 10px;
  }
  .gallery_footer .followUs .code_item img {
    width: 128px;
    height: 119px;
    margin: 9px;
  }
  .gallery_footer .address {
    position: relative !important;
    height: 58px !important;
    line-height: 36px !important;
    padding: 10px 24px !important;
  }
  .gallery_footer .address .en {
    position: static !important;
  }
  .sm_footer {
    padding: 24px;
  }
  .sm_footer .pc_group {
    display: none;
  }
  .sm_footer .mobile_group {
    display: block;
  }
  .sm_footer .small_item {
    display: block;
  }
  .sm_footer .contact-group {
    margin-top: 30px;
  }
  .sm_footer .contact-group .lg_title {
    margin-bottom: 0px;
  }
  .gallery_footer .sm_address {
    padding: 0;
  }
  .gallery_footer .pc744 {
    display: none;
  }
  .gallery_footer .pc743 {
    display: block;
    line-height: 1;
  }
  .gallery_footer .sm_copyright {
    padding: 0 24px;
  }
}

@media only screen and (min-width: 744px) {
  .sm_footer {
    position: relative;
    width: 608px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 40px;
  }
  .sm_footer .pc_group {
    display: none;
  }
  .sm_footer .mobile_group {
    display: block;
  }
  .sm_footer .lg_title {
    margin-bottom: 10px;
  }
  .gallery_footer .copyright {
    padding: 0 30px !important;
  }
  .gallery_footer .followUs {
    width: 240px;
  }
  .gallery_footer .followUs .code_item {
    width: 112px;
  }
  .gallery_footer .followUs .code_item span {
    top: 0;
  }
  .gallery_footer .followUs .code_item img {
    width: 80px;
    height: 80px;
    margin: 6px;
  }
}

.carouselImg_works {
  padding: 0 12px;
  text-align: center;
}
.carouselImg_works img {
  height: 100%;
  width: auto;
  max-width: 100%;
  margin: auto;
  object-fit: scale-down;
}
/**************************************************** media query ******************************************************/
@media only screen and (max-width: 767px) {
  .carouselImg_works {
    height: 260px;
  }
}
@media only screen and (min-width: 768px) {
  .carouselImg_works {
    height: 494px;
  }
}
@media only screen and (min-width: 1024px) {
  .carouselImg_works {
    height: 450px;
  }
}
@media only screen and (min-width: 1440px) {
  .carouselImg_works {
    height: 560px;
  }
}
@media only screen and (min-width: 1920px) {
  .carouselImg_works {
    height: 640px;
  }
}

.list_video {
  overflow-y: hidden;
  overflow-x: auto;
}

.list_video .video {
  width: 100%;
  height: 100%;
}

.list_video .video iframe {
  height: 100% !important;
}

.list_video.more {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}

.list_video.more .video {
  width: 80%;
  min-width: 80%;
  margin-right: 4%;
}

/* 
    CSS Nav
1. General Classes
2. Hero image
3. Featured Collection
4. Featured New Work
5. Artists 
6. Instagram
7. Media Query

*/

/*************************************** 1. General Classes *********************************************************************/
/****************************************************************************************************************************/
body {
  font-family: MaisonNeue-Book;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: var(--dark);
}

.ant-layout-content {
  background-color: var(--white);
}
/******************************************* 2. Hero Image *******************************************************************/
/**********************************************************************************************************************************/
.ant-carousel {
  position: relative;
  /* margin-top: 88px; */
  height: 100vh;
  width: 100%;
  overflow: hidden;
}
.ant-carousel .placeholder {
  width: 100%;
  height: 100%;
  background-color: #efeff1;
}
.ant-carousel img {
  min-width: 100%;
  min-height: 100vh;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
}

.ant-carousel .board {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-color: var(--white);
  z-index: 10;
  position: absolute;
  left: 40px;
  bottom: 0px;
  color: var(--dark);
}

.board_name {
  width: 80%;
  font-size: 12px;
  font-family: MaisonNeue-Bold;
  font-size: 36px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  text-align: center;
  color: var(--dark);
  margin-top: 8px;
}

.board_category {
  width: 74px;
  /* height: 14px; */
  font-family: MaisonNeue-Book;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1.5px;
  color: var(--steel-grey);
  margin-top: 40px;
}

.board_description {
  width: 88%;
  /* height: 48px; */
  font-family: MaisonNeue-Book;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  margin-top: 16px;
}

.board_more {
  width: 88%;
  margin-top: 16px;
  margin-bottom: 32px;
  font-family: MaisonNeue-Demi;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: 2px;
  text-align: center;
  color: var(--dark);
}
.slick-dots li {
  margin: 0px;
}
/******************************************* Featured New Work *******************************************************************/
/**********************************************************************************************************************************/
.featurenewwork_container {
  display: flex;
  flex-direction: column;
  margin: 96px 2.78%;
}

/******************************************* Media Query **************************************************************************/
/**********************************************************************************************************************************/
@media only screen and (max-width: 767px) {
  .ant-carousel {
    display: flex;
    margin-top: 56px;
    flex-direction: column;
    height: 700px;
  }
  .ant-carousel img {
    min-height: 408px;
    top: 0;
  }

  .ant-carousel .board {
    left: 0px;
    top: 408px;
    width: 100%;
  }
  .board_description {
    font-size: 14px;
    margin-top: 8px;
    line-height: 1.43;
    display: none;
  }
  .featurenewwork_container {
    margin: 28px 16px 6px 16px;
  }
  .first_row .ant-col {
    padding: 16px 16px 16px;
  }
  .FeaturedCollection_Container {
    margin-top: 18px !important;
  }
  .slick-dots {
    bottom: 275px;
  }
  .homeArtists {
    margin: 0px 16px;
  }
  .homeArtists .FeaturedCollection_Container {
    margin: 0 auto;
  }
}
@media only screen and (min-width: 768px) {
  .ant-carousel .board {
    width: 61.5%;
    /* height: 346px; */
  }
  .board .board_more {
    font-size: 18px;
    line-height: 1.33;
  }
  .homeArtists .FeaturedCollection_Container .slick-slider {
    height: 320px;
  }

  .homeArtists .FeaturedCollection_Container .slick-list {
    height: 320px;
  }

  .homeArtists .FeaturedCollection_Container {
    height: 320px;
  }

  .homeArtists .FeaturedCollection_Image_Container {
    width: 48.9% !important;
    height: 320px; /*必须是数值*/
  }
}
@media only screen and (min-width: 1024px) {
  /************* 2. Hero Image ***************/
  .ant-carousel .board {
    width: 46%;
    /* height: 407px; */
  }
}
@media only screen and (min-width: 1440px) {
  .Section_Outer_Container {
    margin: 96px 159px 0px 159px;
  }
  .featurenewwork_container {
    margin: 96px 41px;
  }

  /************* 2. Hero Image *************/
  .ant-carousel .board {
    width: 40%;
    /* height: 358px; */
  }

  .homeArtists .FeaturedCollection_Container .slick-slider {
    margin-left: 2.85%;
    width: 94.3% !important;
    height: 420px;
  }

  .homeArtists .FeaturedCollection_Container .slick-list {
    width: 100% !important;
    height: 420px; /*必须写数值*/
    padding: 0px !important;
    margin: 0px !important;
  }

  .homeArtists .FeaturedCollection_Container {
    height: 420px;
  }

  .homeArtists .FeaturedCollection_Image_Container {
    width: 60% !important;
    height: 420px; /*必须是数值*/
  }
}
@media only screen and (min-width: 1920px) {
  .Section_Outer_Container {
    margin: 96px 159px 0px 159px;
  }
  /************* 2. Hero Image *******************/
  .ant-carousel .board {
    width: 30%;
    /* height: 358px; */
  }

  /************* 4. Featured New Work *******************/
  .featurenewwork_container {
    margin: 96px 159px;
  }

  .homeArtists .FeaturedCollection_Container .slick-slider {
    margin-left: 8.5%;
    width: 83% !important;
    height: 500px;
  }
  .homeArtists .FeaturedCollection_Container .slick-list {
    width: 100% !important;
    height: 500px;
    padding: 0px !important;
    margin: 0px !important;
  }

  .homeArtists .FeaturedCollection_Container {
    height: 500px;
  }

  .homeArtists .FeaturedCollection_Image_Container {
    width: 60% !important;
    height: 500px;
  }
}

/* ************* Renderworkdetail ********************* */
.wk_slick-slider-customize {
  width: 600px;
  display: flex;
  flex-direction: column;
  margin-right: 40px;
}

.workdetail_edition:hover {
  text-decoration: underline;
}

.data th,
td {
  padding: 1px 30px 1px 0px;
  text-align: left;
}

.downloadIconWrap {
  padding-top: 24px;
  margin-left: 13px;
}

.downloadIconWrap .c__download {
  flex-direction: row;
}

.downloadIconWrap .c__download .pc__download {
  margin-right: 32px;
}

.downloadIcon {
  font-family: MaisonNeue-Book;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--dark);
  margin-left: 34px;
  cursor: pointer;
}

.downloadIcon-disable {
  cursor: default;
}

.socialIcon .anticon {
  margin-right: 28px;
}

html {
  scroll-behavior: smooth;
}

.wk_warp_pc {
  display: flex;
  flex-direction: row;
  padding-top: 122px;
}

.wk_warp_pc .slick-dots {
  display: flex;
  flex-direction: column;
  width: 15%;
  margin-right: 26px;
  display: flex;
  bottom: 0;
  position: static;
}

.wk_warp_pc .slick-track {
  height: auto !important;
}

.wk_warp_pc .slick-dots li {
  width: 100%;
  height: auto;
  margin-bottom: 19px;
  padding: 0;
  cursor: pointer;
}

.wk_warp_pc .slick-list {
  display: flex;
  width: 85%;
}

.wk_warp_pc .slick-dots li img {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.wk_warp_pc .slick-slider {
  margin: 24px 30px;
  width: 61%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-direction: row-reverse;
}

.wk_warp_pc .slider-img-div {
  height: 100%;
  text-align: left;
}

.wk_warp_pc .slider-img-div img {
  max-height: 100px;
}

.wk_warp_pc .ant-col-sm-24 {
  display: flex;
}

.m__show {
  display: none;
}

/******************************************* Media Query **************************************************************************/
/**********************************************************************************************************************************/
@media only screen and (max-width: 576px) {
  .wk_warp_pc .slick-dots {
    display: none;
  }
  .wk_warp_pc .slick-list {
    width: 100%;
  }
  .wk_warp_pc .downloadIconWrap .c__download {
    flex-direction: column;
  }
  .wk_warp_pc .ant-col-sm-24 {
    display: none;
  }
  .wk_warp_pc .slick-slider {
    width: 100%;
    margin: 0px;
  }
  .wk_slick-slider-customize {
    margin: 24px 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
  .wk_warp_pc {
    position: relative;
    margin: 80px 40px;
    margin-bottom: 0;
    padding-bottom: 20px;
    flex-direction: column;
  }
  .wk_warp_pc::after {
    content: "";
    position: absolute;
    left: -100px;
    right: 0;
    width: 200%;
    height: 1px;
    background-color: #e8e8e8;
    bottom: 0;
  }
  .wk_warp_pc .text_top_info {
    flex-direction: column;
  }
  .wk_warp_pc .ant-row {
    width: 100%;
  }
  .wk_warp_pc .slick-slider {
    margin: 24px 0;
    width: 58%;
  }
  .wk_warp_pc .slick-list {
    width: 90%;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
  .wk_warp_pc .slick-list {
    width: 100%;
  }
  .downloadIcon {
    margin-left: 0px;
    margin-right: 34px;
  }
}

.HeroCarouselMobile {
  height: 280px;
}

@media only screen and (max-width: 1023px) {
  .wm_Artist .ant-divider {
    display: none;
  }
}

@media only screen and (max-width: 1023px) {
  .HeroCarouselMobile {
    height: inherit;
  }
  .HeroCarouselMobile .slick-dots {
    position: relative;
    bottom: 0;
  }
  .WorkDetailVer2 .slick-dots li {
    width: 4px;
    height: 4px;
    margin-right: 10px;
  }
  .WorkDetailVer2 .slick-dots li button {
    border-radius: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    padding: 0;
  }
  .WorkDetailVer2 .slick-dots li button::before {
    display: none;
  }
  .WorkDetailVer2 .slick-dots li.slick-active {
    background: #000;
  }
  .WorkDetailVer2 .c__download {
    flex-direction: row;
  }
  .WorkDetailVer2 .c__download .pc__download:first-child {
    margin-right: 24px !important;
  }
  .WorkDetailVer2 .carouselImg_works {
    padding: 0;
  }
  .w_Materials,
  .w_m_downloadWrap {
    padding-right: 40px;
    padding-left: 40px;
  }
  .wm_Artist {
    padding-right: 40px;
    padding-left: 40px;
  }
  .wm_Artist .cc_header {
    border-bottom: 0;
    padding-bottom: 0;
  }
  .wm_Artist .info_left .ant-divider {
    margin: 24px 0;
  }
}

@media only screen and (max-width: 743px) {
  .wk_warp_mobile .slick-dots {
    bottom: -20px;
  }
  .w_Materials,
  .w_m_downloadWrap {
    padding-right: 24px;
    padding-left: 24px;
  }
  .wm_Artist {
    padding-left: 24px;
    padding-right: 24px;
  }
  .wm_Artist .title_link {
    display: none;
  }
  .HeroCarouselMobile .slick-dots {
    position: relative;
    bottom: 0;
  }
  .WorkDetailVer2 .c__download {
    flex-direction: column !important;
  }
}

.wk_work-selected-image {
  width: 100%;
  height: 505px;
  overflow: hidden;
}

.wk_work-selected-image div {
  height: 100%;
}

.wk_work-selected-image img {
  height: 100%;
}

.wk_work-slider-outside-div {
  margin-top: 10px;
  overflow: hidden;
  width: 600px;
}

.wk_work-slider-div {
  max-width: 100%;
  max-height: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}

.wk_slider-img-div {
  min-width: 150px;
  height: 100px;
  margin: 0 20px 0 0;
  overflow: hidden;
  text-align: left;
  cursor: pointer;
}

.wk_slider_image {
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  object-fit: scale-down;
}

.wk_artist_container {
  display: flex;
  margin-top: 40px;
}

.wk_artist_container .info_left {
  width: 260px;
  margin-right: 82px;
  text-align: left;
}

.wk_artist_container .info_right {
  flex: 1 1;
  font-family: "MaisonNeue-Medium";
  text-align: left;
  font-size: 18px;
}

.wk_artist_container .info_right p:last-child {
  margin-bottom: 0;
}

.wk_artist_container .info_img {
  width: 260px;
  height: 295px;
  margin-bottom: 24px;
}

.wk_artist_container .info_img img {
  max-width: 100%;
  max-height: 100%;
}

.wk_artist_container .info_name {
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 49px;
  font-family: "MaisonNeue-Demi";
}

.w_Artist .info_link {
  text-align: left;
}

.w_Artist .m_a_t_Artist {
  display: block;
  padding-bottom: 24px;
  margin: 0;
}

.w_Artist .m_a_t_Artist:last-child {
  padding: 0;
}

.w_Artist span.text {
  font-family: "MaisonNeue-Medium";
  font-size: 20px;
  margin-right: 8px;
}

.wk_renderWorkDetail_text {
  flex: 1 1;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.wk_renderWorkDetail_text .ant-divider-horizontal {
  margin: 0 0 24px 0;
}

.wk_workdetail_artist {
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  letter-spacing: normal;
  color: var(--dark);
  margin-bottom: 20px;
}

.wk_workdetail_artist:hover, .wk_workdetail_artist:hover {
  text-decoration: underline;
}

.wk_workdetail_title {
  font-family: "MaisonNeue-Demi";
  font-size: 40px;
  letter-spacing: normal;
  color: var(--dark);
  margin-bottom: 20px;
}

.wk_title_gray {
  font-family: "MaisonNeue-Medium";
  font-size: 14px;
  color: #979797;
  padding-bottom: 12px;
}

.wk_workdetail_edition {
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  color: var(--dark);
  margin-bottom: 20px;
}

.wk_workdetail_container .title {
  font-family: "MaisonNeue-Medium";
  font-size: 14px;
  color: var(--steel-grey);
  margin-bottom: 12px;
}

.wk_workdetail_container .data {
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  color: var(--dark);
  margin-bottom: 20px;
}

.wk_workdetail_container span {
  font-family: "MaisonNeue-Demi";
}

.wk_ButtonWrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  flex-direction: column;
}

.wk_ButtonWrap .wk_button_pc {
  margin-top: 0;
  width: 400px;
  height: 42px;
  border-radius: 0;
  text-align: center;
  height: 48px;
  line-height: 48px;
  border-radius: 0;
  background-color: var(--dark);
  font-family: "MaisonNeue-Demi";
  font-size: 18px;
  letter-spacing: 2px;
  color: var(--white);
}

.wk_ButtonWrap .wk_button_pc:hover {
  cursor: pointer;
}

.wk_downloadIconWrap {
  margin-top: 25px;
}

.wk_downloadIconWrap .c__download {
  flex-direction: row;
}

.wk_downloadIconWrap .pc__download {
  margin-bottom: 0;
}

.wk_downloadIconWrap .pc__download:first-child {
  margin-right: 12px;
}

.wk_downloadIconWrap .download_icon {
  position: relative;
  left: 0;
  top: 0;
  line-height: 1;
  vertical-align: text-top;
}

.wk_info_pc {
  margin-bottom: 120px;
}

.wMobile .ant-divider {
  margin: 40px 0;
}

.wk_warp_mobile {
  display: flex;
  flex-direction: column;
  padding-top: 110px;
}

.w_m_text {
  flex: 1 1;
  text-align: left;
  display: flex;
  flex-direction: column;
  padding-top: 17px;
  margin-bottom: 20px;
}

.w_m_text .s_name {
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  margin-bottom: 20px;
}

.w_m_text .s_title {
  font-family: "MaisonNeue-Demi";
  font-size: 40px;
  max-width: 80%;
  line-height: 1.2;
}

.w_m_text a:active, .w_m_text a:hover {
  color: var(--dark);
}

.w_button_div {
  text-align: center;
  background-color: var(--dark);
  font-family: "MaisonNeue-Demi";
  font-size: 18px;
  letter-spacing: 2px;
  color: var(--white);
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  line-height: 1;
}

.w_button_div:hover {
  cursor: pointer;
}

.w_m_buttonWrap {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.w_m_buttonWrap.fiexd {
  position: absolute;
  right: 0;
  top: 110px;
}

.w_m_downloadWrap {
  position: relative;
  margin-bottom: 24px;
}

.w_m_downloadWrap .download_icon {
  position: relative;
  left: 0;
  top: 0;
  display: inline-block;
  vertical-align: text-top;
}

.wk_collections_warp {
  flex: 1 1;
  padding-top: 24px;
  text-align: left;
  display: flex;
  flex-direction: row;
}

.wm_ctitle {
  margin-bottom: 20px;
  padding-right: 20px;
  min-width: 38%;
}

.wm_ctitle .title_gray {
  font-family: "MaisonNeue-Medium";
  font-size: 14px;
  color: #979797;
  margin-bottom: 12px;
}

.wm_ctitle .workdetail_edition {
  font-family: "MaisonNeue-Demi";
  font-size: 18px;
}

.wm_ctitle .workdetail_edition .icon {
  margin-left: 8px;
}

.wm_ctitle a:active, .wm_ctitle a:hover {
  color: var(--dark);
  text-decoration: underline;
}

.wm_csize {
  margin-bottom: 20px;
  min-width: 26%;
  padding-right: 20px;
}

.wm_csize .title {
  font-family: "MaisonNeue-Medium";
  font-size: 14px;
  margin-bottom: 12px;
}

.wm_csize .data {
  font-family: "MaisonNeue-Demi";
  font-size: 18px;
  margin-bottom: 0px;
}

.wm_ctable {
  margin-bottom: 20px;
  min-width: 200px;
}

.wm_ctable .title {
  font-family: "MaisonNeue-Medium";
  font-size: 14px;
  margin-bottom: 12px;
}

.wm_ctable .data {
  font-family: "MaisonNeue-Medium";
  font-size: 14px;
}

.wm_ctable .data span {
  font-family: "MaisonNeue-Demi";
}

.wm_ctable2 {
  text-align: left;
}

.w_Materials {
  flex: 1 1;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.w_Materials .title {
  font-family: "MaisonNeue-Medium";
  font-size: 14px;
  margin-bottom: 12px;
}

.w_Materials .data {
  font-family: "MaisonNeue-Demi";
  font-size: 18px;
  margin-bottom: 0px;
}

.w_youtubeLink {
  visibility: visible;
}

.wk_warp_mobile .w_button_div {
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

.wm_Video .title {
  font-family: "MaisonNeue-Demi";
  text-align: left;
  font-size: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #efefef;
}

.wm_Artist {
  visibility: visible;
}

.vw__Desktop {
  display: block;
}

.vw__Mobile {
  display: none;
}

.wk_collections_container {
  margin-bottom: 120px;
}

.wk_collections_container .v2Slide {
  height: auto;
}

.wk_collections_container .v2Card {
  float: left;
  width: auto !important;
}

.wk_collections_container .v2Image img {
  left: 0;
  top: 0;
  object-fit: unset;
  -webkit-transform: unset;
          transform: unset;
}

.wk_collections_container.s_border {
  margin-bottom: 0 !important;
  border-bottom: 0 !important;
}

.wk_works_container {
  padding-bottom: 120px;
}

.wk_works_container .cc_header {
  margin-bottom: 40px;
}

.wk_works_container .common_wrapper {
  margin: 0;
}

@media only screen and (max-width: 1023px) {
  .vw__Desktop {
    display: none;
  }
  .vw__Mobile {
    display: block;
  }
  .w_m_text {
    padding-right: 40px;
    padding-left: 40px;
  }
  .wk_collections_warp {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 48px;
  }
  .wm_ctable2 {
    display: none;
  }
  .swDetail .wm_ctable {
    display: none;
  }
  .swDetail .wk_collections_warp .wm_ctable {
    display: block;
  }
  .wk_collections_warp .title {
    color: #979797;
  }
  .w_Materials .title {
    color: #979797;
  }
  .wm_ctable2 .title {
    color: #979797;
  }
  .wm_Video {
    border-bottom: 1px solid #efefef;
    padding-bottom: 40px;
    margin-bottom: 40px;
    padding-right: 40px;
    padding-left: 40px;
  }
  .wm_Video .title {
    border-bottom: 0;
  }
  .wm_Video .video {
    height: 402px;
  }
  .wk_artist_container .info_left {
    margin-right: 40px;
  }
  .wk_collections_container,
  .wk_works_container {
    border-bottom: 1px solid #efefef;
    margin-bottom: 40px;
  }
  .wk_collections_container .common_wrapper,
  .wk_works_container .common_wrapper {
    padding-left: 40px;
    padding-right: 40px;
  }
  .wk_collections_container {
    padding-right: 40px;
    padding-left: 40px;
  }
  .wk_collections_container .work_v2 {
    padding-bottom: 40px;
  }
  .wk_works_container {
    border: 0;
    margin: 0;
    padding-right: 40px;
    padding-left: 40px;
    padding-bottom: 40px;
  }
  .wk_works_container .cc_header {
    border: 0;
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 743px) {
  .w_m_text {
    padding-right: 24px;
    padding-left: 24px;
  }
  .w_m_text .s_title {
    max-width: 100%;
  }
  .wk_collections_warp {
    padding-left: 24px;
    padding-right: 24px;
  }
  .wk_warp_mobile .wm_ctable {
    display: none;
  }
  .wk_warp_mobile .wm_ctable2 {
    display: block;
  }
  .swDetail .wm_ctable {
    display: block;
  }
  .swDetail .wm_ctable2 {
    display: none;
  }
  .wm_ctable2 {
    padding-left: 24px;
    padding-right: 24px;
  }
  .wk_collections_container,
  .wk_works_container {
    padding-left: 24px;
    padding-right: 24px;
  }
  .wk_collections_container .cc_header,
  .wk_works_container .cc_header {
    margin-left: 0;
    margin-right: 0;
  }
  .wk_collections_container .cc_header .title,
  .wk_works_container .cc_header .title {
    margin-bottom: 24px;
  }
  .wk_collections_container .common_wrapper,
  .wk_works_container .common_wrapper {
    padding-left: 24px;
    padding-right: 24px;
  }
  .wm_Video {
    padding-left: 24px;
    padding-right: 24px;
  }
  .wm_Video .title {
    border: 0;
  }
  .wm_Video .video {
    height: 222px;
  }
  .wk_artist_container {
    flex-direction: column;
  }
  .wk_artist_container .info_left {
    width: 100%;
    margin-right: 0;
  }
  .wk_artist_container .info_img {
    width: 100%;
    height: auto;
  }
  .wk_artist_container .info_name {
    margin-bottom: 24px;
  }
  .wk_artist_container .p__show {
    display: none;
  }
  .wm_Artist .info_img {
    height: auto;
  }
  .wm_Artist .info_link span.text {
    font-size: 16px;
  }
  .wm_Artist .ant-divider {
    display: block;
    margin: 24px 0;
  }
  .wm_Artist .m__show {
    display: block;
  }
}

@media only screen and (min-width: 1024px) {
  .wk_work-slider-outside-div,
  .wk_slick-slider-customize {
    width: 544px;
  }
  .wk_warp_pc {
    padding-left: 60px;
    padding-right: 60px;
    margin-bottom: 120px;
  }
  .w_Artist {
    padding-left: 60px;
    padding-right: 60px;
  }
  .wk_collections_container .cc_header,
  .wk_works_container .cc_header {
    margin-left: 60px;
    margin-right: 60px;
  }
  .wk_collections_container .common_wrapper,
  .wk_works_container .common_wrapper {
    padding-left: 60px;
    padding-right: 60px;
  }
  .wk_ButtonWrap .wk_button_pc {
    width: 320px !important;
  }
  .wm_Video {
    padding-left: 60px;
    padding-right: 60px;
    margin-bottom: 120px;
  }
  .wm_Video .list_video {
    margin-top: 40px;
    height: 508px;
  }
}

@media only screen and (min-width: 1280px) {
  .wk_warp_pc {
    padding-left: 140px;
    padding-right: 140px;
    margin-bottom: 160px;
  }
  .wk_collections_container .cc_header {
    margin-left: 140px;
    margin-right: 140px;
  }
  .wk_collections_container .worksItems_wrapper {
    padding-left: 140px;
    padding-right: 140px;
  }
  .w_Artist {
    padding-left: 140px;
    padding-right: 140px;
  }
  .wk_works_container .cc_header {
    margin-left: 140px;
    margin-right: 140px;
  }
  .wk_works_container .common_wrapper {
    padding-left: 140px;
    padding-right: 140px;
  }
  .wm_Video {
    padding-left: 140px;
    padding-right: 140px;
  }
  .wm_Video .list_video {
    height: 702px;
  }
}

@media only screen and (min-width: 1440px) {
  .wk_work-slider-outside-div,
  .wk_slick-slider-customize {
    width: 720px;
  }
  .wk_slick-slider-outside-div {
    width: 720px;
  }
  .wk_slick-selected-image {
    width: 720px;
  }
}

.list_video {
  overflow-y: hidden;
  overflow-x: auto;
}

.list_video .video {
  width: 100%;
  height: 100%;
}

.list_video .video iframe {
  height: 100% !important;
}

.list_video.more {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}

.list_video.more .video {
  width: 80%;
  min-width: 80%;
  margin-right: 4%;
}

.artistDetailContainer {
  width: 100%;
  margin-top: 88px;
}

/***************************************** ArtistInfo ***************************************************************/
.artistDetailWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 136px;
}

.artistImage {
  width: 26%;
  margin: 24px auto;
}

.artistImage img {
  width: 100%;
  height: auto;
}

.artistDetailInfo_content {
  width: 34%;
  margin: 40px auto;
}

.artistDetailInfo_content .title {
  font-family: MaisonNeue-Bold;
  font-size: 48px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: var(--dark);
}

.artistDetailInfo_content .content {
  font-family: MaisonNeue-Book;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--dark);
  margin: 32px 0 24px 0;
  text-align: left;
}

.artistVideo {
  margin-top: 102px;
}

.artistBtn {
  margin-bottom: 96px;
}

.artistBtn .bdgBJg.bdgBJg {
  width: 280px;
}

.artistBtn .JhMeT {
  display: none;
}

/************************************************* collection *************************************************/
.ArtistCollection_Container .Clip {
  display: flex !important;
}

.ArtistCollection_Container .slick-prev,
.ArtistCollection_Container .slick-next {
  z-index: 1 !important;
}

.ArtistCollection_Container .slick-arrow .slick-prev,
.ArtistCollection_Container .slick-arrow .slick-next {
  z-index: 1 !important;
  display: block !important;
  position: absolute;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  color: transparent;
  border: none;
  outline: none;
}

.ArtistCollection_Container .slick-prev:before {
  content: url("/images/miscellaneous/icon-chevron-left.svg") !important;
}

.ArtistCollection_Container .slick-next:before {
  content: url("/images/miscellaneous/icon-chevron-right.svg") !important;
}

.M_top {
  height: 100px;
}

.artist_content {
  padding: 0 140px;
  padding-top: 140px;
  width: 100%;
  position: relative;
  overflow: auto;
}

.D_title {
  font-family: "MaisonNeue-Demi";
  font-size: 64px;
  line-height: 1.2;
  margin-bottom: 25px;
}

.D_download {
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  line-height: 1;
  margin-bottom: 40px;
}

.D_download .anticon {
  vertical-align: text-top;
  margin-right: 8px;
}

.D_download .D_cv {
  margin-bottom: 20px;
  cursor: pointer;
}

.D_download .D_images {
  margin-bottom: 0px;
  cursor: pointer;
}

.L_nav {
  min-width: 426px;
  max-width: 426px;
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  text-align: left;
  position: absolute;
  padding-top: 140px;
  top: 0;
}

.L_nav.fixed {
  padding-top: 140px;
  position: fixed;
  top: 0;
}

#fixedNav2.L_nav {
  display: none !important;
}

.L_ul {
  margin: 0;
  padding: 0;
}

.L_ul li {
  font-family: "MaisonNeue-Demi";
  line-height: 1;
  list-style: none;
  font-size: 24px;
  text-align: left;
  padding-bottom: 24px;
  color: #979797;
  cursor: pointer;
}

.L_ul li.active {
  color: var(--dark);
}

.R_content {
  padding-left: 73px;
  float: left;
}

.artDetailWrap {
  width: 100%;
  font-family: "MaisonNeue-Medium";
}

.artImage {
  max-width: 100%;
}

.artImage img {
  max-width: 100%;
}

.artContent {
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  text-align: left;
}

.artContent p:last-child {
  margin-bottom: 0;
}

.artVideo .list_video {
  height: 400px;
}

.icon_more_box {
  padding-top: 8px;
  text-align: right;
  font-size: 20px;
}

.icon_more_box .anticon {
  position: relative;
  top: -3px;
}

.icon_more_box span {
  margin-right: 8px;
}

.icon_more_box a:active, .icon_more_box a:hover {
  color: var(--dark);
}

.art_column_item {
  margin-top: 120px;
}

.art_column_item .common_wrapper {
  margin: 0;
}

.art_column_item.artSelectedPress {
  margin-bottom: 140px;
}

.Title_iphone {
  font-family: "MaisonNeue-Demi";
  font-size: 24px;
  margin-bottom: 36px;
}

.T_line {
  margin-bottom: 40px;
  border: 1px solid #efefef;
}

.iphone_colums {
  padding-bottom: 40px !important;
}

.artist_content_iphone {
  display: none;
  padding-top: 108px;
  text-align: left;
}

.artist_content_iphone .D_title {
  font-size: 40px;
}

.artist_content_iphone .artImage {
  margin-bottom: 48px;
}

.artist_content_iphone .D_download {
  margin-bottom: 25px;
}

.artist_content_iphone .D_cv {
  margin-bottom: 12px;
}

/**************************************************** media query **************************************************/
@media only screen and (max-width: 1278px) {
  .artist_content {
    padding: 0 60px;
    padding-top: 140px;
  }
}

@media only screen and (max-width: 1023px) {
  .artist_content {
    padding: 0 40px;
    padding-top: 100px;
    display: none;
  }
  .R_content {
    padding-left: 40px;
  }
  .iphone_colums {
    padding: 0 40px;
  }
  .artist_content_iphone {
    display: block;
  }
  .artist_content_iphone .cc_header {
    border: 0;
  }
}

@media only screen and (max-width: 744px) {
  .artist_content_iphone {
    display: block;
  }
  .artist_content {
    display: none !important;
    padding: 0 24px;
    padding-top: 100px;
    display: none;
  }
  .iphone_colums {
    padding: 0 24px;
  }
  .artVideo .list_video {
    height: 222px;
  }
}

@media only screen and (max-width: 767px) {
  .ArtistCollection_Container {
    margin: 0 16px 68px 16px;
  }
  .artistImage {
    width: 100%;
    margin: 0 auto;
  }
  .artistDetailInfo_content {
    margin: 24px 16px 0 16px;
    width: 91.5%;
  }
  .artistDetailInfo_content .title {
    font-size: 24px;
    line-height: 1.17;
  }
  .artistDetailInfo_content .content {
    font-size: 14px;
    line-height: 1.43;
  }
  .artistVideo iframe {
    height: 229px;
  }
  .artistDetailWrap {
    margin-bottom: 72px;
  }
  .artistDetailContainer {
    margin-top: 56px;
  }
}

@media only screen and (min-width: 768px) {
  .ArtistCollection_Container {
    margin: 96px 24px;
  }
  .artistImage {
    width: 46.8%;
  }
  .artistDetailInfo_content {
    width: 78.2%;
  }
  .ArtistCollection_Container .slick-prev {
    left: 87% !important;
    top: 86% !important;
  }
  .ArtistCollection_Container .slick-next {
    left: 93% !important;
    top: 86% !important;
  }
  .artistDetailInfo_content .title {
    font-size: 36px;
  }
}

@media only screen and (min-width: 1024px) {
  .ArtistCollection_Container {
    margin: 96px 40px;
  }
  .artistImage {
    width: 42.96%;
  }
  .artistDetailInfo_content {
    width: 58.6%;
  }
  .ArtistCollection_Container .slick-prev {
    left: 91% !important;
    top: 89% !important;
  }
  .ArtistCollection_Container .slick-next {
    left: 96% !important;
    top: 89% !important;
  }
  .artVideo .list_video {
    height: 266px;
  }
  .R_content {
    padding-left: 40px;
    margin-left: 360px;
  }
  .L_nav {
    min-width: 360px;
    max-width: 360px;
  }
  .D_title {
    font-size: 40px;
  }
}

@media only screen and (min-width: 1280px) {
  .artVideo .list_video {
    height: 400px;
  }
  .R_content {
    padding-left: 73px;
    margin-left: 426px;
  }
  .L_nav {
    min-width: 426px;
    max-width: 426px;
  }
  .D_title {
    font-size: 64px;
  }
}

@media only screen and (min-width: 1440px) {
  .ArtistCollection_Container {
    margin: 96px 41px;
  }
  .ArtistCollection_Container .slick-prev {
    left: 93% !important;
    top: 90% !important;
  }
  .ArtistCollection_Container .slick-next {
    left: 96% !important;
    top: 90% !important;
  }
  .artistImage {
    width: 34.65%;
  }
  .artistDetailInfo_content {
    width: 41.67%;
  }
}

@media only screen and (min-width: 1920px) {
  .ArtistCollection_Container {
    margin: 96px 159px;
  }
  .ArtistCollection_Container .slick-prev {
    left: 92.3% !important;
    top: 91.1% !important;
  }
  .ArtistCollection_Container .slick-next {
    left: 96% !important;
    top: 91.2% !important;
  }
  .artistImage {
    width: 26%;
  }
  .artistDetailInfo_content {
    width: 31.25%;
  }
}

.carouselImg_col {
  padding: 0 12px;
}
.col_heroCarousel button {
  border-radius: 50%;
  height: 48px;
  width: 48px;
  border: solid 1px var(--pale-blue);
  background-color: var(--white);
}

.col_heroCarousel button.slick-arrow.slick-next {
  background-color: white;
}
.col_heroCarousel button.slick-arrow.slick-prev {
  background-color: white;
}

.col_heroCarousel .slick-prev {
  left: 40px;
  top: 320px;
  z-index: 1;
}
.col_heroCarousel .slick-next {
  right: 40px;
  top: 320px;
}

.col_heroCarousel .slick-prev:before {
  content: url("/images/miscellaneous/icon-chevron-left.svg") !important;
}

.col_heroCarousel .slick-next:before {
  content: url("/images/miscellaneous/icon-chevron-right.svg") !important;
}
.col_heroCarousel .slick-next:hover:focus:active {
  background-color: white;
}
.col_heroCarousel .slick-prev:hover:focus:active {
  background-color: white;
}

.carouselImg_col_small img{
  object-fit: scale-down;
}

/**************************************************** media query ******************************************************/
@media only screen and (max-width: 767px) {
  .carouselWrap .carousel {
    height: 249px;
  }
  .carouselImg_col img {
    min-height: 249px;
  }
  .col_heroCarousel .slick-arrow {
    display: none !important;
  }
  .col_heroCarousel .slick-dots {
    bottom: -20px;
  }
  .col_heroCarousel .carouselImg_col {
    padding: 0px;
  }
  .col_heroCarousel .ewOoqd {
    margin-top: 102px;
  }
}
@media only screen and (min-width: 768px) {
  .carouselImg_col img {
    height: 360px;
  }
}
@media only screen and (min-width: 1024px) {
  .carouselImg_col img {
    height: 450px;
  }
  .col_heroCarousel .slick-prev {
    top: 250px;
  }
  .col_heroCarousel .slick-next {
    top: 250px;
  }
}
@media only screen and (min-width: 1440px) {
  .carouselImg_col img {
    height: 500px;
  }
}
@media only screen and (min-width: 1920px) {
  .carouselImg_col img {
    height: 640px;
  }
  .col_heroCarousel .slick-prev {
    top: 300px;
  }
  .col_heroCarousel .slick-next {
    top: 300px;
  }
}

.info_link a:active, .info_link a:hover {
  color: var(--dark);
}

.info_link .icon {
  display: inline-block;
  position: relative;
  top: -2px;
}

.collectionDetailWrap {
  display: flex;
  flex-direction: column;
}

.collectionwork_wrap {
  margin: 96px 40px;
  text-align: left;
}

/***************************************** collection Info **************************************/
.collectionInfoWrap {
  width: 31.25%;
  margin: 62px auto;
}

.collectionInfoWrap .collectionInfoWrap_title {
  width: 90%;
  margin: 0 auto;
}

.collectionInfoWrap_title .artistName {
  font-family: MaisonNeue-Book;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--dark);
}

.collectionInfoWrap_title .collectionName {
  font-family: MaisonNeue-Bold;
  font-size: 48px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: var(--dark);
  margin-top: 16px;
}

.collectionInfoWrap_title .year {
  font-family: MaisonNeue-Book;
  font-size: 21px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: var(--dark);
  margin-top: 8px;
}

.collectionInfoWrap_content {
  text-align: left;
  font-family: MaisonNeue-Book;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--dark);
  margin-top: 24px;
}

.collectionInfoWrap_content .title {
  font-size: 12px;
  color: var(--steel-grey);
}

.collectionInfoWrap_content .content {
  margin-top: 8px;
}

.collectionInfoWrap_content .description {
  margin-top: 24px;
}

.collectionInfoWrap .downloadIcon {
  margin-left: 0;
  margin-right: 34px;
}

.collectionInfoWrap .video {
  margin-top: 96px;
}

/* button */
.collectionBtn {
  margin-top: 96px;
  margin-bottom: 96px;
}

.collectionBtn .bdgBJg.bdgBJg {
  width: 330px;
}

.collectionBtn .JhMeT {
  display: none;
}

.col_heroCarousel .slick-list {
  margin-top: 0;
}

.col_heroCarousel .slick-dots {
  display: none !important;
}

.col_heroCarousel .slick-arrow {
  height: 65px;
  width: 40px;
  border-radius: 0;
  border: none;
  background-color: rgba(255, 255, 255, 0.64) !important;
}

.col_heroCarousel button.slick-arrow.slick-next {
  background-color: white;
}

.col_heroCarousel button.slick-arrow.slick-prev {
  background-color: white;
}

.col_heroCarousel .slick-prev {
  left: 0;
  top: 250px;
  z-index: 1;
}

.col_heroCarousel .slick-next {
  right: 0;
  top: 250px;
}

.col_heroCarousel .slick-prev:before {
  content: url("/images/miscellaneous/icon-chevron-left.svg") !important;
}

.col_heroCarousel .slick-next:before {
  content: url("/images/miscellaneous/icon-chevron-right.svg") !important;
}

.v__Desktop {
  display: block;
}

.v__Mobile {
  display: none;
}

.c_DetailWrap {
  visibility: visible;
}

.c_DetailWrap_pc {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 140px;
  margin: 0;
  margin-bottom: 40px;
  text-align: left;
}

.c_DetailWrap_pc .view_content {
  margin-bottom: 12px;
  padding-bottom: 0;
}

.c_DetailWrap_pc .view_content:first-child .c__content {
  font-size: 14px;
  color: #979797;
}

.c_DetailWrap_pc .view_content .c__content {
  line-height: 1;
  position: relative;
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  color: var(--dark);
}

.c_DetailWrap_pc .view_content .c__content .text {
  padding-left: 0;
}

.c_DetailWrap_pc .pc_subTitle {
  font-family: "MaisonNeue-Medium";
  text-align: left;
  margin-bottom: 20px;
  color: var(--dark);
  font-size: 18px;
}

.c_DetailWrap_pc .pc_title {
  font-family: "MaisonNeue-Demi";
  text-align: left;
  font-size: 40px;
  padding-bottom: 0;
}

.c_DetailWrap_pc .c_d_name_pc {
  width: 100%;
  max-width: 36%;
}

.c_DetailWrap_pc .pc_Materials {
  padding-right: 10px;
}

.c_DetailWrap_mobile {
  padding-top: 120px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  margin: 0;
}

.c_d_author {
  font-family: "MaisonNeue-Medium";
  margin-bottom: 20px;
  font-size: 18px;
}

.c_d_title {
  font-family: "MaisonNeue-Demi";
  text-align: left;
  margin-bottom: 24px;
  font-size: 40px;
  line-height: 1.2;
}

.c_Materials {
  font-family: "MaisonNeue-Medium";
  font-size: 14px;
  line-height: 14px;
  color: #979797;
  margin-bottom: 12px;
}

.c_Materials_content {
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  color: var(--dark);
  margin-bottom: 25px;
}

.c_downloadIconWrap {
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  margin-left: 0;
}

.c_downloadIconWrap .c_pdf {
  padding-bottom: 12px;
}

.c_downloadIconWrap .c_images {
  padding-bottom: 0;
}

.c_downloadIconWrap .download_icon {
  vertical-align: text-bottom;
}

.c_downloadIconWrap .download_text {
  vertical-align: middle;
  padding-left: 8px;
}

.collections_overview {
  text-align: left;
}

.collections_overview h1 {
  font-family: "MaisonNeue-Demi";
  font-size: 24px;
  padding-bottom: 24px;
  margin-bottom: 0;
}

.collections_overview .overview_desc {
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
}

.collections_video .title {
  font-family: "MaisonNeue-Demi";
  font-size: 24px;
  line-height: 1;
  text-align: left;
  border-bottom: 1px solid #efefef;
}

.collections_artist {
  padding-top: 40px;
  padding-left: 140px;
  padding-right: 140px;
}

.collections_artist .artist_view_info {
  display: flex;
  margin-top: 40px;
}

.collections_artist .info_left {
  width: 260px;
  margin-right: 82px;
  text-align: left;
}

.collections_artist .info_right {
  flex: 1 1;
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  text-align: left;
}

.collections_artist .info_img {
  width: 260px;
  height: 295px;
  margin-bottom: 24px;
}

.collections_artist .info_img img {
  max-width: 100%;
  max-height: 100%;
}

.collections_artist .info_name {
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 49px;
  font-family: "MaisonNeue-Demi";
}

.collections_artist .info_link a {
  display: block;
  padding-bottom: 24px;
  margin: 0;
}

.collections_artist .info_link span.text {
  font-family: "MaisonNeue-Medium";
  font-size: 20px;
  margin-right: 8px;
}

.collections_artist .m_t {
  font-family: "MaisonNeue-Demi";
  font-size: 24px;
  text-align: left;
  margin-bottom: 24px;
}

.collections_works_container {
  visibility: visible;
  margin-top: 0px;
}

.collections_works_container .cc_header {
  margin-bottom: 40px;
}

.collections_works_container .common_wrapper {
  margin: 0;
}

.collections_works_container .v2Card {
  float: left;
  width: auto !important;
}

.collections_works_container .v2Image img {
  left: 0;
  top: 0;
  object-fit: unset;
  -webkit-transform: unset;
          transform: unset;
}

.collections_other_container {
  visibility: visible;
}

.collections_other_container .cc_header {
  margin-bottom: 40px;
}

.collections_other_container .common_wrapper {
  margin: 0;
}

.artist_info_pc {
  margin-top: 0px;
  padding-top: 0;
}

.artist_info_mobile {
  margin-top: 0px;
  padding-top: 0;
}

.collections_divider {
  height: 160px;
}

.lh100 {
  height: 100px;
}

.c__video {
  visibility: visible;
}

/********************************************* media query *****************************************************************************/
@media only screen and (max-width: 767px) {
  .collectionInfoWrap {
    margin: 44px auto;
    width: 91.47%;
  }
  .collectionInfoWrap_title .artistName {
    font-size: 14px;
    line-height: 1.43;
  }
  .collectionInfoWrap_title .collectionName {
    font-size: 24px;
    line-height: 1.17;
    margin-top: 8px;
  }
  .collectionInfoWrap_title .year {
    font-size: 18px;
    line-height: 1.67;
  }
  .collectionInfoWrap_content {
    font-size: 14px;
    line-height: 1.43;
  }
  .collectionInfoWrap_content .description {
    margin-top: 16px;
  }
  .collectionInfoWrap .video {
    margin-top: 58px;
  }
  .collectionInfoWrap .video iframe {
    height: 229px;
  }
  .downloadIconWrap {
    padding-top: 0px;
  }
  .collectionBtn {
    margin-top: 65px;
    margin-bottom: 65px;
  }
}

@media only screen and (min-width: 768px) {
  .collectionInfoWrap {
    width: 78.2%;
  }
  .collectionInfoWrap_title .collectionName {
    font-size: 36px;
    line-height: 1.11;
    margin-top: 13px;
  }
}

@media only screen and (min-width: 1024px) {
  .collectionInfoWrap {
    width: 58.6%;
  }
}

@media only screen and (min-width: 744px) and (max-width: 1023px) {
  .c_DetailWrap_pc {
    padding-left: 40px;
    padding-right: 40px;
  }
  .collections_overview {
    padding-left: 40px;
    padding-right: 40px;
  }
  .collections_video {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media only screen and (max-width: 1023px) {
  .col_heroCarousel .slick-arrow {
    display: none !important;
  }
  .col_heroCarousel .slick-dots {
    display: block !important;
    position: relative;
    bottom: 0;
  }
  .col_heroCarousel .slick-dots li {
    width: 4px;
    height: 4px;
    background: #000;
    opacity: 0.3;
    margin-right: 18px;
  }
  .col_heroCarousel .slick-dots li button {
    width: 4px;
    height: 4px;
    padding: 0;
  }
  .col_heroCarousel .slick-dots li button::before {
    display: none;
  }
  .col_heroCarousel .slick-dots li.slick-active {
    background: #000;
    opacity: 1;
  }
  .col_heroCarousel .slick-dots button {
    background-color: rgba(0, 0, 0, 0.2) !important;
  }
  .col_heroCarousel .slick-dots .slick-active button {
    background-color: #000 !important;
  }
  .c_DetailWrap_mobile {
    display: block;
    padding-left: 40px;
    padding-right: 40px;
  }
  .collections_overview {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 50px;
    padding-bottom: 20px;
    margin-bottom: 0;
    border-bottom: 1px solid #e8e8e8;
  }
  .collections_video {
    position: relative;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 0;
    padding-bottom: 40px;
    border-bottom: 1px solid #e8e8e8;
  }
  .collections_video .ant-divider {
    display: none;
  }
  .collections_video .title {
    padding-bottom: 24px;
  }
  .collections_artist {
    padding-left: 40px;
    padding-right: 40px;
    border-bottom: 1px solid #e8e8e8;
  }
  .collections_artist .info_name {
    margin-bottom: 24px;
  }
  .collections_artist .info_link {
    text-align: left;
  }
  .collections_artist .info_link a {
    font-size: 16px;
  }
  .collections_artist .info_left {
    width: 100%;
    margin-right: 0;
  }
  .collections_artist .info_left .info_img {
    width: 100%;
    height: 100%;
  }
  .collections_artist .artist_view_info {
    margin-top: 0;
    flex-direction: column;
  }
  .collections_artist .artist_view_info .ant-divider {
    margin-top: 0;
  }
  .collections_works_container {
    border-bottom: 1px solid #efefef;
  }
  .collections_works_container .cc_header {
    margin-left: 40px;
    margin-right: 40px;
  }
  .collections_works_container .common_wrapper {
    padding-left: 40px;
    padding-right: 40px;
  }
  .collections_works_container .work_v2 {
    margin-left: 40px;
    margin-right: 40px;
  }
  .collections_other_container {
    border-bottom: 0px solid #efefef;
  }
  .collections_other_container .cc_header {
    margin-left: 40px;
    margin-right: 40px;
    border: 0;
  }
  .collections_other_container .common_wrapper {
    padding-left: 40px;
    padding-right: 40px;
  }
  .collections_divider {
    height: 40px;
  }
  .lh100 {
    height: 40px;
  }
  .c__video .list_video {
    height: 402px;
  }
}

@media only screen and (max-width: 743px) {
  .c_DetailWrap_pc {
    padding-left: 24px;
    padding-right: 24px;
  }
  .c_DetailWrap_mobile {
    padding-left: 24px;
    padding-right: 24px;
  }
  .collections_overview {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 10px;
  }
  .collections_video {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .collections_artist {
    padding-left: 24px;
    padding-right: 24px;
  }
  .collections_artist .info_link span.text {
    font-size: 16px;
  }
  .collections_works_container .cc_header {
    margin-left: 24px;
    margin-right: 24px;
  }
  .collections_works_container .common_wrapper {
    padding-left: 24px;
    padding-right: 24px;
  }
  .collections_works_container .work_v2 {
    margin: 0 24px;
  }
  .collections_other_container .cc_header {
    margin-left: 24px;
    margin-right: 24px;
  }
  .collections_other_container .cc_header .title {
    margin-bottom: 24px;
  }
  .collections_other_container .common_wrapper {
    padding-left: 24px;
    padding-right: 24px;
  }
  .c__video .list_video {
    height: 222px;
  }
}

@media only screen and (min-width: 1024px) {
  .c_DetailWrap_pc {
    padding-left: 60px;
    padding-right: 60px;
  }
  .c_DetailWrap_pc .group:nth-child(3) {
    min-width: 185px;
  }
  .collections_overview {
    padding-left: 60px;
    padding-right: 60px;
  }
  .collections_video {
    padding-left: 60px;
    padding-right: 60px;
  }
  .collections_video .title {
    padding-bottom: 24px;
    margin-bottom: 40px;
  }
  .collections_artist {
    padding-left: 60px;
    padding-right: 60px;
  }
  .collections_works_container .cc_header {
    margin-left: 60px;
    margin-right: 60px;
  }
  .collections_works_container .common_wrapper {
    padding-left: 60px;
    padding-right: 60px;
  }
  .collections_other_container .cc_header {
    margin-left: 60px;
    margin-right: 60px;
  }
  .collections_other_container .common_wrapper {
    padding-left: 60px;
    padding-right: 60px;
  }
  .collections_divider {
    height: 120px;
  }
  .lh100 {
    height: 80px;
  }
  .c__video .list_video {
    height: 550px;
  }
}

@media only screen and (min-width: 1280px) {
  .c_DetailWrap_pc {
    padding-left: 140px;
    padding-right: 140px;
  }
  .collections_overview {
    padding-left: 140px;
    padding-right: 140px;
  }
  .collections_video {
    padding-left: 140px;
    padding-right: 140px;
  }
  .collections_artist {
    padding-left: 140px;
    padding-right: 140px;
  }
  .collections_works_container .cc_header {
    margin-left: 140px;
    margin-right: 140px;
  }
  .collections_works_container .common_wrapper {
    padding-left: 140px;
    padding-right: 140px;
  }
  .collections_other_container .cc_header {
    margin-left: 140px;
    margin-right: 140px;
  }
  .collections_other_container .common_wrapper {
    padding-left: 140px;
    padding-right: 140px;
  }
  .collections_divider {
    height: 160px;
  }
  .lh100 {
    height: 100px;
  }
  .c__video .list_video {
    height: 700px;
  }
}

@media only screen and (max-width: 1023px) {
  .v__Desktop {
    display: none;
  }
  .v__Mobile {
    display: block;
  }
}

.pb100 {
  padding-top: 100px;
}

.mb100 {
  margin-bottom: 100px;
}

.carouselImgPc {
  vertical-align: middle;
  padding: 0 12px;
}

#fixedNav2 {
  display: none;
  min-height: 300px;
}

.video_link {
  position: relative;
  z-index: 5;
}

.VerWrap2 .exhibitionDetailWrap {
  text-align: left;
}

.VerWrap2 .exhibitionDetailInfo_title {
  font-family: "MaisonNeue-Demi";
  text-align: left;
  margin-bottom: 24px;
}

.VerWrap2 .exhibitionDetailInfo_sub_title {
  font-family: "MaisonNeue-Medium";
  margin-bottom: 20px;
  color: #979797;
  font-size: 18px;
}

.VerWrap2 .exhibitionDetailInfo_content {
  margin-bottom: 12px;
}

.VerWrap2 .exhibitionDetailInfo_content .content {
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  color: var(--dark);
}

.VerWrap2 .exhibitionDetailInfo_content .content .icon {
  margin-right: 10px;
}

.VerWrap2 .last_content {
  margin-bottom: 24px;
}

.VerWrap2 .smallSlidercard {
  margin-right: 40px;
}

.VerWrap2 .heroCarousel .slick-list {
  margin-top: 0;
}

.VerWrap2 .featurenewwork_container {
  margin: 0 20px;
}

.detail_link_group {
  display: flex;
  flex-direction: row;
  align-content: space-around;
  justify-content: space-between;
  padding: 24px 40px 64px 40px;
  font-size: 20px;
}

.detail_link_group .left_item .icon {
  margin-right: 10px;
}

.detail_link_group .right_item .icon {
  margin-left: 10px;
}

.detail_link_group a:active, .detail_link_group a:hover {
  color: var(--dark);
}

.mobile .downloadIconWrap {
  margin-left: 0;
  flex-direction: column;
}

.mobile .downloadIconWrap .downloadIcon,
.mobile .downloadIconWrap .downloadIcon-disable {
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  margin-left: 0;
  padding-bottom: 13px;
}

.mobile .downloadIconWrap .downloadIcon:last-child,
.mobile .downloadIconWrap .downloadIcon-disable:last-child {
  padding-bottom: 0;
}

.mobile .VrDialog {
  position: fixed;
  right: 0;
  top: 340px;
  z-index: 100;
}

.c_Description {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 40px;
  padding-bottom: 16px;
  text-align: left;
}

.c_Description .s_Overview {
  font-family: "MaisonNeue-Demi";
  margin-bottom: 24px;
  font-size: 24px;
  color: var(--dark);
}

.c_Description .s_Description {
  font-family: "MaisonNeue-Medium";
  margin-bottom: 12px;
  padding-bottom: 0;
}

.c_Description .s_Description p {
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
}

.c__slide {
  height: inherit;
}

.c__slide .smallSlider_image {
  position: relative;
  overflow: hidden;
}

.c__slide img {
  position: relative;
  margin: 0;
  max-width: 100%;
}

.presses_slider {
  height: inherit;
}

.presses_slider .horizontalSlide {
  height: inherit;
}

.presses_slider .smallSlider_image {
  width: 395px;
  height: 260px;
}

.presses_slider .c__title {
  font-family: "MaisonNeue-Demi";
  font-weight: normal;
  text-align: left;
  padding: 0;
  margin-top: 16px;
  margin-bottom: 8px;
  font-size: 16px;
  color: #979797;
}

.c__content {
  line-height: 1;
  position: relative;
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  color: var(--dark);
}

.c__content .text {
  padding-left: 8px;
}

.c__content .icon {
  position: absolute;
  top: -2px;
  left: -16px;
}

.c__download {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  padding-top: 0;
  text-align: left;
}

.m__download {
  position: relative;
}

.m__download .download_icon {
  vertical-align: text-bottom;
}

.m__download .download_text {
  vertical-align: middle;
  padding-left: 8px;
}

.pc_subTitle {
  font-family: "MaisonNeue-Medium";
  text-align: left;
  margin-bottom: 20px;
  color: #979797;
  font-size: 18px;
}

.pc_title {
  font-family: "MaisonNeue-Demi";
  text-align: left;
  font-size: 40px;
  max-width: 460px;
  padding-bottom: 0;
}

.view_content {
  margin-bottom: 12px;
  padding-bottom: 0;
}

.view_content:last-child {
  margin-bottom: 0;
}

.pc__download {
  line-height: 1;
  position: relative;
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  margin-bottom: 12px;
  margin-left: 0;
  cursor: pointer;
}

.pc__download:last-child {
  margin-bottom: 0;
}

#lockPage {
  width: 300px;
}

.spacer.s0 {
  min-height: 1px;
}

.work_module_tween {
  position: relative;
  padding-left: 60px;
  padding-right: 60px;
}

.work_module_tween .nav_btn {
  width: 400px;
}

.work_module_tween .nav_btn li {
  font-size: 24px;
}

.work_module_tween .scrollmagic-pin-spacer {
  float: left;
}

.work_module_tween .rights {
  padding-left: 300px;
  text-align: left;
}

.work_module_tween .rights .content {
  font-family: "MaisonNeue-Medium";
}

.pc_navTitle {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: transparent;
  border-bottom: 0;
  opacity: 0;
}

.pc_navTitle.active {
  opacity: 1;
}

.VrPopupPc {
  display: none;
  position: fixed;
  z-index: 10;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.8);
  padding: 72px;
}

.VrPopupPc .popup_c {
  width: 90%;
  height: 78vh;
  padding: 10px;
  margin: auto;
  color: #fff;
  border-radius: 2px;
  position: relative;
  overflow: hidden;
}

.VrPopupPc .closeBtn {
  margin-top: 25px;
  cursor: pointer;
}

.VrPopupPc iframe {
  border: 0;
  border-radius: 2px;
  position: relative;
  overflow: hidden;
}

.VrDialog {
  width: 549px;
  height: 176px;
  position: absolute;
  right: 0;
  bottom: 0px;
  z-index: 8;
  padding: 16px;
  background: rgba(0, 0, 0, 0.5);
  border-top-left-radius: 2px;
}

.VrDialog .vr_c {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.VrDialog .vr_c .vr_left {
  cursor: pointer;
  padding: 40px 0;
  width: 200px;
  margin-right: 40px;
  border-radius: 2px;
  background: url(/images/vr.png) no-repeat;
  background-size: 100% 100%;
}

.VrDialog .vr_c .vr_right {
  padding: 20px 0;
  font-size: 16px;
  color: #fff;
  text-align: left;
}

.VrDialog .vr_c .vr_right h1 {
  font-size: 24px;
  color: #fff;
}

.sm_VrDialog {
  position: absolute;
  right: 0;
  z-index: 6;
  top: 300px;
}

.artists_biography {
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  color: var(--dark);
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.pc_t_title {
  font-family: "MaisonNeue-Demi";
  text-align: left;
  font-size: 40px;
  max-width: 460px;
  padding-bottom: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: transparent;
  border-bottom: 0;
  opacity: 1;
}

.pc_t_title.active {
  opacity: 1;
}

.pc .heroCarousel {
  position: relative;
  overflow: hidden;
}

.pc .heroCarousel .slick-arrow {
  height: 65px;
  width: 40px;
  border-radius: 0;
  border: none;
  background-color: rgba(255, 255, 255, 0.64) !important;
}

.pc .heroCarousel .slick-next {
  right: 0;
  z-index: 1;
}

.pc .heroCarousel .slick-prev {
  left: 0;
  z-index: 1;
}

.pc .heroCarousel .carouselImg {
  padding: 0;
}

.pc .exhibitionDetailWrap {
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 40px;
}

.pc .exhibitionDetailWrap .group .exhibitionDetailInfo_content:last-child {
  padding-bottom: 0;
}

.pc .exhibitionDetailWrap .downloadIconWrap {
  padding-top: 0;
  font-size: 18px;
}

.pc .exhibitionDetailWrap .exhibitionDetailInfo_title {
  font-size: 40px;
  max-width: 460px;
  padding-bottom: 0;
}

.work_module_tween .right_content {
  text-align: left;
  line-height: 28.8px;
}

.work_module_tween .right_content .content {
  font-size: 18px;
}

.work_module_tween .right_content .all_group {
  padding-bottom: 40px;
}

.work_module_tween .right_content .img_group {
  position: relative;
  line-height: 1;
}

.work_module_tween .right_content .name_group {
  padding-top: 24px;
  padding-bottom: 20px;
  font-size: 24px;
  font-weight: 700;
}

.work_module_tween .right_content .name_group p {
  margin: 0;
}

.work_module_tween .right_content .all_group_res {
  padding-bottom: 32px;
}

.work_module_tween .right_content .all_group_res .name_group {
  padding: 0;
  line-height: 28px;
  color: #fff;
  font-size: 19px;
  font-family: "MaisonNeue-Medium";
  font-style: normal;
  font-weight: 400;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(98.13%, #3c3c3c), color-stop(98.13%, #000000));
  background: -webkit-linear-gradient(top, transparent 0%, #3c3c3c 98.13%, #000000 98.13%);
  background: linear-gradient(180deg, transparent 0%, #3c3c3c 98.13%, #000000 98.13%);
  -webkit-backdrop-filter: blur(2.71828px);
          backdrop-filter: blur(2.71828px);
  z-index: 2;
}

.work_module_tween .right_content .all_group_res .name_group p {
  padding: 16px;
}

.work_module_tween .right_content .all_group_res img {
  -webkit-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
}

.work_module_tween .right_content .all_group_res .img_group {
  overflow: hidden;
}

.work_module_tween .right_content .all_group_res .img_group:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.work_module_tween .right_content .desc_group {
  padding-top: 20px;
  font-size: 18px;
  line-height: 28.8px;
}

.work_module_tween .right_content .more_group {
  text-align: left;
  padding-top: 20px;
  font-size: 18px;
}

.work_module_tween .right_content .more_group span {
  font-size: 18px;
}

.work_module_tween .right_content .video_group {
  padding-bottom: 40px;
}

.work_module_tween .right_content .video_group .video_title {
  font-weight: 700;
  font-size: 20px;
  padding-bottom: 20px;
}

.work_module_tween .right_content .press_all_group {
  display: flex;
  flex-wrap: wrap;
}

.work_module_tween .right_content .video_link .left {
  position: absolute;
  left: 140px;
}

.work_module_tween .right_content .video_link .right {
  position: absolute;
  right: 140px;
}

.work_module_tween .right_content .press_group {
  padding-right: 40px;
}

.work_module_tween .right_content .press_group .press_desc {
  padding: 15px 0;
  font-size: 16px;
  color: #979797;
}

.work_module_tween .home_nav {
  align-items: flex-start;
  flex-wrap: wrap;
}

.work_module_tween .left_nav {
  width: 400px;
  background: transparent;
  z-index: 6;
}

.work_module_tween .left_nav::after {
  display: none;
}

.work_module_tween .left_nav li {
  padding-bottom: 20px;
  font-size: 24px;
}

.work_module_tween .nav_group {
  padding-top: 120px;
  background: #fff;
}

.work_module_tween .pc_title {
  margin-bottom: 40px;
}

.work_module_tween .right_content {
  padding-left: 0;
}

.work_module_tween.fixed .left_nav {
  padding-top: 0px;
}

.work_module_tween.fixed .nav_group {
  padding-top: 140px;
}

.detail_card_list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

#DescriptionPc {
  padding-top: 120px;
  margin-bottom: 120px;
}

#ArtistsPc {
  margin-bottom: 120px;
}

#ArtistsPc .common_column {
  float: unset;
}

#ExhibitionPc {
  margin-bottom: 120px;
}

#ExhibitionPc .ant-row-flex {
  margin-bottom: 40px;
}

#ExhibitionPc .workImg img {
  max-width: 100%;
}

#ExhibitionPc .common_column {
  float: unset;
}

#ExhibitionPc .column_item {
  margin-bottom: 16px !important;
}

#ExhibitionPc .wpr {
  padding-right: 13px;
}

#ExhibitionPc .wprl {
  padding-left: 8px;
  padding-right: 8px;
}

#ExhibitionPc .wpl {
  padding-left: 13px;
}

#VideosPc {
  margin-bottom: 0px;
}

#VideosPc .list_video {
  height: 280px;
}

.heroCarousel_exhibitions {
  display: block;
}

.heroCarousel_exhibitions .slick-dots {
  bottom: 0;
}

.carouselImg_exhibitions {
  padding: 0 10px;
}

.render_artist_container .horizontal_slide {
  height: auto;
  min-height: 200px;
}

.render_artist_container .small_card {
  min-width: 256px;
  height: inherit;
  margin-right: 40px;
}

.render_artist_container .small_image {
  margin-right: 0px;
  width: 270px;
  height: 295px;
}

.render_works_container .small_card {
  float: left;
  width: auto !important;
  height: 295px;
}

.render_works_container .small_image {
  height: 295px;
}

.render_works_container .small_image img {
  max-width: unset;
  object-fit: unset;
}

.render_videos_container .title {
  font-family: "MaisonNeue-Demi";
  font-size: 24px;
  line-height: 1;
  text-align: left;
  margin-bottom: 24px;
}

.render_press_container .small_card {
  width: 395px;
  height: auto;
}

.render_press_container .small_image {
  height: 260px;
}

.mobile .slick-dots {
  position: relative;
}

.mobile .slick-dots li {
  width: 4px;
  height: 4px;
  margin-right: 10px;
}

.mobile .slick-dots li:last-child {
  margin-right: 0;
}

.mobile .slick-dots button {
  border-radius: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  padding: 0;
}

.mobile .slick-dots button::before {
  display: none;
}

.mobile .slick-active button {
  background: #000;
}

/**************************************************** media query ******************************************************/
@media only screen and (max-width: 1023px) {
  .mobile .ant-divider-horizontal {
    margin: 40px 0;
  }
}

@media only screen and (max-width: 743px) {
  .VerWrap2 .VrDialog {
    top: 370px;
  }
  .VerWrap2 .exhibitionDetailWrap {
    padding: 75px 24px 24px 24px;
    width: 100%;
    margin: 0;
  }
  .VerWrap2 .featurenewwork_container {
    margin: 0;
  }
  .VerWrap2 .smallSliderHeader .work_title {
    width: 100%;
  }
  .mobile .exhibitionDetailWrap {
    padding-top: 108px;
  }
  .mobile .c_Description {
    padding-left: 24px;
    padding-right: 24px;
  }
  .mobile .carouselImg {
    padding: 0 10px;
  }
  .detail_link_group {
    flex-direction: column;
  }
  .detail_link_group .text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;
    max-width: 75%;
    white-space: nowrap;
  }
  .detail_link_group .left_item {
    padding-bottom: 40px;
  }
  .presses_slider .smallSlider_image {
    width: 295px;
    height: 189px;
  }
  .render_press_container,
  .render_videos_container,
  .render_artist_container,
  .render_works_container {
    padding-left: 24px;
    padding-right: 24px;
  }
  .render_videos_container .list_video {
    height: 222px;
  }
  .render_press_container .small_card {
    width: 295px;
    height: auto;
  }
  .render_press_container .small_image {
    height: 190px;
  }
}

@media only screen and (min-width: 744px) and (max-width: 1023px) {
  .heroCarousel_exhibitions .carouselImg {
    padding: 0 12px;
  }
  .exhibitionDetailInfo_title {
    font-size: 40px;
  }
  .mobile .exhibitionDetailWrap {
    padding: 110px 40px 24px 40px;
    width: 100%;
    margin: 0;
  }
  .mobile .downloadIconWrap {
    padding-top: 0;
    margin-left: 0;
  }
  .mobile .Description {
    padding-top: 80px;
  }
  .VerWrap2 .horizontal_Wrap {
    height: auto;
  }
  .VerWrap2 .horizontal_slide {
    height: inherit;
    min-height: 200px;
  }
  .render_press_container,
  .render_videos_container,
  .render_artist_container,
  .render_works_container {
    padding-left: 40px;
    padding-right: 40px;
  }
  .render_videos_container .list_video {
    height: 272px;
  }
  .render_videos_container .video {
    width: 450px;
  }
}

@media only screen and (min-width: 1024px) {
  .VerWrap2 .heroCarousel .slick-track {
    height: inherit;
    overflow: hidden;
  }
}

@media only screen and (max-width: 1279px) {
  .pc .exhibitionDetailWrap {
    padding-top: 140px;
    padding-left: 60px;
    padding-right: 60px;
  }
  .pc .pc_title {
    max-width: 360px;
  }
}

@media only screen and (min-width: 1280px) {
  .pc .exhibitionDetailWrap {
    padding-top: 140px;
    padding-left: 140px;
    padding-right: 140px;
  }
  .work_module_tween {
    padding-left: 140px;
    padding-right: 140px;
  }
}

.press-artist-title,
.press-image-title {
  padding-top: 100px;
  font-weight: 600;
  text-align: left;
}

.press-title {
  margin: 100px 0 30px 0;
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  background-color: #eaeaea;
  align-items: center;
  padding: 30px 10vw 30px 20vw;
  width: 100%;
  color: #333;
}

.press-title .press-title-text {
  font-size: 23px;
  font-weight: 600;
  max-width: 32.5vw;
  word-wrap: break-word;
  word-break: break-word;
  text-align: left;
}

.press-title .press-title-year {
  font-size: 16px;
  font-weight: normal;
  text-align: left;
  padding-top: 30px;
  width: 40vw;
}

.press-title-image {
  width: 30vw;
}

.press-title-content {
  width: 40vw;
}

.press-title-image img {
  width: 15vw;
  height: auto;
}

.press-title-description {
  text-align: left;
}

.press-link {
  margin-top: 20px;
  float: left;
  text-decoration: underline;
}

.press-link a {
  color: #ca970a;
}

.press-view-all-button {
  margin-bottom: 50px;
}

.press-block .FeaturedCarousel_Container.artist {
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .press-block .FeaturedCarousel_Container.artist {
    margin: 70px 16px 50px 16px;
  }
  .press-block .horizontalSlide {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .press-block .horizontalSlide_Wrap {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .press-artist-title,
  .press-image-title {
    padding-top: 70px;
  }
  .featurenewwork_container {
    padding-top: 0;
  }
  .press-title-image img {
    width: 20vw;
    height: auto;
  }
  .press-block {
    margin: 0 calc(7.22% + 16px);
  }
}

@media only screen and (max-width: 767px) {
  .press-title {
    padding: 30px 5vw 30px 5vw;
  }
  .press-title .press-title-text {
    font-size: 15px;
    max-width: 100%;
  }
  .press-title-image {
    width: 100%;
  }
  .press-title-image img {
    width: 35vw;
  }
}

@media only screen and (min-width: 768px) {
  .press-block {
    margin: 0 calc(20px + 2.78%);
  }
  .press_works {
    margin: 0;
  }
  .press-title {
    padding: 30px 15vw 30px 15vw;
  }
  .press-title .press-title-text {
    font-size: 20px;
    max-width: 100%;
  }
  .press-title-image {
    width: 100%;
  }
  .press-title-image img {
    width: 25vw;
  }
}

@media only screen and (min-width: 1920px) {
  .press-block {
    margin: 0 159px;
  }
  .press_works {
    margin: 0;
  }
  .press-title .press-title-text {
    font-size: 30px;
    max-width: 100%;
  }
  .press-artist-title,
  .press-image-title {
    margin-left: 20px;
  }
}

@media only screen and (min-width: 1024px) {
  .press-titles {
    font-size: 28px;
  }
}

@media only screen and (min-width: 768px) {
  .press-titles {
    font-size: 26px;
  }
}

@media only screen and (max-width: 767px) {
  .press-titles {
    font-size: 18px;
  }
  .heroCarousel2 .slick-dots {
    position: relative;
    bottom: inherit !important;
  }
}

.press-line {
  margin-bottom: 80px;
}

.pressDetail_artists {
  margin: 0;
}

.pressDetail_artists .ant-col {
  padding: 0;
}

.pressDetail_artists .featurenewwork_container {
  margin: 0;
  margin-top: 40px;
}

.pressDetail_artists .smallSliderHeader {
  display: none;
}

.artist_slide {
  margin-top: 40px;
  height: inherit;
}

.artist_slide .v2Card {
  width: 280px;
}

.artist_slide .v2Image {
  height: 295px;
}

.artist_slide .v2ArtNameBox {
  margin-top: 16px;
  text-align: left;
}

.artist_slide .v2Image img {
  width: 100%;
  left: 0;
  top: 0;
  -webkit-transform: unset;
          transform: unset;
}

.artist_slide a:hover, .artist_slide a:active {
  color: var(--dark);
}

.pressDetail_exhibtions .horizontal_Slide {
  margin-top: 40px;
  height: 295px;
}

.pressDetail_exhibtions .column_item {
  max-width: 280px;
}

.pressDetail_exhibtions .lazyimg {
  height: 100%;
}

.pressDetail_exhibtions .lazyimg img {
  margin-top: 0;
  height: 100%;
  max-height: 100%;
}

.press_works .common_wrapper {
  margin: 0;
  margin-top: 40px;
}

.Other_Presses .presses_wrapper {
  margin: 0;
  margin-top: 40px;
}

.press_detail_wrap {
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
}

.press_block {
  padding-bottom: 80px;
}

.pressDetail_flex {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 80px;
}

.pressDetail_swiper {
  width: 566px;
  height: 380px;
  margin-right: 40px;
  overflow: hidden;
}

.pressDetail_swiper .heroCarousel2 {
  line-height: 1;
  height: 100%;
}

.pressDetail_swiper .heroCarousel2 button {
  height: 65px;
  width: 40px;
  border-radius: 0;
  border: none;
  background-color: rgba(255, 255, 255, 0.64) !important;
}

.pressDetail_swiper .heroCarousel2 button.slick-arrow.slick-next {
  background-color: white;
}

.pressDetail_swiper .heroCarousel2 button.slick-arrow.slick-prev {
  background-color: white;
}

.pressDetail_swiper .heroCarousel2 .slick-prev {
  left: 0;
  top: 158px;
  z-index: 1;
}

.pressDetail_swiper .heroCarousel2 .slick-next {
  right: 0;
  top: 158px;
}

.pressDetail_swiper .heroCarousel2 .slick-prev:before {
  content: url("/images/miscellaneous/icon-chevron-left.svg") !important;
}

.pressDetail_swiper .heroCarousel2 .slick-next:before {
  content: url("/images/miscellaneous/icon-chevron-right.svg") !important;
}

.mb_40 {
  margin-bottom: 40px;
}

.pressDetail_info {
  overflow: hidden;
  text-align: left;
  flex: 1 1;
}

.pressDetail_info a:active, .pressDetail_info a:hover {
  color: var(--dark);
}

.pressDetail_info .title {
  font-family: "MaisonNeue-Demi";
  font-size: 24px;
  margin-bottom: 20px;
}

.pressDetail_info .time {
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
}

.pressDetail_info .desc {
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
}

.pressDetail_info .more {
  font-family: "MaisonNeue-Medium";
  font-size: 16px;
  cursor: pointer;
}

.pressDetail_info .more .anticon {
  padding-left: 8px;
}

.pressDetail_info .download {
  margin-left: 0;
  font-family: "MaisonNeue-Demi";
  font-size: 18px;
  cursor: pointer;
}

.pressDetail_info .anticon {
  padding-right: 8px;
  vertical-align: text-top;
}

.pressDetail_iphone,
.pressDetail_ipad {
  border-bottom: 1px solid #efefef;
  margin-bottom: 40px;
}

.pressDetail_iphone .slick-dots li,
.pressDetail_ipad .slick-dots li {
  width: 4px;
  height: 4px;
  background: #000;
  opacity: 0.3;
  margin-right: 18px;
}

.pressDetail_iphone .slick-dots li:last-child,
.pressDetail_ipad .slick-dots li:last-child {
  margin-right: 0;
}

.pressDetail_iphone .slick-dots li.slick-active,
.pressDetail_ipad .slick-dots li.slick-active {
  background: #000;
  opacity: 1;
}

.pressDetail_iphone .slick-dots li button,
.pressDetail_ipad .slick-dots li button {
  width: 4px;
  height: 4px;
  padding: 0;
}

.pressDetail_iphone .slick-dots li button:before,
.pressDetail_ipad .slick-dots li button:before {
  width: 4px;
  height: 4px;
  content: "";
}

.pressDetail_ipad {
  padding-bottom: 80px;
}

.pressDetail_ipad .slick-arrow {
  display: none !important;
}

.pressDetail_iphone .pressDetail_info {
  margin-top: 48px;
}

.worksItems_wrapper {
  margin-bottom: 0;
}

@media only screen and (max-width: 1023px) {
  .press_detail_wrap .cc_header {
    border: 0;
    padding-bottom: 8px;
  }
  .mb_40 {
    margin-bottom: 32px;
  }
  .press_detail_wrap {
    padding-top: 120px;
    padding-bottom: 24px;
  }
  .pressDetail_ipad {
    padding-bottom: 50px;
    margin-bottom: 64px;
  }
  .press_block {
    padding-bottom: 40px;
  }
  .pressDetail_ipad,
  .pressDetail_flex,
  .pressDetail_exhibtions,
  .pressDetail_artists {
    padding-left: 40px;
    padding-right: 40px;
  }
  .press_works .cc_header {
    margin-left: 40px;
    margin-right: 40px;
  }
  .Other_Presses .cc_header {
    margin-left: 40px;
    margin-right: 40px;
  }
  .press-line {
    border-bottom: 1px solid #efefef;
    margin-bottom: 40px;
  }
  .pressDetail_flex {
    display: none;
  }
  .pressDetail_iphone {
    display: none;
  }
  .heroCarousel2 .slick-dots {
    position: relative;
    bottom: inherit !important;
  }
}

@media only screen and (max-width: 744px) {
  .press_detail_wrap {
    padding-bottom: 8px;
  }
  .press_detail_wrap .cc_header {
    border: 0;
    display: block;
  }
  .press_detail_wrap .cc_header .title {
    padding-bottom: 20px;
  }
  .pressDetail_info .mb_40:last-child {
    margin-bottom: 0;
  }
  .pressDetail_iphone,
  .pressDetail_ipad,
  .pressDetail_flex,
  .pressDetail_exhibtions,
  .pressDetail_artists {
    padding-left: 24px;
    padding-right: 24px;
  }
  .press_works .cc_header {
    margin-left: 24px;
    margin-right: 24px;
  }
  .press_works .common_wrapper {
    margin: 0;
    margin-top: 32px;
    padding-top: 0;
  }
  .Other_Presses {
    padding: 0;
  }
  .Other_Presses .cc_header {
    margin-left: 24px;
    margin-right: 24px;
  }
  .Other_Presses .common_wrapper {
    margin: 0;
    margin-top: 32px;
    padding-top: 0;
  }
  .pressDetail_ipad {
    display: none;
  }
  .pressDetail_iphone {
    display: block;
  }
}

@media only screen and (min-width: 1024px) {
  .press_detail_wrap {
    padding-top: 137px;
  }
  .press_detail_wrap .press_block {
    padding-bottom: 20px;
  }
  .pressDetail_flex,
  .pressDetail_exhibtions,
  .pressDetail_artists {
    padding-left: 60px;
    padding-right: 60px;
  }
  .press_works .cc_header {
    margin-left: 60px;
    margin-right: 60px;
  }
  .Other_Presses .cc_header {
    margin-left: 60px;
    margin-right: 60px;
  }
  .pressDetail_ipad {
    display: none;
  }
  .pressDetail_iphone {
    display: none;
  }
}

@media only screen and (min-width: 1280px) {
  .press_detail_wrap {
    padding-top: 169px;
  }
  .press_detail_wrap .press_block {
    padding-bottom: 80px;
  }
  .pressDetail_flex,
  .pressDetail_exhibtions,
  .pressDetail_artists {
    padding-left: 140px;
    padding-right: 140px;
  }
  .press_works .cc_header {
    margin-left: 140px;
    margin-right: 140px;
  }
  .Other_Presses .cc_header {
    margin-left: 140px;
    margin-right: 140px;
  }
}

.heroCarousel2 .carouselImg {
  padding: 0 12px;
}
.heroCarousel2 button {
  border-radius: 50%;
  height: 48px;
  width: 48px;
  border: solid 1px var(--pale-blue);
  background-color: var(--white);
}

.heroCarousel2 button.slick-arrow.slick-next {
  background-color: white;
}
.heroCarousel2 button.slick-arrow.slick-prev {
  background-color: white;
}

.heroCarousel2 .slick-prev {
  left: 40px;
  top: 320px;
  z-index: 1;
}
.heroCarousel2 .slick-next {
  right: 40px;
  top: 320px;
}

.heroCarousel2 .slick-prev:before {
  content: url("/images/miscellaneous/icon-chevron-left.svg") !important;
}

.heroCarousel2 .slick-next:before {
  content: url("/images/miscellaneous/icon-chevron-right.svg") !important;
}
.heroCarousel2 .slick-next:hover:focus:active {
  background-color: white;
}
.heroCarousel2 .slick-prev:hover:focus:active {
  background-color: white;
}
.carousel.carousel-slider .control-arrow {
  background-color: white;
  position: absolute;
  top: 300px;
  right: 35px;
}

/**************************************************** media query ******************************************************/
@media only screen and (max-width: 767px) {
  .carouselImg img {
    min-height: 249px;
  }
  /* .exhibitionDetailWrap {
    margin: 60px auto;
    width: 92%;
  } */
  .exhibitionDetailInfo_title {
    font-size: 24px;
    line-height: 1.17;
  }
  /* .exhibitionDetailInfo_content {
    margin-top: 24px;
  } */
  .exhibitionDetailInfo_content .content {
    font-size: 14px;
    line-height: 1.43;
  }
  .exhibitionBtn .bdgBJg.bdgBJg {
    width: 320px;
    height: 56px;
  }
  .exhibitionBtn {
    margin-bottom: 64px;
  }
  .exhibitionBtn .gipcPA.gipcPA {
    font-size: 18px;
    line-height: 1.33;
    top: 18px;
    left: 45px;
  }
  .heroCarousel2 .slick-arrow {
    display: none !important;
  }
  .heroCarousel2 .slick-dots {
    bottom: -20px;
  }
  .heroCarousel2 .carouselImg {
    padding: 0px;
  }
  .heroCarousel2 .ewOoqd {
    margin-top: 102px;
  }
  .exhibition_artist {
    margin: 68px 16px 0px 16px;
  }
}
@media only screen and (min-width: 768px) {
  .carouselImg img {
    height: 360px;
  }
}
@media only screen and (min-width: 1024px) {
  .carouselImg img {
    height: 450px;
  }
  .heroCarousel2 .slick-prev {
    top: 250px;
  }
  .heroCarousel2 .slick-next {
    top: 250px;
  }
}
@media only screen and (min-width: 1440px) {
  .carouselImg img {
    height: 500px;
  }
}
@media only screen and (min-width: 1920px) {
  .carouselImg img {
    height: 640px;
  }
  .heroCarousel2 .slick-prev {
    top: 300px;
  }
  .heroCarousel2 .slick-next {
    top: 300px;
  }
}

/* Version2 */
.carouselImg2{
  padding: 0;
  /* padding-right: 20px; */
}
.carouselImg2 img{
  width: 100%;
  object-fit: scale-down;
}
@media only screen and (min-width: 1024px) {
  .carouselImg2 img {
    height: 306px;
  }
}
@media only screen and (min-width: 1280px) {
  .carouselImg2 img {
    height: 372px;
  }
}

.aboutHeader_board.privacy {
    height: unset;
}

.about_middle_des.privacy {
    text-align: left;
}

.aboutHeader_content_list.privacy>li {
    margin: 5px 0;
    line-height: 1.5;
}

.privacy_subtitle {
    font-family: MaisonNeue-Bold;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--dark);
    margin: 20px 0;
}

.about_image_des.privacy_subcontent {
    width: 100%;
    line-height: 29px;
    font-size: 16px;
    font-family: MaisonNeue-Book;
}

.privacyVer2 .aboutHeader_title {
    text-align: center;
}

.privacyVer2 .aboutHeader {
    position: relative;
}

.privacy_tips {
    position: absolute;
    bottom: 0;
    left: 0px;
    background: rgba(0, 0, 0, 0.64);
    color: #fff;
    font-size: 12px;
    line-height: 28px;
    height: 28px;
    padding: 0 8px;
}

.mb0 {
    margin-bottom: 0;
}

.privacy_tips_mobile {
    display: none;
}

@media only screen and (max-width: 744px) {
    .privacyVer2 .aboutHeader {
        height: 355px;
        z-index: 1;
    }

    .privacyVer2 .about_middle_des.privacy {
        margin: 40px auto !important;
    }

    .privacy_tips {
        display: none;
    }

    .privacyVer2 .privacy_subtitle {
        margin-top: 0;
    }

    .privacyVer2 .about_image_des.privacy_subcontent {
        padding-bottom: 40px;
    }

    .privacyVer2 .about_image_des.privacy_subcontent:last-child {
        padding-bottom: 0;
    }

    .pb40 {
        padding-bottom: 40px;
        margin-bottom: 0;
    }

    .privacy_tips_mobile {
        display: block;
        padding: 0 24px;
        position: relative;
        background: #fff;
        color: #979797;
        opacity: 0.88;
        text-align: left;
        margin-top: 12px;
        line-height: 16px;
        height: auto;
    }
}

@media only screen and (min-width: 768px) {
    .privacyVer2 .privacy_subtitle {
        margin-top: 0;
    }

    .privacyVer2 .about_image_des.privacy_subcontent {
        padding-bottom: 40px;
    }

    .privacyVer2 .about_image_des.privacy_subcontent:last-child {
        padding-bottom: 0;
    }

    .pb40 {
        padding-bottom: 40px;
        margin-bottom: 0;
    }

    .privacyVer2 .about_middle_des.privacy {
        margin: 100px auto;
        padding: 0 60px;
    }
}

@media only screen and (max-width: 1023px) {
    .privacyVer2 .about_middle_des.privacy {
        margin: 64px auto;
    }
}


@media only screen and (min-width: 1280px) {
    .privacyVer2 .privacy_subtitle {
        margin-top: 0;
    }

    .privacyVer2 .about_image_des.privacy_subcontent {
        padding-bottom: 40px;
    }

    .privacyVer2 .about_image_des.privacy_subcontent:last-child {
        padding-bottom: 0;
    }

    .pb40 {
        padding-bottom: 40px;
        margin-bottom: 0;
    }

    .about_middle_des.privacy {
        margin: 120px auto !important;
    }
}
.m_filter {
  position: fixed;
  z-index: 6;
  top: 89px;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 24px;
  box-sizing: border-box;
}

.m_filter .f_top {
  padding-top: 8px;
  padding-bottom: 34px;
  text-align: left;
}

.m_filter .f_top .f_title {
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  vertical-align: bottom;
}

.m_filter .f_close {
  float: right;
  cursor: pointer;
}

.m_filter .f_body {
  height: calc(100% - 88px);
  overflow-y: auto;
}

.m_filter .f_item {
  position: relative;
  margin-bottom: 26px;
  text-align: left;
}

.m_filter .f_btn_group {
  margin-top: 40px;
}

.m_filter .t__text {
  font-family: "MaisonNeue-Medium";
  font-size: 14px;
  width: 100%;
  height: 44px;
  line-height: 44px;
  text-align: center;
  cursor: pointer;
}

.m_filter .reset {
  border: 1px solid #000;
  color: var(--dark);
  margin-bottom: 24px;
}

.m_filter .confirm {
  color: #fff;
  background: #000;
}

.m_filter_list {
  text-align: left;
}

.m_filter_item {
  margin-bottom: 26px;
}

@media only screen and (max-width: 743px) {
  .m_filter {
    height: calc(100% - 88px);
  }
}

@media only screen and (min-width: 744px) and (max-width: 1023px) {
  .m_filter {
    height: calc(100% - 88px);
  }
  .m_filter_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }
  .m_filter_item {
    min-width: 33.33%;
  }
}

