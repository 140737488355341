.sm_footer {
  visibility: visible;
}

.lg_footer {
  padding: 48px 60px 64px 60px;
}

.mobile_group {
  display: none;
}

.footer_code_dailog {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 101;
}

.footer_code_dailog.active {
  display: block;
}

.footer_code_dailog .mask {
  position: fixed;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.footer_code_dailog .footer_dailog_content {
  position: absolute;
  z-index: 2;
  width: 256px;
  height: 256px;
  top: 50%;
  left: 50%;
  margin-top: -128px;
  margin-left: -128px;
}

.lg_footer_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-content: center;
}

.lg_footer_item .NewsLetter {
  width: 400px;
}

.lg_address {
  position: relative;
  height: 42px;
  line-height: 42px;
  padding: 0 30px;
  background-color: #000000;
  border-bottom: 1px solid rgba(151, 151, 151, 0.64);
}

.lg_address span {
  color: #fff;
}

.lg_address .copyright {
  float: right;
  line-height: 42px;
  padding: 0;
}

.lg_address .copyright span {
  color: #fff;
}

.followUs .code_item {
  display: none;
  position: absolute;
  left: 45px;
  bottom: -8px;
  background: #fff;
  flex: 1;
  width: 112px;
  border: 2px solid #000000;
  box-sizing: border-box;
  height: min-content;
  z-index: 1;
}

.followUs .code_item:first-child {
  margin-right: 16px;
}

.followUs .code_item span {
  position: absolute;
  width: 20px;
  top: 2px;
  left: 5px;
  font-size: 12px;
  word-wrap: break-word;
  color: var(--dark);
}

.followUs .code_item img {
  float: right;
  width: 80px;
  height: 80px;
  margin: 6px;
}

.lg_title {
  font-family: MaisonNeue-Book;
  font-style: normal;
  font-size: 21px;
  line-height: 21px;
  color: var(--dark);
  margin-bottom: 20px;
}

.lg_content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  align-content: center;
}

.icon_group .icon_item {
  cursor: pointer;
  position: relative;
  margin-right: 20px;
}

.icon_group .icon_item.wechat:hover .code_item {
  display: block;
}

.icon_group .icon_item.redbook:hover .code_item {
  display: block;
}

.small_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-content: center;
  margin-bottom: 10px;
}

.footer-text-style {
  font-family: MaisonNeue-Book;
  font-style: normal;
  font-size: 12px;
}

.footer-text-style a {
  color: #fff;
}

.footer-text-style span {
  color: #fff !important;
}

.sm_address {
  position: relative;
  line-height: 36px;
  background: #000000;
  padding: 0 36px;
  color: #fff;
}

.sm_address::after {
  display: block;
  content: " ";
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  bottom: 0;
  background: rgba(151, 151, 151, 0.64);
}

.sm_address svg {
  margin-right: 5px;
}

.sm_address span {
  color: #fff;
}

.pc744 {
  display: block;
  font-size: 12px;
}

.pc743 {
  position: relative;
  display: none;
  padding: 12px 0;
  padding-left: 24px;
  font-size: 12px;
}

.pc743 p {
  margin-bottom: 0;
  line-height: 16px;
}

.pc743 p:last-child {
  margin-top: 2px;
  padding-left: 15px;
}

.sm_copyright {
  height: 36px;
  line-height: 36px;
  background: #000000;
  padding: 0 36px;
  color: #fff;
}

.sm_copyright span {
  color: #fff;
}

.socialMedia_container {
  margin-bottom: 20px;
}

.contact-group {
  font-size: 16px;
}

.contact-group .contact-item {
  padding-bottom: 12px;
}

.contact-group .contact-item .icon {
  padding-right: 9px;
}

.gallery_footer {
  border-top: 1px solid #e4e5e7;
  background: white;
  padding: 0;
  text-align: left;
  width: 100%;
  position: relative;
}

@media only screen and (max-width: 743px) {
  .gallery_footer .pub_footer {
    padding: 48px 24px 40px 24px !important;
    width: 100% !important;
  }
  .gallery_footer .copyright {
    padding: 0 24px !important;
  }
  .gallery_footer .followUs {
    width: 100% !important;
  }
  .gallery_footer .followUs .code_item {
    width: 175px;
  }
  .gallery_footer .followUs .code_item span {
    top: 30px;
    left: 10px;
  }
  .gallery_footer .followUs .code_item img {
    width: 128px;
    height: 119px;
    margin: 9px;
  }
  .gallery_footer .address {
    position: relative !important;
    height: 58px !important;
    line-height: 36px !important;
    padding: 10px 24px !important;
  }
  .gallery_footer .address .en {
    position: static !important;
  }
  .sm_footer {
    padding: 24px;
  }
  .sm_footer .pc_group {
    display: none;
  }
  .sm_footer .mobile_group {
    display: block;
  }
  .sm_footer .small_item {
    display: block;
  }
  .sm_footer .contact-group {
    margin-top: 30px;
  }
  .sm_footer .contact-group .lg_title {
    margin-bottom: 0px;
  }
  .gallery_footer .sm_address {
    padding: 0;
  }
  .gallery_footer .pc744 {
    display: none;
  }
  .gallery_footer .pc743 {
    display: block;
    line-height: 1;
  }
  .gallery_footer .sm_copyright {
    padding: 0 24px;
  }
}

@media only screen and (min-width: 744px) {
  .sm_footer {
    position: relative;
    width: 608px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 40px;
  }
  .sm_footer .pc_group {
    display: none;
  }
  .sm_footer .mobile_group {
    display: block;
  }
  .sm_footer .lg_title {
    margin-bottom: 10px;
  }
  .gallery_footer .copyright {
    padding: 0 30px !important;
  }
  .gallery_footer .followUs {
    width: 240px;
  }
  .gallery_footer .followUs .code_item {
    width: 112px;
  }
  .gallery_footer .followUs .code_item span {
    top: 0;
  }
  .gallery_footer .followUs .code_item img {
    width: 80px;
    height: 80px;
    margin: 6px;
  }
}
