.m_filter {
  position: fixed;
  z-index: 6;
  top: 89px;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 24px;
  box-sizing: border-box;
}

.m_filter .f_top {
  padding-top: 8px;
  padding-bottom: 34px;
  text-align: left;
}

.m_filter .f_top .f_title {
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  vertical-align: bottom;
}

.m_filter .f_close {
  float: right;
  cursor: pointer;
}

.m_filter .f_body {
  height: calc(100% - 88px);
  overflow-y: auto;
}

.m_filter .f_item {
  position: relative;
  margin-bottom: 26px;
  text-align: left;
}

.m_filter .f_btn_group {
  margin-top: 40px;
}

.m_filter .t__text {
  font-family: "MaisonNeue-Medium";
  font-size: 14px;
  width: 100%;
  height: 44px;
  line-height: 44px;
  text-align: center;
  cursor: pointer;
}

.m_filter .reset {
  border: 1px solid #000;
  color: var(--dark);
  margin-bottom: 24px;
}

.m_filter .confirm {
  color: #fff;
  background: #000;
}

.m_filter_list {
  text-align: left;
}

.m_filter_item {
  margin-bottom: 26px;
}

@media only screen and (max-width: 743px) {
  .m_filter {
    height: calc(100% - 88px);
  }
}

@media only screen and (min-width: 744px) and (max-width: 1023px) {
  .m_filter {
    height: calc(100% - 88px);
  }
  .m_filter_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }
  .m_filter_item {
    min-width: 33.33%;
  }
}
