.common_page,
.common-page {
  padding-left: 140px;
  padding-right: 140px;
}

.common_title,
.common-title {
  min-width: 200px;
  font-family: MaisonNeue-Demi;
  font-size: 32px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: -0.1px;
  text-align: left;
  color: var(--dark);
  margin-bottom: 32px;
  padding-top: 128px;
}

.header_box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 128px;
  margin-bottom: 32px;
}

.header_box .common_title {
  padding-top: 0;
  margin-bottom: 0;
}

.header_box .filter_bar {
  min-width: 200px;
  text-align: right;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.header_box .filter_name {
  font-family: MaisonNeue-Medium;
  font-size: 18px;
}

.header_box .filter_name .text {
  margin-right: 6px;
}

.names_hook {
  margin-right: 20px;
}

.s_btn_group {
  border: 1px solid #000000;
  padding: 0px 10px;
}

.header_box .filter_name {
  margin-right: 6px;
  cursor: pointer;
  line-height: 1.8;
}

.header_box .ant-btn {
  border-color: transparent !important;
  box-shadow: none;
}

.header_box .ant-btn:hover,
.header_box .ant-btn:focus {
  border-color: transparent !important;
  color: unset !important;
}

.header_box .ant-select-selection {
  border: 0px;
}

.header_box .ant-select-selection {
  font-size: 16px;
  color: var(--dark);
}

.header_box .ant-btn {
  padding: 0;
}

.header_solid {
  display: block;
}

.filter_title {
  font-family: 'MaisonNeue-Demi';
  font-size: 32px;
  margin-bottom: 10px;
}

.filter_title .anticon {
  vertical-align: middle;
  margin-left: 10px;
}

.type_ul {
  display: none;
  font-family: 'MaisonNeue-Demi';
  font-size: 32px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.type_ul li {
  color: #979797;
  margin-bottom: 20px;
}

.type_ul .active {
  color: var(--dark);
}

.type_ul .active::before {
  display: inline-block;
  vertical-align: middle;
  content: ' ';
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: #000000;
  margin-right: 20px;
}

.a_dropdown_link {
  color: var(--dark);
  border: 1px solid #000000;
  cursor: pointer;
  padding: 5px 10px;
}

.a_dropdown_link .text {
  margin-right: 2px;
}

.a_dropdown_link:hover, .a_dropdown_link:focus {
  color: var(--dark);
  border: 1px solid #000000;
  color: unset !important;
}

.a_dropdown_link:active {
  color: var(--dark);
  border: 1px solid #000000;
  color: unset !important;
}

.a_dropdown_link:active span {
  position: relative;
}

/* Artists */
.artists_page {
  overflow: hidden;
}

.artists_page .column_item:hover .card_name {
  display: none;
}

.artists_page .column_item:hover .card_desc {
  display: block;
}

.fileterBarRow .Large_Screen_Frame {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 743px) {
  .common-page {
    padding-left: 24px;
    padding-right: 24px;
  }
  .common_title,
  .common-title {
    text-align: center;
    padding-top: 80px;
    margin-bottom: 0;
  }
  .header_box {
    padding-top: 108px;
    display: block;
  }
  .header_box .common_title {
    width: 100%;
    text-align: center;
  }
  .header_box .filter_bar {
    width: 100%;
    display: block;
    text-align: center;
  }
  .flex_header {
    display: flex !important;
    justify-content: center !important;
    margin-top: 10px;
  }
  .c_page .ImgSwiper .swiper_info::before {
    bottom: -35px;
  }
  .c_page .header_box,
  .c_page .common_wrapper {
    padding-left: 24px;
    padding-right: 24px;
  }
  .c_page .common_wrapper {
    padding-top: 35px;
  }
  .header_box {
    border-bottom: 1px solid #efefef;
    padding-bottom: 20px !important;
  }
}

@media only screen and (min-width: 744px) {
  .common-page {
    padding-left: 40px;
    padding-right: 40px;
  }
  .c_page .header_box,
  .c_page .ImgBanner,
  .c_page .common_wrapper {
    padding-left: 40px;
    padding-right: 40px;
  }
  .c_page .header_box {
    border-bottom: 1px solid #efefef;
    padding-bottom: 20px !important;
  }
  .filter_title {
    display: none;
  }
}

@media only screen and (max-width: 743px) {
  .artist_header .common_title {
    display: none;
  }
  .artist_header .filter_title {
    display: block;
  }
}

@media only screen and (min-width: 1024px) {
  .common-page {
    padding-left: 60px;
    padding-right: 60px;
  }
  .c_page .header_box,
  .c_page .ImgBanner,
  .c_page .common_wrapper {
    padding-left: 60px;
    padding-right: 60px;
  }
  .c_page .header_box {
    border-bottom: none;
    padding-bottom: 0 !important;
  }
  .filter_title {
    display: none;
  }
}

@media only screen and (min-width: 1280px) {
  .common-page {
    padding-left: 140px;
    padding-right: 140px;
  }
  .c_page .header_box,
  .c_page .ImgBanner,
  .c_page .common_wrapper {
    padding-left: 140px;
    padding-right: 140px;
  }
  .c_page .header_box {
    border-bottom: none;
    padding-bottom: 0 !important;
  }
}
