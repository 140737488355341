@import "~antd/dist/antd.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

:root {
  --steel-grey: #797c86;
  --cloudy-blue: #bcbec2;
  --pale-blue: #e4e5e7;
  --ice-blue: #f1f2f3;
  --white: #ffffff;
  --dark: #1c1d22;
}
.ant-layout {
  overflow: hidden;
  min-height: 100vh;
}
a {
  color: var(--dark);
}
a:active{
  color: var(--dark);
}
a:hover{
  color: var(--dark);
}
.ant-btn {
  border: none;
}

/******************************************* Global CSS **************************************************************************/
img {
  object-fit: cover;
}
