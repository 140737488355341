.f_checked .ant-checkbox-inner {
  border-radius: 0;
  border: 1px solid #000000;
}

.f_checked .ant-checkbox-checked::after {
  border: 1px solid #000000;
  border-radius: 0;
}

.f_checked .ant-checkbox-checked .ant-checkbox-inner {
  border-color: #000000;
  background-color: #000000;
}

.f_button_footer {
  text-align: right;
  padding-top: 30px;
}

.f_button_footer .b_btn {
  box-sizing: border-box;
  border: 1px solid #000000;
  font-family: 'MaisonNeue-Medium';
  font-size: 14px;
  display: inline-block;
  width: 78px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10+ and Edge */
  user-select: none;
  /* Standard syntax */
}

.f_button_footer .b_reset {
  margin-right: 8px;
}

.f_button_footer .b_confirm {
  color: #fff;
  background: #000000;
}

.f_button_content {
  display: none;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #000000;
  position: absolute;
  z-index: 5;
  top: 44px;
  right: 0;
  padding: 24px;
}

.f_button_content .f_filter_item {
  overflow: hidden;
}

.f_button_header {
  z-index: 6;
  position: relative;
  background: #fff;
  border: 1px solid #000000;
  padding: 10px 15px;
  text-align: center;
  cursor: pointer;
}

.f_button_header .f__text {
  font-family: 'MaisonNeue-Medium';
  font-size: 18px;
  margin-right: 8px;
  display: inline-block;
  vertical-align: text-top;
  line-height: 1;
}

.f_button_header .anticon {
  vertical-align: text-top;
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
  transition: transform 0.3s;
}

.f_button_header.actives, .f_button_header:hover {
  border-bottom-color: transparent;
}

.f_button_header.actives .anticon, .f_button_header:hover .anticon {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.f_button {
  position: relative;
  box-sizing: border-box;
}

.f_button_content .c_StyledCol_ver {
  margin-bottom: 18px;
}

.f_button_content .c_StyledRow_ver {
  display: flex;
  align-items: baseline;
}

.f_button_content .c_StyledText_span_ver {
  padding-left: 8px;
  display: block;
  text-align: left;
  font-family: 'MaisonNeue-Book';
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: var(--dark);
}

.mobile_filter_btn {
  position: relative;
  background: #fff;
  border: 1px solid #000000;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  line-height: 44px;
  padding: 0 15px;
}

.mobile_filter_btn .f__text {
  font-family: 'MaisonNeue-Medium';
  font-size: 18px;
  margin-right: 8px;
  display: inline-block;
  vertical-align: text-top;
  line-height: 1;
}

.mobile_filter_btn .anticon {
  vertical-align: middle;
  margin-left: 10px;
}

.artists_menu {
  width: 640px;
}

.collections_menu {
  width: 640px;
  visibility: visible;
}

.exhibitions_menu {
  width: 210px;
}

.exhibitions_menu .f_button_footer {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.worksItems_menu {
  width: 640px;
}

@media only screen and (min-width: 744px) and (max-width: 1023px) {
  .worksItems_menu {
    width: 500px;
  }
}
