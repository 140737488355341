.info_link a:active, .info_link a:hover {
  color: var(--dark);
}

.info_link .icon {
  display: inline-block;
  position: relative;
  top: -2px;
}

.collectionDetailWrap {
  display: flex;
  flex-direction: column;
}

.collectionwork_wrap {
  margin: 96px 40px;
  text-align: left;
}

/***************************************** collection Info **************************************/
.collectionInfoWrap {
  width: 31.25%;
  margin: 62px auto;
}

.collectionInfoWrap .collectionInfoWrap_title {
  width: 90%;
  margin: 0 auto;
}

.collectionInfoWrap_title .artistName {
  font-family: MaisonNeue-Book;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--dark);
}

.collectionInfoWrap_title .collectionName {
  font-family: MaisonNeue-Bold;
  font-size: 48px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: var(--dark);
  margin-top: 16px;
}

.collectionInfoWrap_title .year {
  font-family: MaisonNeue-Book;
  font-size: 21px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: var(--dark);
  margin-top: 8px;
}

.collectionInfoWrap_content {
  text-align: left;
  font-family: MaisonNeue-Book;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--dark);
  margin-top: 24px;
}

.collectionInfoWrap_content .title {
  font-size: 12px;
  color: var(--steel-grey);
}

.collectionInfoWrap_content .content {
  margin-top: 8px;
}

.collectionInfoWrap_content .description {
  margin-top: 24px;
}

.collectionInfoWrap .downloadIcon {
  margin-left: 0;
  margin-right: 34px;
}

.collectionInfoWrap .video {
  margin-top: 96px;
}

/* button */
.collectionBtn {
  margin-top: 96px;
  margin-bottom: 96px;
}

.collectionBtn .bdgBJg.bdgBJg {
  width: 330px;
}

.collectionBtn .JhMeT {
  display: none;
}

.col_heroCarousel .slick-list {
  margin-top: 0;
}

.col_heroCarousel .slick-dots {
  display: none !important;
}

.col_heroCarousel .slick-arrow {
  height: 65px;
  width: 40px;
  border-radius: 0;
  border: none;
  background-color: rgba(255, 255, 255, 0.64) !important;
}

.col_heroCarousel button.slick-arrow.slick-next {
  background-color: white;
}

.col_heroCarousel button.slick-arrow.slick-prev {
  background-color: white;
}

.col_heroCarousel .slick-prev {
  left: 0;
  top: 250px;
  z-index: 1;
}

.col_heroCarousel .slick-next {
  right: 0;
  top: 250px;
}

.col_heroCarousel .slick-prev:before {
  content: url("/images/miscellaneous/icon-chevron-left.svg") !important;
}

.col_heroCarousel .slick-next:before {
  content: url("/images/miscellaneous/icon-chevron-right.svg") !important;
}

.v__Desktop {
  display: block;
}

.v__Mobile {
  display: none;
}

.c_DetailWrap {
  visibility: visible;
}

.c_DetailWrap_pc {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 140px;
  margin: 0;
  margin-bottom: 40px;
  text-align: left;
}

.c_DetailWrap_pc .view_content {
  margin-bottom: 12px;
  padding-bottom: 0;
}

.c_DetailWrap_pc .view_content:first-child .c__content {
  font-size: 14px;
  color: #979797;
}

.c_DetailWrap_pc .view_content .c__content {
  line-height: 1;
  position: relative;
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  color: var(--dark);
}

.c_DetailWrap_pc .view_content .c__content .text {
  padding-left: 0;
}

.c_DetailWrap_pc .pc_subTitle {
  font-family: "MaisonNeue-Medium";
  text-align: left;
  margin-bottom: 20px;
  color: var(--dark);
  font-size: 18px;
}

.c_DetailWrap_pc .pc_title {
  font-family: "MaisonNeue-Demi";
  text-align: left;
  font-size: 40px;
  padding-bottom: 0;
}

.c_DetailWrap_pc .c_d_name_pc {
  width: 100%;
  max-width: 36%;
}

.c_DetailWrap_pc .pc_Materials {
  padding-right: 10px;
}

.c_DetailWrap_mobile {
  padding-top: 120px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  margin: 0;
}

.c_d_author {
  font-family: "MaisonNeue-Medium";
  margin-bottom: 20px;
  font-size: 18px;
}

.c_d_title {
  font-family: "MaisonNeue-Demi";
  text-align: left;
  margin-bottom: 24px;
  font-size: 40px;
  line-height: 1.2;
}

.c_Materials {
  font-family: "MaisonNeue-Medium";
  font-size: 14px;
  line-height: 14px;
  color: #979797;
  margin-bottom: 12px;
}

.c_Materials_content {
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  color: var(--dark);
  margin-bottom: 25px;
}

.c_downloadIconWrap {
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  margin-left: 0;
}

.c_downloadIconWrap .c_pdf {
  padding-bottom: 12px;
}

.c_downloadIconWrap .c_images {
  padding-bottom: 0;
}

.c_downloadIconWrap .download_icon {
  vertical-align: text-bottom;
}

.c_downloadIconWrap .download_text {
  vertical-align: middle;
  padding-left: 8px;
}

.collections_overview {
  text-align: left;
}

.collections_overview h1 {
  font-family: "MaisonNeue-Demi";
  font-size: 24px;
  padding-bottom: 24px;
  margin-bottom: 0;
}

.collections_overview .overview_desc {
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
}

.collections_video .title {
  font-family: "MaisonNeue-Demi";
  font-size: 24px;
  line-height: 1;
  text-align: left;
  border-bottom: 1px solid #efefef;
}

.collections_artist {
  padding-top: 40px;
  padding-left: 140px;
  padding-right: 140px;
}

.collections_artist .artist_view_info {
  display: flex;
  margin-top: 40px;
}

.collections_artist .info_left {
  width: 260px;
  margin-right: 82px;
  text-align: left;
}

.collections_artist .info_right {
  flex: 1;
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  text-align: left;
}

.collections_artist .info_img {
  width: 260px;
  height: 295px;
  margin-bottom: 24px;
}

.collections_artist .info_img img {
  max-width: 100%;
  max-height: 100%;
}

.collections_artist .info_name {
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 49px;
  font-family: "MaisonNeue-Demi";
}

.collections_artist .info_link a {
  display: block;
  padding-bottom: 24px;
  margin: 0;
}

.collections_artist .info_link span.text {
  font-family: "MaisonNeue-Medium";
  font-size: 20px;
  margin-right: 8px;
}

.collections_artist .m_t {
  font-family: "MaisonNeue-Demi";
  font-size: 24px;
  text-align: left;
  margin-bottom: 24px;
}

.collections_works_container {
  visibility: visible;
  margin-top: 0px;
}

.collections_works_container .cc_header {
  margin-bottom: 40px;
}

.collections_works_container .common_wrapper {
  margin: 0;
}

.collections_works_container .v2Card {
  float: left;
  width: auto !important;
}

.collections_works_container .v2Image img {
  left: 0;
  top: 0;
  object-fit: unset;
  transform: unset;
}

.collections_other_container {
  visibility: visible;
}

.collections_other_container .cc_header {
  margin-bottom: 40px;
}

.collections_other_container .common_wrapper {
  margin: 0;
}

.artist_info_pc {
  margin-top: 0px;
  padding-top: 0;
}

.artist_info_mobile {
  margin-top: 0px;
  padding-top: 0;
}

.collections_divider {
  height: 160px;
}

.lh100 {
  height: 100px;
}

.c__video {
  visibility: visible;
}

/********************************************* media query *****************************************************************************/
@media only screen and (max-width: 767px) {
  .collectionInfoWrap {
    margin: 44px auto;
    width: 91.47%;
  }
  .collectionInfoWrap_title .artistName {
    font-size: 14px;
    line-height: 1.43;
  }
  .collectionInfoWrap_title .collectionName {
    font-size: 24px;
    line-height: 1.17;
    margin-top: 8px;
  }
  .collectionInfoWrap_title .year {
    font-size: 18px;
    line-height: 1.67;
  }
  .collectionInfoWrap_content {
    font-size: 14px;
    line-height: 1.43;
  }
  .collectionInfoWrap_content .description {
    margin-top: 16px;
  }
  .collectionInfoWrap .video {
    margin-top: 58px;
  }
  .collectionInfoWrap .video iframe {
    height: 229px;
  }
  .downloadIconWrap {
    padding-top: 0px;
  }
  .collectionBtn {
    margin-top: 65px;
    margin-bottom: 65px;
  }
}

@media only screen and (min-width: 768px) {
  .collectionInfoWrap {
    width: 78.2%;
  }
  .collectionInfoWrap_title .collectionName {
    font-size: 36px;
    line-height: 1.11;
    margin-top: 13px;
  }
}

@media only screen and (min-width: 1024px) {
  .collectionInfoWrap {
    width: 58.6%;
  }
}

@media only screen and (min-width: 744px) and (max-width: 1023px) {
  .c_DetailWrap_pc {
    padding-left: 40px;
    padding-right: 40px;
  }
  .collections_overview {
    padding-left: 40px;
    padding-right: 40px;
  }
  .collections_video {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media only screen and (max-width: 1023px) {
  .col_heroCarousel .slick-arrow {
    display: none !important;
  }
  .col_heroCarousel .slick-dots {
    display: block !important;
    position: relative;
    bottom: 0;
  }
  .col_heroCarousel .slick-dots li {
    width: 4px;
    height: 4px;
    background: #000;
    opacity: 0.3;
    margin-right: 18px;
  }
  .col_heroCarousel .slick-dots li button {
    width: 4px;
    height: 4px;
    padding: 0;
  }
  .col_heroCarousel .slick-dots li button::before {
    display: none;
  }
  .col_heroCarousel .slick-dots li.slick-active {
    background: #000;
    opacity: 1;
  }
  .col_heroCarousel .slick-dots button {
    background-color: rgba(0, 0, 0, 0.2) !important;
  }
  .col_heroCarousel .slick-dots .slick-active button {
    background-color: #000 !important;
  }
  .c_DetailWrap_mobile {
    display: block;
    padding-left: 40px;
    padding-right: 40px;
  }
  .collections_overview {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 50px;
    padding-bottom: 20px;
    margin-bottom: 0;
    border-bottom: 1px solid #e8e8e8;
  }
  .collections_video {
    position: relative;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 0;
    padding-bottom: 40px;
    border-bottom: 1px solid #e8e8e8;
  }
  .collections_video .ant-divider {
    display: none;
  }
  .collections_video .title {
    padding-bottom: 24px;
  }
  .collections_artist {
    padding-left: 40px;
    padding-right: 40px;
    border-bottom: 1px solid #e8e8e8;
  }
  .collections_artist .info_name {
    margin-bottom: 24px;
  }
  .collections_artist .info_link {
    text-align: left;
  }
  .collections_artist .info_link a {
    font-size: 16px;
  }
  .collections_artist .info_left {
    width: 100%;
    margin-right: 0;
  }
  .collections_artist .info_left .info_img {
    width: 100%;
    height: 100%;
  }
  .collections_artist .artist_view_info {
    margin-top: 0;
    flex-direction: column;
  }
  .collections_artist .artist_view_info .ant-divider {
    margin-top: 0;
  }
  .collections_works_container {
    border-bottom: 1px solid #efefef;
  }
  .collections_works_container .cc_header {
    margin-left: 40px;
    margin-right: 40px;
  }
  .collections_works_container .common_wrapper {
    padding-left: 40px;
    padding-right: 40px;
  }
  .collections_works_container .work_v2 {
    margin-left: 40px;
    margin-right: 40px;
  }
  .collections_other_container {
    border-bottom: 0px solid #efefef;
  }
  .collections_other_container .cc_header {
    margin-left: 40px;
    margin-right: 40px;
    border: 0;
  }
  .collections_other_container .common_wrapper {
    padding-left: 40px;
    padding-right: 40px;
  }
  .collections_divider {
    height: 40px;
  }
  .lh100 {
    height: 40px;
  }
  .c__video .list_video {
    height: 402px;
  }
}

@media only screen and (max-width: 743px) {
  .c_DetailWrap_pc {
    padding-left: 24px;
    padding-right: 24px;
  }
  .c_DetailWrap_mobile {
    padding-left: 24px;
    padding-right: 24px;
  }
  .collections_overview {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 10px;
  }
  .collections_video {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .collections_artist {
    padding-left: 24px;
    padding-right: 24px;
  }
  .collections_artist .info_link span.text {
    font-size: 16px;
  }
  .collections_works_container .cc_header {
    margin-left: 24px;
    margin-right: 24px;
  }
  .collections_works_container .common_wrapper {
    padding-left: 24px;
    padding-right: 24px;
  }
  .collections_works_container .work_v2 {
    margin: 0 24px;
  }
  .collections_other_container .cc_header {
    margin-left: 24px;
    margin-right: 24px;
  }
  .collections_other_container .cc_header .title {
    margin-bottom: 24px;
  }
  .collections_other_container .common_wrapper {
    padding-left: 24px;
    padding-right: 24px;
  }
  .c__video .list_video {
    height: 222px;
  }
}

@media only screen and (min-width: 1024px) {
  .c_DetailWrap_pc {
    padding-left: 60px;
    padding-right: 60px;
  }
  .c_DetailWrap_pc .group:nth-child(3) {
    min-width: 185px;
  }
  .collections_overview {
    padding-left: 60px;
    padding-right: 60px;
  }
  .collections_video {
    padding-left: 60px;
    padding-right: 60px;
  }
  .collections_video .title {
    padding-bottom: 24px;
    margin-bottom: 40px;
  }
  .collections_artist {
    padding-left: 60px;
    padding-right: 60px;
  }
  .collections_works_container .cc_header {
    margin-left: 60px;
    margin-right: 60px;
  }
  .collections_works_container .common_wrapper {
    padding-left: 60px;
    padding-right: 60px;
  }
  .collections_other_container .cc_header {
    margin-left: 60px;
    margin-right: 60px;
  }
  .collections_other_container .common_wrapper {
    padding-left: 60px;
    padding-right: 60px;
  }
  .collections_divider {
    height: 120px;
  }
  .lh100 {
    height: 80px;
  }
  .c__video .list_video {
    height: 550px;
  }
}

@media only screen and (min-width: 1280px) {
  .c_DetailWrap_pc {
    padding-left: 140px;
    padding-right: 140px;
  }
  .collections_overview {
    padding-left: 140px;
    padding-right: 140px;
  }
  .collections_video {
    padding-left: 140px;
    padding-right: 140px;
  }
  .collections_artist {
    padding-left: 140px;
    padding-right: 140px;
  }
  .collections_works_container .cc_header {
    margin-left: 140px;
    margin-right: 140px;
  }
  .collections_works_container .common_wrapper {
    padding-left: 140px;
    padding-right: 140px;
  }
  .collections_other_container .cc_header {
    margin-left: 140px;
    margin-right: 140px;
  }
  .collections_other_container .common_wrapper {
    padding-left: 140px;
    padding-right: 140px;
  }
  .collections_divider {
    height: 160px;
  }
  .lh100 {
    height: 100px;
  }
  .c__video .list_video {
    height: 700px;
  }
}

@media only screen and (max-width: 1023px) {
  .v__Desktop {
    display: none;
  }
  .v__Mobile {
    display: block;
  }
}
