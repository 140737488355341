/* ---- grid-item ---- */
.sizer,
.item {
  float: left;
  width: 20%;
  height: 20vw;

  /* vertical gutter */
  margin: 0px;
  border: none;
  border-color: hsla(0, 0%, 0%, 0.5);
  overflow: hidden;
}

.item img {
  width: 100%;
  height: 100%;
}

.layout {
  margin: 0 auto;
  margin-bottom: 32px;
}

.layoutv2 {
  margin-top: 40px;
}

.layout:after {
  content: "";
  display: block;
  clear: both;
}

.Instagram-video {
  width: 100%;
  height: 100%;
}

/* more */
.follow-us {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 137px;
  text-align: right;
  font-family: "MaisonNeue-Medium";
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.9;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  cursor: pointer;
}
.follow-us .icon {
  margin-left: 5px;
}

@media only screen and (max-width: 743px) {
  .follow-us {
    display: none;
  }
}
@media only screen and (min-width: 744px) {
  .follow-us {
    display: none;
  }
}
@media only screen and (min-width: 1024px) {
  .follow-us {
    display: block;
  }
}

@media only screen and (max-width: 576px) {
  .sizer,
  .item {
    width: 50%;
    height: 50vw;
  }
  .layout {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .follow-us {
    line-height: 1.2;
  }
}
@media only screen and (min-width: 576px) and (max-width: 768px) {
  .sizer,
  .item {
    width: 50%;
    height: 50vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .sizer,
  .item {
    width: 33.3%;
    height: 33.3vw;
  }
}
@media only screen and (min-width: 1023px) and (max-width: 1439px) {
  .sizer,
  .item {
    width: 25%;
    height: 25vw;
  }
}
@media only screen and (min-width: 1440px) {
  .sizer,
  .item {
    width: 20%;
    height: 20vw;
  }
}
@media only screen and (min-width: 1920px) {
  .sizer,
  .item {
    width: 20%;
    height: 20vw;
  }
}
@media only screen and (min-width: 2020px) {
  .sizer,
  .item {
    width: 20%;
    height: 20vw;
  }
}
