.artistDetailContainer {
  width: 100%;
  margin-top: 88px;
}

/***************************************** ArtistInfo ***************************************************************/
.artistDetailWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 136px;
}

.artistImage {
  width: 26%;
  margin: 24px auto;
}

.artistImage img {
  width: 100%;
  height: auto;
}

.artistDetailInfo_content {
  width: 34%;
  margin: 40px auto;
}

.artistDetailInfo_content .title {
  font-family: MaisonNeue-Bold;
  font-size: 48px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: var(--dark);
}

.artistDetailInfo_content .content {
  font-family: MaisonNeue-Book;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--dark);
  margin: 32px 0 24px 0;
  text-align: left;
}

.artistVideo {
  margin-top: 102px;
}

.artistBtn {
  margin-bottom: 96px;
}

.artistBtn .bdgBJg.bdgBJg {
  width: 280px;
}

.artistBtn .JhMeT {
  display: none;
}

/************************************************* collection *************************************************/
.ArtistCollection_Container .Clip {
  display: flex !important;
}

.ArtistCollection_Container .slick-prev,
.ArtistCollection_Container .slick-next {
  z-index: 1 !important;
}

.ArtistCollection_Container .slick-arrow .slick-prev,
.ArtistCollection_Container .slick-arrow .slick-next {
  z-index: 1 !important;
  display: block !important;
  position: absolute;
  width: 20px;
  height: 20px;
  padding: 0;
  transform: translate(0, -50%);
  color: transparent;
  border: none;
  outline: none;
}

.ArtistCollection_Container .slick-prev:before {
  content: url("/images/miscellaneous/icon-chevron-left.svg") !important;
}

.ArtistCollection_Container .slick-next:before {
  content: url("/images/miscellaneous/icon-chevron-right.svg") !important;
}

.M_top {
  height: 100px;
}

.artist_content {
  padding: 0 140px;
  padding-top: 140px;
  width: 100%;
  position: relative;
  overflow: auto;
}

.D_title {
  font-family: "MaisonNeue-Demi";
  font-size: 64px;
  line-height: 1.2;
  margin-bottom: 25px;
}

.D_download {
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  line-height: 1;
  margin-bottom: 40px;
}

.D_download .anticon {
  vertical-align: text-top;
  margin-right: 8px;
}

.D_download .D_cv {
  margin-bottom: 20px;
  cursor: pointer;
}

.D_download .D_images {
  margin-bottom: 0px;
  cursor: pointer;
}

.L_nav {
  min-width: 426px;
  max-width: 426px;
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  text-align: left;
  position: absolute;
  padding-top: 140px;
  top: 0;
}

.L_nav.fixed {
  padding-top: 140px;
  position: fixed;
  top: 0;
}

#fixedNav2.L_nav {
  display: none !important;
}

.L_ul {
  margin: 0;
  padding: 0;
}

.L_ul li {
  font-family: "MaisonNeue-Demi";
  line-height: 1;
  list-style: none;
  font-size: 24px;
  text-align: left;
  padding-bottom: 24px;
  color: #979797;
  cursor: pointer;
}

.L_ul li.active {
  color: var(--dark);
}

.R_content {
  padding-left: 73px;
  float: left;
}

.artDetailWrap {
  width: 100%;
  font-family: "MaisonNeue-Medium";
}

.artImage {
  max-width: 100%;
}

.artImage img {
  max-width: 100%;
}

.artContent {
  font-family: "MaisonNeue-Medium";
  font-size: 18px;
  text-align: left;
}

.artContent p:last-child {
  margin-bottom: 0;
}

.artVideo .list_video {
  height: 400px;
}

.icon_more_box {
  padding-top: 8px;
  text-align: right;
  font-size: 20px;
}

.icon_more_box .anticon {
  position: relative;
  top: -3px;
}

.icon_more_box span {
  margin-right: 8px;
}

.icon_more_box a:active, .icon_more_box a:hover {
  color: var(--dark);
}

.art_column_item {
  margin-top: 120px;
}

.art_column_item .common_wrapper {
  margin: 0;
}

.art_column_item.artSelectedPress {
  margin-bottom: 140px;
}

.Title_iphone {
  font-family: "MaisonNeue-Demi";
  font-size: 24px;
  margin-bottom: 36px;
}

.T_line {
  margin-bottom: 40px;
  border: 1px solid #efefef;
}

.iphone_colums {
  padding-bottom: 40px !important;
}

.artist_content_iphone {
  display: none;
  padding-top: 108px;
  text-align: left;
}

.artist_content_iphone .D_title {
  font-size: 40px;
}

.artist_content_iphone .artImage {
  margin-bottom: 48px;
}

.artist_content_iphone .D_download {
  margin-bottom: 25px;
}

.artist_content_iphone .D_cv {
  margin-bottom: 12px;
}

/**************************************************** media query **************************************************/
@media only screen and (max-width: 1278px) {
  .artist_content {
    padding: 0 60px;
    padding-top: 140px;
  }
}

@media only screen and (max-width: 1023px) {
  .artist_content {
    padding: 0 40px;
    padding-top: 100px;
    display: none;
  }
  .R_content {
    padding-left: 40px;
  }
  .iphone_colums {
    padding: 0 40px;
  }
  .artist_content_iphone {
    display: block;
  }
  .artist_content_iphone .cc_header {
    border: 0;
  }
}

@media only screen and (max-width: 744px) {
  .artist_content_iphone {
    display: block;
  }
  .artist_content {
    display: none !important;
    padding: 0 24px;
    padding-top: 100px;
    display: none;
  }
  .iphone_colums {
    padding: 0 24px;
  }
  .artVideo .list_video {
    height: 222px;
  }
}

@media only screen and (max-width: 767px) {
  .ArtistCollection_Container {
    margin: 0 16px 68px 16px;
  }
  .artistImage {
    width: 100%;
    margin: 0 auto;
  }
  .artistDetailInfo_content {
    margin: 24px 16px 0 16px;
    width: 91.5%;
  }
  .artistDetailInfo_content .title {
    font-size: 24px;
    line-height: 1.17;
  }
  .artistDetailInfo_content .content {
    font-size: 14px;
    line-height: 1.43;
  }
  .artistVideo iframe {
    height: 229px;
  }
  .artistDetailWrap {
    margin-bottom: 72px;
  }
  .artistDetailContainer {
    margin-top: 56px;
  }
}

@media only screen and (min-width: 768px) {
  .ArtistCollection_Container {
    margin: 96px 24px;
  }
  .artistImage {
    width: 46.8%;
  }
  .artistDetailInfo_content {
    width: 78.2%;
  }
  .ArtistCollection_Container .slick-prev {
    left: 87% !important;
    top: 86% !important;
  }
  .ArtistCollection_Container .slick-next {
    left: 93% !important;
    top: 86% !important;
  }
  .artistDetailInfo_content .title {
    font-size: 36px;
  }
}

@media only screen and (min-width: 1024px) {
  .ArtistCollection_Container {
    margin: 96px 40px;
  }
  .artistImage {
    width: 42.96%;
  }
  .artistDetailInfo_content {
    width: 58.6%;
  }
  .ArtistCollection_Container .slick-prev {
    left: 91% !important;
    top: 89% !important;
  }
  .ArtistCollection_Container .slick-next {
    left: 96% !important;
    top: 89% !important;
  }
  .artVideo .list_video {
    height: 266px;
  }
  .R_content {
    padding-left: 40px;
    margin-left: 360px;
  }
  .L_nav {
    min-width: 360px;
    max-width: 360px;
  }
  .D_title {
    font-size: 40px;
  }
}

@media only screen and (min-width: 1280px) {
  .artVideo .list_video {
    height: 400px;
  }
  .R_content {
    padding-left: 73px;
    margin-left: 426px;
  }
  .L_nav {
    min-width: 426px;
    max-width: 426px;
  }
  .D_title {
    font-size: 64px;
  }
}

@media only screen and (min-width: 1440px) {
  .ArtistCollection_Container {
    margin: 96px 41px;
  }
  .ArtistCollection_Container .slick-prev {
    left: 93% !important;
    top: 90% !important;
  }
  .ArtistCollection_Container .slick-next {
    left: 96% !important;
    top: 90% !important;
  }
  .artistImage {
    width: 34.65%;
  }
  .artistDetailInfo_content {
    width: 41.67%;
  }
}

@media only screen and (min-width: 1920px) {
  .ArtistCollection_Container {
    margin: 96px 159px;
  }
  .ArtistCollection_Container .slick-prev {
    left: 92.3% !important;
    top: 91.1% !important;
  }
  .ArtistCollection_Container .slick-next {
    left: 96% !important;
    top: 91.2% !important;
  }
  .artistImage {
    width: 26%;
  }
  .artistDetailInfo_content {
    width: 31.25%;
  }
}
