.carouselImg_col {
  padding: 0 12px;
}
.col_heroCarousel button {
  border-radius: 50%;
  height: 48px;
  width: 48px;
  border: solid 1px var(--pale-blue);
  background-color: var(--white);
}

.col_heroCarousel button.slick-arrow.slick-next {
  background-color: white;
}
.col_heroCarousel button.slick-arrow.slick-prev {
  background-color: white;
}

.col_heroCarousel .slick-prev {
  left: 40px;
  top: 320px;
  z-index: 1;
}
.col_heroCarousel .slick-next {
  right: 40px;
  top: 320px;
}

.col_heroCarousel .slick-prev:before {
  content: url("/images/miscellaneous/icon-chevron-left.svg") !important;
}

.col_heroCarousel .slick-next:before {
  content: url("/images/miscellaneous/icon-chevron-right.svg") !important;
}
.col_heroCarousel .slick-next:hover:focus:active {
  background-color: white;
}
.col_heroCarousel .slick-prev:hover:focus:active {
  background-color: white;
}

.carouselImg_col_small img{
  object-fit: scale-down;
}

/**************************************************** media query ******************************************************/
@media only screen and (max-width: 767px) {
  .carouselWrap .carousel {
    height: 249px;
  }
  .carouselImg_col img {
    min-height: 249px;
  }
  .col_heroCarousel .slick-arrow {
    display: none !important;
  }
  .col_heroCarousel .slick-dots {
    bottom: -20px;
  }
  .col_heroCarousel .carouselImg_col {
    padding: 0px;
  }
  .col_heroCarousel .ewOoqd {
    margin-top: 102px;
  }
}
@media only screen and (min-width: 768px) {
  .carouselImg_col img {
    height: 360px;
  }
}
@media only screen and (min-width: 1024px) {
  .carouselImg_col img {
    height: 450px;
  }
  .col_heroCarousel .slick-prev {
    top: 250px;
  }
  .col_heroCarousel .slick-next {
    top: 250px;
  }
}
@media only screen and (min-width: 1440px) {
  .carouselImg_col img {
    height: 500px;
  }
}
@media only screen and (min-width: 1920px) {
  .carouselImg_col img {
    height: 640px;
  }
  .col_heroCarousel .slick-prev {
    top: 300px;
  }
  .col_heroCarousel .slick-next {
    top: 300px;
  }
}
