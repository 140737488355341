.carouselImg_works {
  padding: 0 12px;
  text-align: center;
}
.carouselImg_works img {
  height: 100%;
  width: auto;
  max-width: 100%;
  margin: auto;
  object-fit: scale-down;
}
/**************************************************** media query ******************************************************/
@media only screen and (max-width: 767px) {
  .carouselImg_works {
    height: 260px;
  }
}
@media only screen and (min-width: 768px) {
  .carouselImg_works {
    height: 494px;
  }
}
@media only screen and (min-width: 1024px) {
  .carouselImg_works {
    height: 450px;
  }
}
@media only screen and (min-width: 1440px) {
  .carouselImg_works {
    height: 560px;
  }
}
@media only screen and (min-width: 1920px) {
  .carouselImg_works {
    height: 640px;
  }
}
