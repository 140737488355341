/*********** Default  ***********/
.pl20 {
  padding-left: 20px;
}

.pr20 {
  padding-right: 20px;
}

.Anchor {
  height: 1px;
  visibility: visible;
  position: relative;
  overflow: hidden;
  display: block;
}

/*********** Banner  ***********/
.ant_carousel {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.desc_modal {
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 10;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}

.desc_modal .desc_group {
  position: absolute;
  width: 581px;
  left: 40px;
  text-align: left;
  top: 50%;
  transform: translateY(-50%);
}

.desc_modal a {
  color: var(--white);
}

.desc_modal .desc_category {
  font-family: MaisonNeue-Medium;
  font-size: 18px;
  line-height: normal;
  color: var(--steel-grey);
}

.desc_modal .desc_name {
  font-family: MaisonNeue-Demi;
  font-style: normal;
  font-size: 60px;
  line-height: 1.2;
  margin-top: 20px;
}

.desc_modal .desc_description {
  width: 88%;
  font-size: 18px;
  margin-top: 28px;
  font-family: MaisonNeue-Medium;
  line-height: 1.6;
}

.desc_modal .desc_more {
  margin-top: 32px;
  font-family: MaisonNeue-Medium;
  color: var(--dark);
  font-size: 18px;
  line-height: 1.6;
}

.desc_modal .desc_more svg {
  margin-left: 8px;
}

.galleryV2 .board {
  display: none;
}

.galleryV2 .slick-dots {
  bottom: 30px;
}

.galleryV2 .slick-dots li {
  width: 6%;
  height: 4px;
  margin-right: 12px;
  background: rgba(255, 255, 255, 0.4);
}

.galleryV2 .slick-dots li:last-child {
  margin-right: 0px;
}

.galleryV2 .slick-dots button {
  padding: 0;
  width: 100%;
}

.galleryV2 .slick-dots .slick-active {
  background: #ffffff;
}

.galleryV2 .slick-dots li button:before {
  display: none;
}

/*********** Collections Works Artists ***********/
.v2-NewsOfGalleryAll,
.v2-Collections,
.v2-Works,
.v2-Artists {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  background: #fff;
}

.v2-NewsOfGalleryAll::after,
.v2-Collections::after,
.v2-Works::after,
.v2-Artists::after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom: 1px solid #ebebeb;
}

.v2-NewsOfGalleryAll:last-child:after,
.v2-Collections:last-child:after,
.v2-Works:last-child:after,
.v2-Artists:last-child:after {
  display: none;
}

.v2-Collections .both {
  padding-top: 120px;
}

.v2-Works .v2Card {
  float: left;
  width: auto !important;
}

.v2-Works .v2Image {
  text-align: left;
}

.v2-Works .work_content {
  overflow: hidden;
  padding-top: 120px;
  margin-bottom: 30px;
}

.v2-Artists .v2Card {
  float: left;
  width: auto !important;
}

.v2-Artists .v2Image {
  text-align: left;
}

.work_content .ant-row-flex {
  margin-bottom: 22px;
}

.workPop {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0.64));
  transition: all 0.4s linear 0s;
}

.workPopContent {
  font-family: MaisonNeue-Medium;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-align: left;
  padding-left: 40px;
}

.workPopContent h2 {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #fff !important;
}

.workImg {
  position: relative;
  overflow: hidden;
}

.workImg img {
  max-width: 100%;
  transition: all 0.4s linear 0s;
}

.workImg:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.workImg:hover .workPop {
  opacity: 1;
}

.collections_info {
  font-size: 18px;
  text-align: left;
  padding: 40px 0;
}

.v2-Artists .content-item {
  padding-top: 180px;
  margin-bottom: 40px;
}

.artists_centent {
  display: inline-block;
  overflow: hidden;
}

.artists-1024 {
  display: none;
}

.artists-1024 .ant-row-flex {
  margin-bottom: 40px;
}

.artists-item {
  width: 100%;
}

.artists-item-image {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.artists-item-image img {
  transition: all 0.5s;
  width: 100%;
  max-width: 100%;
  height: 295px;
  margin: auto;
}

.artists-item-info {
  font-family: "MaisonNeue-Medium";
  width: 100%;
  text-align: left;
}

.artists-item-info .artists-item-vrt {
  font-style: normal;
  font-size: 12px;
  line-height: 12px;
  color: var(--dark);
  mix-blend-mode: normal;
  opacity: 0.88;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 8px 0px;
}

.artists-item-info .artists-item-name {
  font-style: normal;
  font-size: 20px;
  line-height: 20px;
  color: var(--dark);
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 8px 0px;
}

.artists-item-link {
  visibility: visible;
}

.artists-item-link:hover img {
  transform: scale(1.1, 1.1);
}

/*********** News of Gallery All ***********/
.v2-NewsOfGalleryAll .smallSliderHeader {
  position: relative;
  width: 100%;
  margin-bottom: 40px;
}

.v2-NewsOfGalleryAll .smallSliderHeader::after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom: 1px solid #ebebeb;
}

.newsCard {
  width: 100%;
  margin-right: 8px;
  padding: 0 10px;
}

.newsCard .news-thumb {
  width: 100%;
  height: 240px;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.newsCard .news-thumb img {
  transition: all 0.5s;
  width: 100% !important;
  height: 240px;
}

.newsCard .news-thumb:hover img {
  transform: scale(1.1, 1.1);
}

.newsCard .news-content {
  visibility: visible;
}

.newsCard .news_title {
  font-family: "MaisonNeue-Demi";
  font-size: 20px;
  line-height: 1.2;
  color: var(--dark);
  text-align: left;
  margin-top: 24px;
}

.newsCard .news_description {
  font-family: "MaisonNeue-Medium";
  font-size: 16px;
  line-height: 1.6;
  color: #808080;
  mix-blend-mode: normal;
  text-align: left;
  margin-top: 16px;
}

.newsCard .news_read_more {
  font-family: "MaisonNeue-Medium";
  font-size: 16px;
  line-height: 1.6;
  text-align: right;
  color: #979797;
  margin-top: 16px;
  text-align: left;
}

.small_news .v2Slide {
  height: inherit;
}

.small_news .v2Card {
  width: 288px;
  padding: 0;
  margin-right: 40px;
}

.v2-NewsOfGalleryAll {
  padding-top: 20px;
}

.v2-NewsOfGalleryAll .slick-prev,
.v2-NewsOfGalleryAll .slick-next {
  width: 32px;
  height: 32px;
  line-height: 1;
  top: -80px;
}

.v2-NewsOfGalleryAll .slick-prev {
  left: unset;
  right: 80px;
}

.v2-NewsOfGalleryAll .slick-next {
  right: 4px;
}

.v2-NewsOfGalleryAll .slick-track {
  margin-left: unset;
  margin-right: unset;
}

.v2-NewsOfGalleryAll .slick-prev:before {
  font-size: 32px;
  content: url("/images/miscellaneous/icon-chevron-left.svg");
}

.v2-NewsOfGalleryAll .slick-next:before {
  font-size: 32px;
  content: url("/images/miscellaneous/icon-chevron-right.svg");
}

@media only screen and (max-width: 743px) {
  .ant_carousel {
    flex-direction: column;
    height: 700px;
  }
  .desc_modal .desc_group {
    width: 334px;
    left: unset;
  }
  .desc_modal .desc_category {
    font-size: 16px;
  }
  .desc_modal .desc_name {
    font-size: 40px;
  }
  .desc_modal .desc_description {
    font-size: 16px;
  }
  .desc_modal .desc_more {
    font-size: 16px;
  }
  .ant_carousel img {
    height: 100%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    -webkit-transform: translateY(-50%) translateX(-50%);
  }
  .v2-Collections,
  .v2-Works,
  .v2-Artists {
    margin: 0 24px;
    padding: 20px 0;
  }
  .collections_info {
    width: 100%;
  }
  .v2-NewsOfGalleryAll {
    margin: 0 24px;
    padding: 20px 0;
    margin-bottom: 40px;
  }
  .small_news .smallSlidercard {
    width: 288px;
    margin-right: 24px;
  }
}

@media only screen and (min-width: 744px) {
  .collections_info {
    width: 516px;
  }
  .ant-col-lg-24 {
    display: none;
  }
  .ant-col-lg-0 {
    display: block;
  }
  .v2-Collections {
    padding: 0 40px 40px 40px;
  }
  .v2-Works {
    padding: 40px;
  }
  .v2-Artists {
    padding: 40px;
  }
  .v2-NewsOfGalleryAll {
    padding: 40px;
    margin-bottom: 40px;
  }
  .v2-NewsOfGalleryAll .smallSliderHeader {
    margin-bottom: 32px;
  }
  .v2-NewsOfGalleryAll .smallSliderHeader::after {
    visibility: hidden;
  }
}

@media only screen and (min-width: 1024px) {
  .desc_modal .desc_group {
    left: 60px;
  }
  .left_nav {
    padding-right: 40px;
  }
  .left_nav .nav_group {
    padding-top: 100px;
  }
  .right_content {
    padding-left: 40px;
  }
  .v2-Collections {
    padding: 0 60px;
  }
  .v2-Collections::after {
    visibility: hidden;
  }
  .v2-Works {
    padding: 0 60px;
    margin-bottom: 40px;
  }
  .v2-Works::after {
    visibility: hidden;
  }
  .v2-Collections .both {
    padding-top: 100px;
  }
  .v2-Works .work_content {
    padding-top: 100px;
  }
  .v2-Artists {
    padding: 0 60px;
    margin-bottom: 120px;
  }
  .v2-Artists::after {
    visibility: hidden;
  }
  .v2-Artists .content-item {
    padding-top: 100px;
  }
  .v2-NewsOfGalleryAll {
    padding: 40px 60px;
    margin-bottom: 120px;
  }
  .v2-NewsOfGalleryAll::after {
    visibility: hidden;
  }
  .v2-NewsOfGalleryAll .smallSliderHeader {
    margin-bottom: 40px;
  }
  .v2-NewsOfGalleryAll .smallSliderHeader::after {
    visibility: visible;
  }
  .ant-col-lg-24 {
    display: block;
  }
  .ant-col-lg-0 {
    display: none;
  }
  .lg_footer {
    position: relative;
    width: 100%;
    padding: 48px 40px !important;
  }
  .lg_address {
    padding: 0 40px !important;
  }
  .left_nav {
    width: 300px;
  }
  .artists_centent {
    display: none;
  }
  .artists-1024 {
    display: block;
  }
}

@media only screen and (min-width: 1280px) {
  .desc_modal .desc_group {
    left: 140px;
  }
  .v2-Collections,
  .v2-Works {
    padding: 0 140px;
  }
  .v2-Collections::after,
  .v2-Works::after {
    visibility: hidden;
  }
  .v2-Artists {
    padding: 0 140px;
    margin-bottom: 120px;
  }
  .v2-Artists::after {
    visibility: hidden;
  }
  .v2-NewsOfGalleryAll {
    margin: 0;
    margin-bottom: 160px;
    padding-left: 140px;
    padding-right: 140px;
  }
  .v2-NewsOfGalleryAll::after {
    visibility: hidden;
  }
  .left_nav {
    width: 428px;
  }
  .left_nav .nav_group {
    padding-top: 120px;
  }
  .v2-Collections .both {
    padding-top: 120px;
  }
  .artists_centent {
    display: flex;
  }
  .artists-1024 {
    display: none;
  }
  .lg_footer {
    position: relative;
    width: 100%;
    padding: 48px 140px !important;
  }
  .lg_footer .pc_group {
    display: block;
  }
  .lg_footer .mobile_group {
    display: none;
  }
  .lg_address {
    padding: 0 140px !important;
  }
}
